import React from "react";
// import { FaLinkedinIn, FaTwitter, FaInstagram, FaFacebookF, FaYoutube } from "react-icons/fa";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div classNameName="creative-footer">
        <div className="content">
          <div className="top">
            <div className="logo-details">
              <i className="fab fa-slack"></i>
              <span className="logo_name">Creative Thoughts</span>
            </div>
            {/* <div className="media-icons">
            <a href="#FaFacebookF"><FaFacebookF /></a>
            <a href="#FaTwitter"><FaTwitter /></a>
            <a href="#FaInstagram"><FaInstagram /></a>
            <a href="#FaLinkedinIn"><FaLinkedinIn /></a>
            <a href="#FaYoutube"><FaYoutube /></a>
        </div> */}
          </div>
          <div className="link-boxes">
            <ul className="box">
              <li className="link_name">Connect</li>
              <li>
                <Link to={"/home"}>Home</Link>
              </li>
              <li>
                <Link to={"/mybookings"}>Contact us</Link>
              </li>
              <li>
                <Link to={"/mybookings"}>About us</Link>
              </li>
              <li>
                <Link to={"/mybookings"}>Get started</Link>
              </li>
            </ul>
            <ul className="box">
              <li className="link_name">Services</li>
              <li>
                <Link to={"/services"}>1:1 Services</Link>
              </li>
              <li>
                <Link to={"/webinar"}>Webinar</Link>
              </li>
            </ul>
            <ul className="box">
              <li className="link_name">Creators</li>
              <li>
                <Link to={"/mybookings"}>My Bookings</Link>
              </li>
              <li>
                <Link to={"/pages"}>Pages</Link>
              </li>
              <li>
                <Link to={"/reviews"}>Reviews</Link>
              </li>
              <li>
                <Link to={"/profile"}>Profile</Link>
              </li>
            </ul>
            <ul className="box">
              <li className="link_name">Speciality</li>
              <li>
                <Link to={"/software"}>Software Engineers</Link>
              </li>
              <li>
                <Link to={"/creators"}>Creators</Link>
              </li>
              <li>
                <Link to={"/scientists"}>Data Scientists</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="bottom-details">
          <div className="bottom_text">
            <span className="copyright_text">
              Copyright © 2021 <a href="#Creative">YATA YATI.</a>All rights
              reserved
            </span>
            <span className="policy_terms">
              <a href="#Privacy">Privacy policy</a>
              <a href="#Terms">Terms & condition</a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
