import React, { useState, useEffect } from "react";
import "./AdminService.css";
import Verticalbar from "./verticalbar";
import Page from "./Page";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { FaSearch } from "react-icons/fa";
import "./AdminManagement.css";
 
const Transactionhistory = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
 
  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);
 
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [fullData, setFullData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
 
  useEffect(() => {
    fetchData();
  }, []);
 
  useEffect(() => {
    const startIdx = (currentPage - 1) * rowsPerPage;
    const endIdx = startIdx + rowsPerPage;
    setDisplayData(fullData.slice(startIdx, endIdx));
    setTotalPages(Math.ceil(fullData.length / rowsPerPage));
  }, [fullData, currentPage, rowsPerPage]);
 
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
 
  const fetchData = () => {
    fetch("https://api.booking.ashishphotography.co.in/api/transcation_history")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setFullData(data);
        setDisplayData(data);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
      });
  };
 
  const filteredData = displayData.filter((item) =>
    item.user_username.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.rescp_username.toLowerCase().includes(searchQuery.toLowerCase())
  );
 
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
 
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
 
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
 
  return (
    <div className="d-flex">
      <Verticalbar />
      <div className="service-page" style={{ width: "100%", borderCollapse: "collapse" }}>
        <div className="header-container">
          <h4>Transaction History</h4>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <FaSearch className="search-icon" />
          </div>
        </div>
 
        {filteredData.length > 0 ? (
          <div className="table-wrapper">
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <th>Receipt ID</th>
                    <th>Service ID</th>
                    <th>Amount</th>
                    <th>User Name</th>
                    <th>Booked By</th>
                    <th>Payment Time</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item) => (
                    <tr key={item.t_id}>
                      <td>{item.receipt_id}</td>
                      <td>{item.service_id}</td>
                      <td>{item.amount}</td>
                      <td>{item.user_username}</td>
                      <td>{item.rescp_username}</td>
                      <td>{item.payment_time}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div>No results found.</div>
        )}
 
        <Page
          className="Pagi"
          currentPage={currentPage}
          totalPages={totalPages}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          rowsPerPage={rowsPerPage}
          handleRowsPerPageChange={handleRowsPerPageChange}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};
 
export default Transactionhistory;