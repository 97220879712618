import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './bookingform.css';
import 'react-datepicker/dist/react-datepicker.css';
import Axios from 'axios';

const BookingForm = () => {
  const [time, setTime] = useState('');
  const [name, setname] = useState('');
  const [email, setemail] = useState('');
  const [phonenumber, setphonenumber] = useState('');
  const [availabilityMessage, setAvailabilityMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedDaySlots, setSelectedDaySlots] = useState([]);
  const [selectedDateString, setSelectedDateString] = useState('');
  const [disabledDates, setDisabledDates] = useState([]);
  const [timeslotsavailableitly, settimeslotsavailableitly] = useState(false);
  // const [bookedSlots, setBookedSlots] = useState([]);
  const [disabledSlots, setDisabledSlots] = useState([]);
  const [bookedTimeSlots, setBookedTimeSlots] = useState([]);
  // const [availableSlots, setAvailableSlots] = useState([]);
  // const [allSlotsFilled, setAllSlotsFilled] = useState(false);
  // const [selectAll, setSelectAll] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [YOUR_ALL_SLOTS, setYOUR_ALL_SLOTS] = useState(false);
  // const [noSlotsAvailableDialogOpen, setNoSlotsAvailableDialogOpen] = useState(false);
  const [allSlotsFilledForSelectedDate, setAllSlotsFilledForSelectedDate] = useState(false);
 

  const notifySuccess = () => {
    toast.success('Appointment booked successfully!', {
      position: 'top-center', // Adjust the position as needed
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      style: {
        whiteSpace: 'nowrap', // Add this style to prevent line breaks
      },
    });
  };
  

  const notifyError = (message) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  useEffect(() => {
    console.log("slotsfrombackend", selectedDaySlots)
  }, [selectedDaySlots])

  const handleDateChange = async (event) => {
    const selectedDate = event.target.value;
    setSelectedDateString(selectedDate);
    setTime('');
    setErrorMessage('');

    try {
      const response = await Axios.get(`https://api.booking.ashishphotography.co.in/slots?date=${selectedDate}`);

      console.log('Response Data from Backend:', response.data);

      if (Array.isArray(response.data.availableSlots) && response.data.availableSlots.length > 0) {
        const availableSlots = response.data.availableSlots.map(slot => ({
          value: slot,
          label: slot,
          isDisabled: disabledSlots.includes(`${selectedDate} ${slot}`) || bookedTimeSlots.includes(`${selectedDate} ${slot}`),
        }));
        setSelectedDaySlots(availableSlots);
        settimeslotsavailableitly(true);
      } else {
        setSelectedDaySlots([]);
        settimeslotsavailableitly(false);
        setErrorMessage('No available slots for the selected date.');
      }
    } catch (error) {
      console.error('Error fetching available slots for the selected date:', error);
      setErrorMessage('Error fetching available slots. Please try again.');
      setSelectedDaySlots([]);
    }
  };

  const availableSlotsForSelectedDay = selectedDaySlots.map(slot => {
    const slotTime = `${slot.value}`;
    const isDisabled = disabledSlots.includes(slotTime) || bookedTimeSlots.includes(`${selectedDateString} ${slotTime}`);

    return {
      value: slotTime,
      label: slotTime,
      isDisabled: isDisabled,
    };
  });

  const handleTimeButtonClick = (selectedTime) => {

    setSelectedSlot(selectedTime);
    const selectedDateTime = `${selectedDateString} ${selectedTime}`;

    if (disabledDates.includes(selectedDateString) || bookedTimeSlots.includes(selectedDateTime)) {
      setErrorMessage('This time slot is already booked. Please choose another time.');
      return;
    }

    setTime(selectedTime);
    setErrorMessage('');

    const updatedBookedTimeSlots = [...bookedTimeSlots, selectedDateTime];
    setBookedTimeSlots(updatedBookedTimeSlots);

    // Disable the selected slot
    const updatedSlots = selectedDaySlots.map(slot => {
      const slotTime = `${selectedDateString} ${slot.value}`;
      if (slotTime === selectedDateTime) {
        return {
          ...slot,
          isDisabled: true,
        };
      }
      return slot;
    });

    setSelectedDaySlots(updatedSlots);

    // Check if all slots for the selected date are booked
    const isAllSlotsForSelectedDateFilled = updatedSlots.every(slot => slot.isDisabled);

    // If all slots for the selected date are filled, disable the date
    if (isAllSlotsForSelectedDateFilled && !disabledDates.includes(selectedDateString)) {
      setDisabledDates((prevDisabledDates) => [...prevDisabledDates, selectedDateString]);
    }

    setDisabledSlots([...disabledSlots, selectedTime]);
  };


  const bookAppointment = async () => {
    try {
      const formData = {
        name: name,
        email: email,
        phonenumber: phonenumber,
        date: selectedDateString,
        time: time,
      };
  
      // Simulating API call to book an appointment
      // Replace this with your actual API endpoint
      const response = await Axios.post("https://api.booking.ashishphotography.co.in/api/bookAppointment", formData);
  
      if (response.status === 200) {
        // Save booked time slot to localStorage
        const bookedSlotTime = `${selectedDateString} ${time}`;
        const updatedDisabledSlots = [...disabledSlots, bookedSlotTime];
        const updatedBookedTimeSlots = [...bookedTimeSlots, bookedSlotTime];
        localStorage.setItem('disabledSlots', JSON.stringify(updatedDisabledSlots));
        localStorage.setItem('bookedTimeSlots', JSON.stringify(updatedBookedTimeSlots));
  
        // Update the selectedDaySlots to disable the booked time slot
        const updatedSlots = selectedDaySlots.map(slot => {
          const slotTime = `${selectedDateString} ${slot.value}`;
          if (slotTime === bookedSlotTime) {
            return {
              ...slot,
              isDisabled: true,
            };
          }
          return slot;
        });
  
        setSelectedDaySlots(updatedSlots);
  
        // Check if all time slots for the selected date are disabled
        const isAllSlotsForSelectedDateFilled = updatedSlots.every(slot => slot.isDisabled);
  
        // If all slots for the selected date are filled, disable the entire date
        if (isAllSlotsForSelectedDateFilled && !disabledDates.includes(selectedDateString)) {
          setDisabledDates((prevDisabledDates) => [...prevDisabledDates, selectedDateString]);
        }
  
        notifySuccess(); // Notify success after booking appointment
        setErrorMessage('');
      } else {
        // Handle other status codes if needed
        notifyError('Error booking appointment. Please try again.');
      }
    } catch (error) {
      console.error('Error booking appointment:', error);
      notifyError('Error booking appointment. Please try again.');
      setAvailabilityMessage('');
    }
  };
  
  

  // const formatDate = (dateString) => {
  //   const [year, month, day] = dateString.split('-');
  //   return `${day}-${month}-${year}`;
  // };

  const formattedMinDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const isDateDisabled = (date) => disabledDates.includes(date) || (allSlotsFilledForSelectedDate && date === selectedDateString);


  const [selectedSlot, setSelectedSlot] = useState(null);

  

  return (
    <div className="parent">
      
      <form className="page">
        <fieldset>
        <h3 className="sub-heading"><b><u>APPOINTMENT BOOKING</u></b></h3>
        <br /> 
          <input type='text' name='name' placeholder='Name' className="input-field" value={name} onChange={e => setname(e.target.value)} />
          <br /> <br />
          <input type='email' name='email' placeholder='Email' className="input-field" value={email} onChange={e => setemail(e.target.value)} />
          <br /> <br />
          <input type='text' placeholder='Phone Number' name='phoneNumber' className="input-field" onChange={e => setphonenumber(e.target.value)} />
          <br />
          <input
          className="input-field"
          type="date"
          id="datepicker"
          placeholder='Date'
          value={selectedDateString}
          onChange={handleDateChange}
          min={formattedMinDate()}
          style={{
            color: selectedDateString ? 'black' : 'gray',
          }}
          disabled={disabledDates.length > 0 && isDateDisabled(selectedDateString)}
        />
          
        </fieldset>

        

        <div>
          {timeslotsavailableitly ? (
            selectedDaySlots.length > 0 && (
              <div>
                {availableSlotsForSelectedDay.map(slot => (
                  <button
                    key={slot.value}
                    className={`time-slot-button ${selectedSlot === slot.value ? 'selected' : ''}`}
                    onClick={() => handleTimeButtonClick(slot.value)}
                    disabled={slot.isDisabled}
                  > 
                    {slot.label}
                  </button>
                ))}
              </div>
            )
          ) : null}
        </div>
        <br /><br />
        <button type="button" className="button-type" onClick={bookAppointment} disabled={!selectedDateString || !time}>
          Book Appointment
        </button>
        <p style={{ color: 'red' }}>{errorMessage}</p>
        <p style={{ color: 'green' }}>{availabilityMessage}</p>

        <ToastContainer />
      </form>
    </div>
  );
};

export default BookingForm;





