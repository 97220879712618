import React, { useState, useEffect } from "react";
import "./Resume1.css";
import Img1 from "./assets/r1.png";
import Img2 from "./assets/r2.png";
import Img3 from "./assets/r3.png";
import Img4 from "./assets/r4.png";
import Img5 from "./assets/r5.png";
import Img6 from "./assets/r6.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Portfolioform2 = () => {

  const storedUserId = localStorage.getItem("userId");
  const [userId, setUserId] = useState(
    storedUserId ? parseInt(storedUserId) : null
  );
  const navigate = useNavigate();

  const [logoutStatus, setLogoutStatus] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
 
    useEffect(() => {
      axios.get('https://api.booking.ashishphotography.co.in/home', { withCredentials: true })
        .then((response) => {
          if (response.data.loggedIn) {
            setIsLoggedIn(true);
          } else {
            navigate('/login');
            // localStorage.clear();
          }
        })
        .catch((error) => {
          console.error('Error checking authentication:', error);
          navigate('/login');
        });
    }, [navigate]);


  const [formData, setFormData] = useState({
    name: "",
    image: "",
    title: "",
    aboutme: "",
    aboutmedescription: "",
    skillsDescp: "",
    higherEducation: "",
    higherUniversity: "",
    higherYear: "",
    lowerEducation: "",
    lowerUniversity: "",
    lowerYear: "",
    workDesignation: "",
    clientName: "",
    workYear: "",
    projectDescp: "",
    email: "",
    mobile: "",
  });

  const handleImageChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const originalFilename = file.name; // Get the original filename
      uploadImage(file, name);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const uploadImage = async (file, name) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      // formData.append('filename', filename);
      formData.append("userId", userId);

      const response = await axios.post(
        "https://api.booking.ashishphotography.co.in/port/uploadImage",
        formData
      );
      const { filename } = response.data; // Extract the filename from the response
      console.log(filename);
      console.log("Image uploaded successfully!");
      setFormData((prevData) => ({ ...prevData, [name]: filename }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const [step, setStep] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleContinue = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // You can submit the form data here or perform any other action
    console.log(formData);
    // Reset form data if needed
    // Send data to Backend
    const response = await fetch("https://api.booking.ashishphotography.co.in/api/portfolio2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        formData,
        userId,
      }),
    });

    if (response.ok) {
      console.log("Data saved successfully.");
      // Reset form data after successful submission
      setFormData({
        name: "",
        image: "",
        title: "",
        aboutme: "",
        aboutmedescription: "",
        skillsDescp: "",
        higherEducation: "",
        higherUniversity: "",
        higherYear: "",
        lowerEducation: "",
        lowerUniversity: "",
        lowerYear: "",
        workDesignation: "",
        clientName: "",
        workYear: "",
        projectDescp: "",
        email: "",
        mobile: "",
      });
      navigate("/pages");
    } else {
      console.error("Error saving PDF.");
    }
  };

  const getImageForStep = () => {
    switch (step) {
      case 1:
        return <img className="re1" src={Img1} alt="Step 1" />;
      case 2:
        return <img className="re1" src={Img2} alt="Step 2" />;
      case 3:
        return <img className="re1" src={Img3} alt="Step 3" />;
      case 4:
        return <img className="re1" src={Img4} alt="Step 4" />;
      case 5:
        return <img className="re1" src={Img5} alt="Step 5" />;
      case 6:
        return <img className="re1" src={Img6} alt="Step 6" />;
      default:
        return null;
    }
  };

  return (
    <div className="multi-step-form-container">
      {getImageForStep()}
      <form onSubmit={handleSubmit} className="form">
        {step === 1 && (
          <div className="form-step">
            <label>
              1 Upload Image
              <input
                type="file"
                name="image"
                onChange={handleImageChange}
                required
              />
            </label>
            <br />
            <label>
              2 Name:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </label>
            <br />
            <label>
              3 Designation:
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </label>
            <br />
            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}

        {step === 2 && (
          <div className="form-step">
            <label>
              4 ABOUT ME
              <input
                type="text"
                name="aboutme"
                value={formData.aboutme}
                onChange={handleChange}
                required
              />
              <br />
              5 Description
              <input
                type="text"
                name="aboutmedescription"
                value={formData.aboutmedescription}
                required
                onChange={handleChange}
              />
              <br />
            </label>

            <br />
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}

        {step === 3 && (
          <div className="form-step">
            EDUCATION
            <br />
            <br />
            <label>
              6. HIGHER EDUCATION
              <input
                type="text"
                name="higherEducation"
                value={formData.higherEducation}
                onChange={handleChange}
                required
              />
              <br />
              7. UNIVERSITY/COLLEGE
              <input
                type="text"
                name="higherUniversity"
                value={formData.higherUniversity}
                onChange={handleChange}
                required
              />
              <br />
              8. YEAR
              <input
                type="number"
                name="higherYear"
                value={formData.higherYear}
                onChange={handleChange}
                required
              />
            </label>
            <br />
            <label>
              9. LOWER EDUCATION
              <input
                type="text"
                name="lowerEducation"
                value={formData.lowerEducation}
                onChange={handleChange}
                required
              />
              <br />
              10. UNIVERSITY/COLLEGE
              <input
                type="text"
                name="lowerUniversity"
                value={formData.lowerUniversity}
                onChange={handleChange}
                required
              />
              <br />
              11. YEAR
              <input
                type="number"
                name="lowerYear"
                value={formData.lowerYear}
                onChange={handleChange}
                required
              />
            </label>
            <br />
            {/* <label>
              12. EDUCATION
              <input type="text" name="ProjectHeading" value={formData.ProjectHeading} onChange={handleChange} /><br />
              13. UNIVERSITY/COLLEGE
              <input type="text" name="ProjectHeading" value={formData.ProjectHeading} onChange={handleChange} /><br />
              14. YEAR
              <input type="number" name="ProjectHeading" value={formData.ProjectHeading} onChange={handleChange} />
            </label> */}
            <br />
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}
        {step === 4 && (
          <div className="form-step">
            <label>
              SKILLS <br />
              <br />
              <label>
                15 Skills Description:
                <input
                  type="text"
                  name="skillsDescp"
                  value={formData.Paragraph}
                  onChange={handleChange}
                  required
                  style={{
                    padding: "25px",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    marginTop: "5px",
                  }}
                />
              </label>
              <br />
              {/* <button type="button" onClick={handleAdditionalDataSubmit}>
                Add more
              </button> */}
              {/* {formData.additionalData.map((data, index) => (
                <div key={index}>
                  <label>
                    Skills Description:
                    <input
                      type="text"
                      name="additionalSkills"
                      value={data.skills}
                      onChange={(e) => {
                        const newData = [...formData.additionalData];
                        newData[index].skills = e.target.value;
                        setFormData((prevData) => ({
                          ...prevData,
                          additionalData: newData,
                        }));
                      }}
                    />
                  </label>
                </div>
              ))} */}
            </label>

            <br />
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>

            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}
        {step === 5 && (
          <div className="form-step">
            WORK HISTORY
            <br />
            <br />
            <label>
              16.Designation:
              <input
                type="text"
                required
                name="workDesignation"
                value={formData.workDesignation}
                onChange={handleChange}
              />
              <br />
              17.Client Name :
              <input
                type="text"
                name="clientName"
                value={formData.clientName}
                onChange={handleChange}
                required
              />
              <br />
              18.year:
              <input
                type="text"
                name="workYear"
                value={formData.workYear}
                onChange={handleChange}
                required
              />
              <br />
              19. Description of project:
              <input
                type="text"
                name="projectDescp"
                value={formData.projectDescp}
                onChange={handleChange}
              />
              <br />
            </label>
            <br />
            {/* <button type="button" onClick={handleAdditionalDataSubmit}>
            Add more
          </button> */}
            <br />
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}

        {step === 6 && (
          <div className="form-step">
            CONTACT US
            <br />
            <br />
            <label>
              20. email
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <br />
              21. Contact
              <input
                type="tel"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                pattern="[0-9]{10}"
                required
              />
              <br />
              {/* <button type="submit">Add more</button> */}
            </label>
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="submit">Save</button>
          </div>
        )}
      </form>
    </div>
  );
};

export default Portfolioform2;
