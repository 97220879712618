import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Resume.css";
import Axios from "axios";
import menphoto from "./assets/menphoto.jpg";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";
import { MdOutlineArrowRight } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
 
function Resumeview() {
    const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          //   setIsLoggedIn(true);
        } else {
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);
 
  return (
    <div>
      <div className="main">
        <div className="left">
          <div className="aaa">
            <h2 className="profile"> <MdOutlineArrowRight />PROFILE </h2>
            <p className="hello">
              {" "}
             
              Hello, I am a Digital Marketer who
                    likes to engage and interact with
                    people.
            </p>
           
          </div>
          <div className="bbb">
            <h2><MdOutlineArrowRight />EXPERIENCE</h2>
            <p>
            Sr. Software Developer 2017


            </p>
            <p className="expdescp">6 year EXP in Fullstack Development</p>
 
          </div>
          <div className="bbb1">
            <h2><MdOutlineArrowRight />EDUCATION</h2>
            <p>Bachelors of Technology</p>
            <div className="eee">Fauget University I 2013-2017</div>
          </div>
          <div className="bbb2">
            <h2><MdOutlineArrowRight />SKILLS</h2>
            <p>ReactsJS, NodeJS</p>
          </div>
         
         
            <button className="Resume-view-service">View Service</button>
        
           
        </div>
        <div className="right">
          <div className="ccc">
            <div className="photo-men">
              <img
                src={menphoto}
                alt="men"
                className="men"
              />
              <div className="men-name">
                <h1 className="mmm">MURAD</h1>
                <h1 className="mmm1">NASER</h1>
                <p className="nnn">DIGITAL MARKETER</p>
              </div>
            </div>
 
            <div className="contact">
              <h2 className="ooo">CONTACT</h2>
              <div className="eight">
                <FaPhoneAlt className="phone" />
                <p className="num">123-456-7890</p>
                <IoIosMail className="email" />
                <p className="mail">marud@gmail.com</p>
                <MdLocationPin className="loc" />
                <p className="location">
                 NewYork
                  <br />
                  500000
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default Resumeview;
