
import React, { useState, useEffect } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import JSZip from "jszip";
import "./SuperAdmin/Portfolio5.css"
import Verticalbar from './SuperAdmin/verticalbar';

const Portfolio1 = () => {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    // Fetch user data from the backend API
    axios
      .get(`https://api.booking.ashishphotography.co.in/api/userdata`)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  const onDownload = async (userId, username) => {
    try {
      // Fetch portfolio data corresponding to the given login ID
      const response = await axios.get(
        `https://api.booking.ashishphotography.co.in/api/portfolioData/${userId}`
      );
      const portfolioData = response.data[0];

      // Download PDF and images
      downloadPDFAndImages(portfolioData, username);
    } catch (error) {
      console.error("Error fetching portfolio data:", error);
    }
  };

  const downloadPDFAndImages = async (data, username) => {
    const zip = new JSZip();

    try {
      // Generate the PDF
     
      const doc = new jsPDF();
            let currentY = 20;
            let currentPage = 1;
      
            const addText = (text) => {
              const textLines = doc.splitTextToSize(text, 170);
              textLines.forEach((line) => {
                if (currentY > 280) {
                  doc.addPage();
                  currentPage++;
                  currentY = 20;
                }
                doc.text(20, currentY, line);
                currentY += 10; // Adjust line height as needed
              });
            };

      // Add text to PDF
      addText("Page1");
      addText("");
      addText(`1 Name: ${data.name}`);
      addText(`2 Description: ${data.description}`);
      addText(`3 Image: ${data.image}`);
      addText("");
      addText("Page2");
      addText("");
      addText(`1 Expertise Descp: ${data.expertise_description}`);
      addText(`2 Skills: ${data.skills}`);
      addText("");
      addText("Page3");
      addText("");
      addText(`1 Portfolio Description: ${data.portfolio_descp}`);
      addText(`2 App Image: ${data.app_img}`);
      addText(`3 App Name: ${data.app_name}`);
      addText(`4 App Design: ${data.app_design}`);
      addText("");
      addText("Page4");
      addText("");
      addText(`1 Full Name: ${data.full_name}`);
      addText(`2 DOB: ${data.dob}`);
      addText(`3 Role: ${data.role}`);
      addText(`4 Email: ${data.email}`);
      addText(`5 Mobile: ${data.mobile_no}`);
      addText(`6 Location: ${data.location}`);
      addText(`7 Interests: ${data.intrests}`);
      addText(`8 Awards Won: ${data.awards_won}`);
      addText(`9 XP Years: ${data.xp_year}`);
      addText(`10 Clients: ${data.clients}`);
      addText(`11 Projects: ${data.project}`);
      const pdfBlob = doc.output("blob");

      // Add PDF to the zip file
      zip.file(`${username}.pdf`, pdfBlob);

      // Download the first image
      const image1Response = await axios.get(
        `https://api.booking.ashishphotography.co.in/uploads/${data.image}`,
        {
          responseType: "blob",
        }
      );
      const image1Blob = image1Response.data;
      zip.file(data.image, image1Blob);

      // Download the second image
      const image2Response = await axios.get(
        `https://api.booking.ashishphotography.co.in/uploads/${data.app_img}`,
        {
          responseType: "blob",
        }
      );
      const image2Blob = image2Response.data;
      zip.file(data.app_img, image2Blob);

      // Generate the zip file
      const zipBlob = await zip.generateAsync({ type: "blob" });

      // Create a temporary URL for the zip Blob
      const zipUrl = window.URL.createObjectURL(zipBlob);

      // Create a temporary anchor element for the zip file
      const zipAnchor = document.createElement("a");
      zipAnchor.href = zipUrl;
      zipAnchor.download = `${username}.zip`;
      zipAnchor.click();

      // Revoke the temporary URL
      window.URL.revokeObjectURL(zipUrl);
    } catch (error) {
      console.error("Error downloading files:", error);
    }
  };

  return (
    <div className="portfolio1">
          <div className='d-flex'>
          <Verticalbar />
            <div className='portfolio-1'>
              <h3>Portfolio 1</h3>
    
            <div className='portfolio-1-table'>
                 <table className='table table-1-hover table-hover'>
                   <thead>
                     <tr>
                     <th>ID</th>
                      <th>Username</th>
                     <th>Email</th>
                     <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData.map((user) => (
                      <tr key={user.id}>
                        <td>{user.id}</td>
                        <td>{user.username}</td>
                        <td>{user.email}</td>
                        <td>
                          <button onClick={() => onDownload(user.id, user.username)}>
                            Download
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    };
    
    export default Portfolio1;
    