import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

import "./Resumepage1.css";
import axios from "axios";
// import img from './assets/1.png';
// import img1 from './assets/img1.jpg';

const CounterAnimation = ({ title, count, animationDuration, delay }) => {
  const [animatedCount, setAnimatedCount] = useState(0);

  useEffect(() => {
    let startCount = 0;
    const increment = Math.ceil(count / (animationDuration / 50));

    const interval = setInterval(() => {
      startCount += increment;
      if (startCount >= count) {
        startCount = count;
        clearInterval(interval);
      }
      setAnimatedCount(startCount);
    }, 50);

    return () => clearInterval(interval);
  }, [count, animationDuration]);

  return (
    <div
      className="Stylesheet"
      data-animation-duration={animationDuration}
      data-animation-delay={delay}
    >
      <div className="u-container-layout u-similar-container u-valign-middle">
        <p className="u-align-center-xs uss-textss u-text-palette-1-base">
          {title}
        </p>
        <h3
          className="u-align-center-xs u-custom-font u-font-montserrat u-text u-text-palette-3-base"
          style={{ willChange: "contents" }}
        >
          {animatedCount}
        </h3>
      </div>
    </div>
  );
};

const Resumepage1 = ({ id, match }) => {
  let c_id = id;
  const [userId, setUserId] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const resumessElement = document.getElementById("resumess");
      const scrollPosition = window.scrollY;

      if (resumessElement && scrollPosition > resumessElement.offsetTop - 300) {
        resumessElement.classList.add("animate__animated", "animate__fadeInUp");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await axios.get(
          `https://api.booking.ashishphotography.co.in/getuserId/${c_id}`
        );
        setUserId(response.data.id);
      } catch (err) {
        console.error(err);
      }
    };

    fetchUserId();
  }, [id]);

  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://api.booking.ashishphotography.co.in/api/portfolioData/${userId}`
          );
          setData(response.data[0]);
        } catch (error) {
          setError(
            error.response ? error.response.data.error : "Error fetching data"
          );
        }
      };

      fetchData();
    }
  }, [userId]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="james-body">
      <div className="james-Img" data-aos="fade-left">
        <img
          src={`https://api.booking.ashishphotography.co.in/uploads/${data.image}`}
          alt="AnimatedImage"
        />
      </div> 
      <div data-aos="fade-right">
        <h1 className="james-header" data-aos="fade-up">
          Hello
          <br />
          I’m {data.name}
        </h1>
        <p className="james-descrip" data-aos="fade-up">
          {data.description}
        </p>
        {match? (<></>):( <Link to={`/serivcesbyid/${id}`} className="Resume4-service james-descrip">
          <Button
            variant="contained"
            data-aos="fade-out"
            data-aos-duration="2000"
          >
            View services
          </Button>
        </Link>)}
        
      </div>

      <div
        className="james-resume"
        style={{ backgroundColor: "#571B7E", height: "auto" }}
      >
        <div
          style={{ maxWidth: "300px", margin: "0 auto", textAlign: "center" }}
        >
          <h1>Expertise</h1>
          <p>{data.expertise_description}</p>
        </div>
        <div
          style={{ maxWidth: "350px", margin: "20px auto" }}
          className="resumess"
          id="resumess"
        >
          <div className="animate__animated animate__fadeInUp">
            <ul className="contain">
              <div>
                <h1>My skills</h1>
                <p>{data.skills}</p>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <div className="james-portfolio" style={{ backgroundColor: "#571B7E" }}>
        <div className="james-portfolio-content">
          <div className="james-text-content">
            <h1>My Portfolio</h1>
            <p>{data.expertise_description}</p>
          </div>
          <div className="portfolio-item">
            <img
              src={`https://api.booking.ashishphotography.co.in/uploads/${data.app_img}`}
              alt="Portfolio"
              className="portfolio-image"
            />
            <div className="portfolio-details">
              <p className="app-name">{data.app_name}</p>
              <p className="app-design">{data.app_design}</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="Details">
          <div className="james-profile">
            <p>
              Name:<br></br> {data.name}
            </p>
            <p>
              Birthday:<br></br> {data.dob}
            </p>
            <br></br>
            <p>
              Role:<br></br> {data.role}
            </p>
            <br></br>
            <p>
              Email: <br></br>
              {data.email}
            </p>
            <br></br>
            <p>
              Phone:<br></br> {data.mobile_no}
            </p>
            <br></br>
            {/* <p>Website:<br></br> {data.website}</p>
            <br></br> */}
            <p>
              Location:<br></br> {data.location}
            </p>
            <br></br>
            <p>
              Interests:<br></br> {data.intrests}
            </p>
            <br></br>
          </div>
          <div className="james-list">
            <div>
              <h3>XP YEARS</h3>
              <p>{data.xp_year} </p>
            </div>
            <div>
              <h3>clients</h3>
              <p> count={data.clients} </p>
            </div>
            <div>
              <h3>projects</h3>
              <p>{data.project} </p>
            </div>
            <div>
              <h3>Awards_won</h3>
              <p>{data.awards_won} </p>
            </div>

            {/* <CounterAnimation title="XP YEARS" count={data.xp_year} animationDuration={1750} delay={500} />
            <CounterAnimation title="clients" count={data.clients} animationDuration={1500} delay={500} />
            <CounterAnimation title="projects" count={data.project} animationDuration={1500} delay={500} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resumepage1;
