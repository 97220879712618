import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import axios from 'axios';
import './calender.css';
import { Box, Dialog, DialogTitle, DialogContent, Typography, Button } from '@mui/material';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const Calendar = () => {
  const [bookings, setBookings] = useState([]);
  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  // const [eventId, seteventId] = useState([]);
  // const [selectedDays, setselectedDays] = useState([]);
  // const navigate = useNavigate();
  

 useEffect(() => {
  // Fetch all bookings from the server
  axios
    .get('https://api.booking.ashishphotography.co.in/api/getAllBookings')
    .then((response) => {
      const events = response.data.map((booking) => ({
        id: booking.id,
        title: booking.Name,
        // Extract only the date without the time
        start: new Date(booking.date).toISOString().split('T')[0],
        // Exclude "12:00:00 AM" from the time
        time: booking.time !== '12:00:00 AM' ? booking.time : '',
      }));

      setBookings(events);
    })
    .catch((error) => {
      console.error('Error fetching bookings:', error);
    });
}, []);

const handleEventClick = async (selected) => {
  const { id } = selected.event;
  console.log('Clicked event with id:', id);

  try {
    const response = await axios.get(`https://api.booking.ashishphotography.co.in/api/getEventDetails?id=${id}`);
    console.log('Server response:', response.data);

    const eventData = response.data;

    // Update the state with the fetched details, ensure proper date formatting
    setSelectedEventDetails({
      id,
      Name: eventData.Name,
      Email: eventData.Email,
      Mobile_Number: eventData.Mobile_Number,
      date: new Date(eventData.date).toLocaleDateString(), // Format date to a string
      time: eventData.time,
      // Add other fields as needed
    });

    

    setDialogOpen(true);
  } catch (error) {
    console.error('Error fetching event details:', error);
  }
};

  
  
  
  // const handleEditEvent = () => {
  //   if (selectedEventDetails) {
  //     const eventId = selectedEventDetails.id;
  //     navigate(`/edit-event/${eventId}`);
  //   }
  // <Routes>
  //         <Route path="/" element={<Landingpage />} />
  //         <Route path="/creators" element={<Creators />} />
  //         <Route path="/scientists" element={<Scientists />} />
  //         <Route path="/software" element={<Software />} />
  //         <Route path="/creatorreviews/:id" element={<Creatorreviews />} />
  //         <Route path="/Home" element={<Home />} />
  //         <Route path="/mybookings" element={<MyBookings />} />
  //         <Route path="/pages" element={<Pages />} />
  //         <Route path="/reviews" element={<Reviews />} />
  //         <Route path="/services" element={<Services />} />
  //         <Route path="/payments" element={<Payments />} />
  //         <Route path="/webinar" element={<Webinar />} />
  //         <Route path="/profile" element={<Profile />} />
  //         <Route path="/main" element={<Main />} />
  //         <Route path="/preview2" element={<Resume1 />} />
  //         <Route path="/preview3" element={<Resume2 />} />
  //         <Route path="/preview4" element={<Resume3 />} />

  //         <Route path="/resume" element={<Resumepage />} />
  //         <Route path="/resume/:id" element={<Resumepage />} />
  //         <Route path="/serivcesbyid/:id" element={<Cards />} />
  //         <Route path="/bookingform" element={<BookingForm />} />
  //         <Route path="/Card" element={<Card />} />
  //         <Route path="/Subscribe" element={<Subscribe />} />
  //         <Route path="/portfolio" element={<Portfolio />} />
  //         <Route path="/portfolio1" element={<Portfolio1 />} />
  //         <Route path="/portfolio2" element={<Portfolio2 />} />
  //         <Route path="/portfolio3" element={<Portfolio3 />} />
  //         <Route path="/portfolio4" element={<Portfolio4 />} />
  //         <Route path="/login" element={<Login />} />
  //         <Route path="/forgetpassword" element={<Forgetpassword />} />
  //         <Route path="/Schedule" element={<Schedule />} />
  //         <Route path="/calender" element={<Calender />} />
  //         <Route path="/edit-event/:eventId" element={<EditEvent />} />
  //         <Route
  //           path="/bookingform/:serviceId/:serviceName"
  //           component={BookingForm}
  //         />
  //         <Route path="/:serviceId/:c_id" element={<Appointment />} />
  //         <Route path="/comment/:id" element={<Comment />} />
  //         <Route path="/dummy" element={<Dummy />} />

  //         {/* super-admin */}
  //         <Route path="/verticalbar" element={<Verticalbar />} />
  //         <Route path="/admin" element={<Admin />} />
  //         <Route path="/AdminBooking" element={<AdminBooking />} />
  //         <Route path="/adminservices" element={<AdminServices />} />
  //         {/* <Route path='/adminpayments' element={<AdminPayments/>}/> */}
  //         <Route path="/adminprofile" element={<AdminProfile />} />
  //         {/* <Route path='/Services' element={<Service/>}/> */}
  //         <Route path="/NewUsers" element={<NewUsers />} />
  //         <Route path="/AdminManagement" element={<AdminManagement />} />
  //         <Route path="/AdminPortfolio" element={<AdminPortfolio />} />
  //         <Route path="/Portfolio5" element={<Portfolio5 />} />
  //         <Route path="/Portfolio6" element={<Portfolio6 />} />
  //         <Route path="/Portfolio7" element={<Portfolio7 />} />
  //         <Route path="/Portfolio8" element={<Portfolio8 />} />
  //       </Routes>
  // };


  const handleDeleteEvent = async () => {
    if (selectedEventDetails) {
      const eventId = selectedEventDetails.id;
  
      if (window.confirm("Are you sure you want to delete this event?")) {
        try {
          // Make a DELETE request to delete the event
          await axios.delete(`https://api.booking.ashishphotography.co.in/api/remove/${eventId}`);
          toast.success("Event Deleted Successfully");
  
          // Update the state to reflect the deleted event
          setBookings((prevBookings) => prevBookings.filter((booking) => booking.id !== eventId));
  
          // Close the dialog
          handleClose();
        } catch (error) {
          console.error('Error deleting event:', error);
        }
      }
    }
  };
  
  

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedEventDetails(null);
  }



  return (
    <Box m="15px"  height="100vh"  >
      <br />
      <div style={{ height: '100vh', padding: '20px',  color:'white' }}>
      <FullCalendar
        height="100%"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        }}
        initialView="dayGridMonth"
        events={bookings}
        eventClick={handleEventClick}
      />
    </div>

<Dialog open={isDialogOpen} onClose={handleClose} >
  <DialogTitle>Event Details</DialogTitle>
  <DialogContent>
    {selectedEventDetails && (
      <>
      <Typography variant="h6">Name: {selectedEventDetails.Name}</Typography>
      <Typography variant="h6">Email: {selectedEventDetails.Email}</Typography>
      <Typography variant="h6">Date: {selectedEventDetails.date}</Typography>
      <Typography variant="h6">Mobile_Number:{selectedEventDetails.Mobile_Number}</Typography>
      <Typography variant="h6">Time: {selectedEventDetails.time}</Typography>

      <Link to={`/edit-event/${selectedEventDetails.id}`}>
                <Button >Edit</Button>
              </Link>
        <Button onClick={handleDeleteEvent} >Delete</Button>
        <Button onClick={handleClose} >Close</Button>
      </>
    )}
   
  </DialogContent>
</Dialog>

    </Box>
  );
};

export default Calendar;           