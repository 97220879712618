import React from "react";
import "./Subscribe.css";
import log from './assets/log.png'
import { Link } from "react-router-dom";
import { TbTopologyStar3 } from "react-icons/tb";
 
const Subscribe = () => {
  return (
    <div className="whole-1">
      <div className="bac-1">
        <p></p>
        <div className="lo-1">
          <p className="bo-1">
            <img src={log} alt="" className="log-1" />
            <div className="tor-1">
              <p className="nto-1">
                Mentorship / Career Guidance
              </p>
              <p className="nm-1">
                <p className="trik-1">$39/mo</p>
              </p>
            </div>
            <div>
              <p className="vi-1">Subscription | Monthly</p>
            </div>
           
            <div>
              <p className="nae-1">
                <TbTopologyStar3 className="icon-1" />
                Your Name
              </p>
 
              <input
                type="text"
                name="groupName"
                class="you"
                placeholder="Enter your name"
              />
            </div>
            <div>
              <p className="nae-1">
                <TbTopologyStar3 className="icon-1" />
                Email
              </p>
 
              <input
                type="text"
                name="groupName"
                class="you"
                placeholder="Enter your email"
              />
            </div>
 
            <div>
              <p className="nae-1">Phone number</p>
 
              <input
                type="text"
                name="groupName"
                class="you"
                placeholder="+91"
              />
            </div>
 
            <div className="last-1">
              <p className="five-1"><strike className="tik-1">$39</strike>$37/mo</p>
              <div className="paid-1">
                <p className="pay-1">Subscribe</p>
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};
 
export default Subscribe;