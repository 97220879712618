import React, { useState, useEffect } from "react";
import "./AdminService.css";
import Verticalbar from "./verticalbar";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { FaSearch } from "react-icons/fa";
import { Dropdown } from 'react-bootstrap';
import "./AdminManagement.css";
 
const Services = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
 
  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);
 
  const [filterName, setFilterName] = useState("All");
  const [fullData, setFullData] = useState([]); // Store the full dataset
  const [searchQuery, setSearchQuery] = useState("");
 
  useEffect(() => {
    fetchData();
  }, [filterName]);
 
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
 
  const fetchData = () => {
    const queryParam = filterName !== "All" ? `?serviceType=${filterName}` : "";
    fetch(`https://api.booking.ashishphotography.co.in/api/Service${queryParam}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setFullData(data);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
      });
  };
 
  // Filter data based on search query
  const filteredData = fullData.filter((item) =>
    item.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.serviceType.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.serviceName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.description.toLowerCase().includes(searchQuery.toLowerCase())
  );
 
  const handleFilterChange = (eventKey) => {
    setFilterName(eventKey);
  };
 
  return (
    <div className="d-flex">
      <Verticalbar />
 
      <div className="service-page" style={{ width: "100%", borderCollapse: "collapse" }}>
        <div className="header-container">
          <h4>Service Overview</h4>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {/* <FaSearch className="search-icon" /> */}
          </div>
         
        </div>
        <div >
            <Dropdown onSelect={handleFilterChange}>
              <Dropdown.Toggle variant="success" id="dropdown-basic" className="filter-container custom-dropdown-toggle">
                {filterName}
              </Dropdown.Toggle >
              <Dropdown.Menu className='custom-dropdown-menu'>
                <Dropdown.Item className='custom-dropdown-item' eventKey="All">All</Dropdown.Item>
                <Dropdown.Item className='custom-dropdown-item' eventKey="1:1">1:1</Dropdown.Item>
                <Dropdown.Item className='custom-dropdown-item' eventKey="Webinar">Webinar</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
 
        {filteredData.length > 0 ? (
          <div className="table-wrapper">
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Service Type</th>
                    <th>Service Name</th>
                    <th>Description</th>
                    <th>Price</th>
                    <th>Duration</th>
                    <th>Selected Schedule</th>
                  </tr>
                </thead>
 
                <tbody>
                  {filteredData.map((item) => (
                    <tr key={item.service_id}>
                      <td>{item.username}</td>
                      <td>{item.serviceType}</td>
                      <td>{item.serviceName}</td>
                      <td>{item.description}</td>
                      <td>{item.price}</td>
                      <td>{item.duration}</td>
                      <td>{item.selectedSchedule}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div>No results found.</div>
        )}
      </div>
    </div>
  );
};
 
export default Services;