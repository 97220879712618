import React, { useState, useEffect } from "react";
import "./AdminPortfolio.css";
import Verticalbar from "./verticalbar";
import portfolio4 from "./Asserts/portfolio1.png";
import portfolio2 from "./Asserts/portfolio2.png";
import portfolio3 from "./Asserts/portfolio3.png";
import portfolio1 from "./Asserts/portfolio4.png";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

function AdminPortfolio() {
    const [logoutStatus, setLogoutStatus] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate("/login");
          // localStorage.clear();
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);
  return (
    <div className="admin-portfolio">
      <div className="d-flex">
        <Verticalbar />
        <div className="portfolio-items">
          <div className="portfolio-imgs">
            <a href="Portfolio1" className="Portfolio-1">
              <img src={portfolio1} alt="Portfolio 1" />
              <p>Portfolio 1</p>
            </a>

            <a href="Portfolio2" className="Portfolio-1">
              <img src={portfolio2} alt="Portfolio 1"></img>
              <p>Portfolio 2</p>
            </a>
            <a href="Portfolio3" className="Portfolio-1">
              <img src={portfolio3} alt="Portfolio 1"></img>
              <p>Portfolio 3</p>
            </a>
            <a href="Portfolio4" className="Portfolio-1">
              <img src={portfolio4} alt="Portfolio 1"></img>
              <p>Portfolio 4</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPortfolio;
