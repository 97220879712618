import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Resume.css";
import menphoto from "./assets/menphoto.jpg";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";
import { MdOutlineArrowRight } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
 
function Resumepage({ id, match }) {
  // let { id } = useParams();
  // console.log(id)
  let c_id = id;
  const [uId, setUserId] = useState([]);

  console.log(match)
  
 
  useEffect(() => {
    if (c_id) {
      const fetchUserId = async () => {
        try {
          const response = await axios.get(
            `https://api.booking.ashishphotography.co.in/getuserId/${c_id}`
          );
          setUserId(response.data.id);
        } catch (err) {
          console.log(err);
        }
      };
 
      fetchUserId();
    }
  }, [c_id]);
 
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
 
  useEffect(() => {
    if (uId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://api.booking.ashishphotography.co.in/api/portfolio3Data/${uId}`
          );
          setData(response.data[0]);
        } catch (error) {
          setError(
            error.response ? error.response.data.error : "Error fetching data"
          );
        }
      };
 
      fetchData();
    }
  }, [uId]);
 
  return (
    <div>
      <div className="main">
        <div className="left">
          <div className="aaa">
            <h2 className="profile"> <MdOutlineArrowRight />PROFILE </h2>
            <p className="hello">
              {" "}
             
              {data.profileDescp}
            </p>
           
          </div>
          <div className="bbb">
            <h2><MdOutlineArrowRight />EXPERIENCE</h2>
            <p>
              {data.experienceheading} {data.expYear}
            </p>
            <p className="expdescp">{data.experienceDescp}</p>
 
          </div>
          <div className="bbb1">
            <h2><MdOutlineArrowRight />EDUCATION</h2>
            <p>{data.stream}</p>
            <div className="eee">{data.universityYear}</div>
          </div>
          <div className="bbb2">
            <h2><MdOutlineArrowRight />SKILLS</h2>
            <p>{data.skills}</p>
          </div>
          {match? (<></>):( <Link to={`/serivcesbyid/${id}`}>
            <button className="Resume-view-service">View Service</button>
          </Link>)}
          {/* <Link to={`/serivcesbyid/${id}`}>
            <button className="Resume-view-service">View Service</button>
          </Link> */}
           
        </div>
        <div className="right">
          <div className="ccc">
            <div className="photo-men">
              <img
                src={`https://api.booking.ashishphotography.co.in/uploads/${data.image}`}
                alt="men"
                className="men"
              />
              <div className="men-name">
                <h1 className="mmm">{data.firstName}</h1>
                <h1 className="mmm1">{data.lastName}</h1>
                <p className="nnn">{data.designation}</p>
              </div>
            </div>
 
            <div className="contact">
              <h2 className="ooo">CONTACT</h2>
              <div className="eight">
                <FaPhoneAlt className="phone" />
                <p className="num">{data.mobile}</p>
                <IoIosMail className="email" />
                <p className="mail">{data.email}</p>
                <MdLocationPin className="loc" />
                <p className="location">
                  {data.location}
                  <br />
                  {data.pincode}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default Resumepage;