import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes, FaBell } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './NotificationPopup.css';
 
const NotificationPopup = () => {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
 
  useEffect(() => {
    fetchNotifications();
  }, []);
 
  const fetchNotifications = async () => {
    try {
      const response = await axios.get('https://api.booking.ashishphotography.co.in/api/notifications');
      const formattedNotifications = response.data.map(({ id, username, portfolio_name, datetime }) => ({
        id,
        message: `${username} requested to create ${portfolio_name}<br>${datetime}`, // Add line break and display datetime below message
        path: getPathForPortfolio(portfolio_name) // Generate path based on portfolio name
      }));
      setNotifications(formattedNotifications);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setError(error.message);
    }
  };
 
  const getPathForPortfolio = (portfolioName) => {
    switch (portfolioName) {
      case 'portfolio1':
        return '/portfolio1';
      case 'portfolio2':
        return '/portfolio2';
      case 'portfolio3':
        return '/portfolio3';
      case 'portfolio4':
        return '/portfolio4';
      default:
        return '/defaultPortfolio';
    }
  };
 
  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };
 
  const closeNotifications = () => {
    setShowNotifications(false);
  };
 
  const deleteNotification = async (index) => {
    const notificationId = notifications[index].id;
    try {
      await axios.delete(`https://api.booking.ashishphotography.co.in/api/notifications/${notificationId}`);
      setNotifications(notifications.filter((_, i) => i !== index));
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };
 
  const handleNotificationClick = (path) => {
    navigate(path);
  };
 
  return (
    <div>
      <FaBell title="Notifications" onClick={toggleNotifications} />
      {showNotifications && (
        <div className="notification-popup">
          <div className="notification-header">
            <h4>Notifications</h4>
            <button onClick={closeNotifications}>Close</button>
          </div>
          {error ? (
            <div>Error: {error}</div>
          ) : (
            <ul className="notification-list">
              {notifications.map((notification, index) => (
                <li key={notification.id} className="notification-item">
                  <span onClick={() => handleNotificationClick(notification.path)}>
                    <div dangerouslySetInnerHTML={{ __html: notification.message }} />
                  </span>
                  <span className="notification-delete" onClick={() => deleteNotification(index)}>
                    <FaTimes style={{color:'black'}}/>
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};
 
export default NotificationPopup;