import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import './Piechart.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const Piechart = () => {
 
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Service Count',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    axios.get('https://api.booking.ashishphotography.co.in/service-count')
      .then(response => {
        const fetchedData = response.data;
        
        const labels = fetchedData.map(item => item.serviceType);
        const data = fetchedData.map(item => item.count);

        setChartData({
          labels,
          datasets: [
            {
              ...chartData.datasets[0],
              data,
            },
          ],
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Add chartData to the dependency array if using chartData inside useEffect

  const options = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,  // Adds padding between the legend items and the chart
        },
      },
    },
    layout: {
      padding: {
        bottom: 40,  // Adds padding between the chart and the bottom edge
      },
    },
  };

  return (
    <div className="chart-wrapper">
      <h4>Services</h4>
      <div className="chart-container">
        <Pie data={chartData} options={options} />
      </div>
    </div>
  );
};

export default Piechart;
