import React, { useState, useEffect } from "react";
import { Modal, Button, Pagination } from "react-bootstrap";
import "./AdminManagement.css";
import Verticalbar from "./verticalbar";
import { FaSearch } from "react-icons/fa";
import Page from "./Page";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
 
const MyVerticallyCenteredModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="edit-add-user">
          <div className="user">
            <label>User Name</label>
            <input type="text" placeholder="name"></input>
          </div>
          <div className="user">
            <label>email</label>
            <input type="text" placeholder="ravi@example.com"></input>
          </div>
          <div className="user">
            <label>contact</label>
            <input type="text" placeholder="9703466171"></input>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};
 
const Adminuser = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [editedEmployee, setEditedEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
 
  useEffect(() => {
    fetchEmployees();
  }, []);
 
  useEffect(() => {
    setTotalPages(Math.ceil(employees.length / itemsPerPage));
  }, [employees]);
 
  const [logoutStatus, setLogoutStatus] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
 
  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate("/login");
          // localStorage.clear();
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);
 
  const fetchEmployees = () => {
    fetch("https://api.booking.ashishphotography.co.in/api/User")
      .then((response) => response.json())
      .then((data) => {
        setEmployees(data); // Update state with fetched data
      })
      .catch((error) => console.error("Error fetching employees:", error));
  };
 
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = employees.slice(indexOfFirstItem, indexOfLastItem);
 
  const handleEditClick = (employee) => {
    setSelectedEmployee(employee);
    setEditedEmployee({ ...employee });
  };
 
  const handleDeleteClick = (employee) => {
    const updatedEmployees = employees.filter((emp) => emp !== employee);
    setEmployees(updatedEmployees);
  };
 
  const handleSaveChanges = () => {
    const updatedEmployees = employees.map((emp) =>
      emp === selectedEmployee ? editedEmployee : emp
    );
    setEmployees(updatedEmployees);
    setSelectedEmployee(null);
    setEditedEmployee(null);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
 
  const handleNextPage = () => {
    console.log("Next page clicked");
    if (currentItems.length === itemsPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };
 
  const handlePrevPage = () => {
    console.log("Previous page clicked");
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
 
  const filteredItems = employees.filter(
    (employee) =>
      employee.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
      employee.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      employee.phonenumber.toLowerCase().includes(searchQuery.toLowerCase())
  );
 
  const [modalShow, setModalShow] = useState(false);
 
  return (
    <div className="NewAdminComponent">
      {" "}
      {/* Apply the new classname here */}
      <div className="d-flex">
        <Verticalbar />
        <div className="Management-dashbord">
          <div className="Management-section-top">
            <h4>User Management</h4>
            <div className="Management-section-top-2">
            <div className="search-container">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                {/* <FaSearch className="search-icon" /> */}
              </div>
              {/* <div className='add-user'>
                <Button variant="primary" onClick={() => setModalShow(true)}>
                  Add User
                </Button>
               
              </div> */}
              <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
          <div className="totalEmployeePage">
            <div className="resourcePage">
              <div className="row resourceTable table-responsive mb-2">
                <table className="table table-hover">
                  <thead className="resourceTableHeader-1">
                    <tr>
                      <th scope="col">User Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">phonenumber</th>
                      {/* <th scope="col">Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredItems.map((employee, index) => (
                      <tr key={index}>
                        <td>{employee.username}</td>
                        <td>{employee.email}</td>
                        <td>{employee.phonenumber}</td>
                        {/* <td className='resourceActionIcons'>
          <i className="fa fa-pencil-square-o" aria-hidden="true" onClick={() => handleEditClick(employee)}></i>
          <i className="fa fa-trash" aria-hidden="true" onClick={() => handleDeleteClick(employee)}></i>
        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <Pagination>
                <Pagination.Prev onClick={handlePrevPage} disabled={currentPage === 1} />
                <Pagination.Next onClick={handleNextPage} disabled={currentItems.length < itemsPerPage} />
              </Pagination> */}
              <Page
                currentPage={currentPage}
                totalPages={totalPages}
                handlePrevPage={handlePrevPage}
                handleNextPage={handleNextPage}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
      {selectedEmployee && (
        <div className="overlay">
          <div className="popup edit-popup">
            <div className="popup-inner">
              <h4>Edit Employee Details</h4>
              <form>
                <div>
                  <label>User Name:</label>
                  <input
                    type="text"
                    name="username"
                    value={editedEmployee.username}
                    onChange={(e) =>
                      setEditedEmployee({
                        ...editedEmployee,
                        username: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <label>Email:</label>
                  <input
                    type="text"
                    name="email"
                    value={editedEmployee.email}
                    onChange={(e) =>
                      setEditedEmployee({
                        ...editedEmployee,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <label>Contact</label>
                  <input
                    type="text"
                    name="contact"
                    value={editedEmployee.contact}
                    onChange={(e) =>
                      setEditedEmployee({
                        ...editedEmployee,
                        contact: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="edit-popup-buttons">
                  <button type="button" onClick={handleSaveChanges}>
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedEmployee(null);
                      setEditedEmployee(null);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
 
export default Adminuser;