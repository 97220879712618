
import React, { useState, useEffect } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import JSZip from "jszip";

import './SuperAdmin/Portfolio5.css';
import Verticalbar from "./SuperAdmin/verticalbar";

const Portfolio2 = () => {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    // Fetch user data from the backend API
    axios
      .get(`https://api.booking.ashishphotography.co.in/api/get/portfolio2`)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  const onDownload = async (userId, username) => {
    try {
      // Fetch portfolio data corresponding to the given login ID
      const response = await axios.get(
        `https://api.booking.ashishphotography.co.in/api/portfolio2Data/${userId}`
      );
      const portfolioData = response.data[0];

      // Download PDF and images
      downloadPDFAndImages(portfolioData, username);
    } catch (error) {
      console.error("Error fetching portfolio data:", error);
    }
  };

  const downloadPDFAndImages = async (data, username) => {
    const zip = new JSZip();

    try {
      // Generate the PDF

      const doc = new jsPDF();
      let currentY = 20;
      let currentPage = 1;

      const addText = (text) => {
        const textLines = doc.splitTextToSize(text, 170);
        textLines.forEach((line) => {
          if (currentY > 280) {
            doc.addPage();
            currentPage++;
            currentY = 20;
          }
          doc.text(20, currentY, line);
          currentY += 10; // Adjust line height as needed
        });
      };

      // Add text to PDF
      addText("Page1");
      addText("");
      addText(`1 Name: ${data.name}`);
      addText(`2 Image: ${data.image}`);
      addText(`3 Designation: ${data.title}`);
      addText("");
      addText("Page2");
      addText("");
      addText(`1 About Me: ${data.aboutme}`);
      addText(`2 Description: ${data.aboutmedescription}`);
      addText("");
      addText("Page3");
      addText("");
      addText(`1 Lower Education: ${data.portfolio_descp}`);
      addText(`2 University/College: ${data.app_img}`);
      addText(`3 PassedOut Year: ${data.app_name}`);
      addText("");
      addText("Page4");
      addText(`1 Skills: ${data.skillsDescp}`);
      addText("");
      addText("Page5");
      addText("");
      addText("Work History");
      addText(`1 Designation: ${data.workDesignation}`);
      addText(`2 Client Name: ${data.clientName}`);
      addText(`3 Year: ${data.workYear}`);
      addText(`4  Description of project: ${data.projectDescp}`);
      addText("");
      addText("Page6");
      addText("");
      addText(`1 Email: ${data.email}`);
      addText(`1 Mobile: ${data.mobile}`);

      const pdfBlob = doc.output("blob");

      // Add PDF to the zip file
      zip.file(`${username}.pdf`, pdfBlob);

      // Download the first image
      const image1Response = await axios.get(
        `https://api.booking.ashishphotography.co.in/uploads/${data.image}`,
        {
          responseType: "blob",
        }
      );
      const image1Blob = image1Response.data;
      zip.file(data.image, image1Blob);

      // Generate the zip file
      const zipBlob = await zip.generateAsync({ type: "blob" });

      // Create a temporary URL for the zip Blob
      const zipUrl = window.URL.createObjectURL(zipBlob);

      // Create a temporary anchor element for the zip file
      const zipAnchor = document.createElement("a");
      zipAnchor.href = zipUrl;
      zipAnchor.download = `${username}.zip`;
      zipAnchor.click();

      // Revoke the temporary URL
      window.URL.revokeObjectURL(zipUrl);
    } catch (error) {
      console.error("Error downloading files:", error);
    }
  };

  return (
    <div className="portfolio1">
    <div className='d-flex'>
      <Verticalbar />
      <div className='portfolio-1'>
        <h3>Portfolio 2</h3>

        <div className='portfolio-1-table'>
          <table className='table table-1-hover table-hover'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Username</th>
            <th>Email</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {userData.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>
                <button onClick={() => onDownload(user.id, user.username)}>
                  Download
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
    </div>
    </div>
  );
};

export default Portfolio2;
