import React, { useState, useEffect } from 'react';
import './Loginpage.css';
import loginBg from './assets/login-bg.png';
import { toast, ToastContainer } from 'react-toastify';
import { FaUser, FaEnvelope, FaPhone,  FaLock } from "react-icons/fa";
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios';
import { Link, useNavigate } from "react-router-dom";


 
const SignupForm = () => {
    const [email, setemail] = useState('');
    const [phonenumber, setphonenumber] = useState('');
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [conformpassword, setconformpassword] = useState('');
 
//   const handleChange = (e) => {
//     const value = e.target.value;
//     const regex = /^[0-9\b]+$/; // Only numbers
 
//     if (value === "" || regex.test(value)) {
//       setPhoneNumber(value);
//     }
//   };
 
//   const handleBlur = () => {
//     if (phoneNumber.length !== 10) {
//       toast.error("Phone number must be exactly 10 digits.");
//     } else {
//       setErrorMessage("");
//     }
//   };

const navigate = useNavigate();
const [logoutStatus, setLogoutStatus] = useState("");
const [isLoggedIn, setIsLoggedIn] = useState(false);


useEffect(() => {
  Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
    .then((response) => {
      if (response.data.loggedIn) {
        setIsLoggedIn(true);
        navigate("/");
      } else {
        // navigate("/login");
        // localStorage.clear();
      }
    })
    .catch((error) => {
      console.error("Error checking authentication:", error);
      // navigate("/login");
    });
}, [navigate]);

Axios.defaults.withCredentials = true;


function isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
}

function Validation() {
    let emailregex = /^[a-zA-Z0-9]+@(gmail|yahoo|outlook)\.com$/;
    let userregex = /^[a-zA-Z0-9]{5,}$/;
    let phoneRegex = /^\d{10}$/;


    if (isEmptyOrSpaces(username) || isEmptyOrSpaces(email) || isEmptyOrSpaces(phonenumber) || isEmptyOrSpaces(password)) {
        alert("you cannot leave any field empty");
        return false;
    }

    if (!emailregex.test(email)) {
        alert("Enter a valid email");
        return false;
    }
    if (!userregex.test(username)) {
        alert("- Username can only be alphanumeric\n- Username must be at least 5 characters\n- Username cannot contain spaces");
        return false;
    }
    if (!phoneRegex.test(phonenumber)) {
        alert("Enter a valid phone number");
        return false;
    }

    return true;
}

const handleregistration = (event) => {
    event.preventDefault();
    if (!Validation()) {
        return;
    }

    if (password === conformpassword) {
        Axios.post('https://api.booking.ashishphotography.co.in/registration', {
            username: username,
            password: password,
            email: email,
            phonenumber: phonenumber
        }).then((response) => {
            console.log(response);
            toast.success('Registration successful');
            navigate("/login");
             // Reset form values to empty
             setusername('');
             setemail('');
             setphonenumber('');
             setpassword('');
             setconformpassword('');
        }).catch((error) => {
            console.error("Error registering:", error);
            if (error.response && error.response.status === 400) {
                if (error.response.data.includes("Username")) {
                    toast.error("Username already exists");
                } else if (error.response.data.includes("Email")) {
                    toast.error("Email already exists");
                } else if (error.response.data.includes("Phone number")) {
                    toast.error("Phone number already exists");
                } else {
                    toast.error("Error during registration");
                }
            } else {
                toast.error("An error occurred during registration");
            }
        });
    } else {
        toast.error("Passwords do not match");
    }
};



  return (
    <div className="login-page">
      <ToastContainer /> {/* Add ToastContainer for toast notifications */}
      <img src={loginBg} alt="background" className="login__bg" />
 
      <form action="" className="login__form">
        <h1 className="login__title">Sign Up</h1>
 
        <div className="login__inputs">
          <div className="login__box">
            <input placeholder="username" type="text" required onChange={(e) => setusername(e.target.value)}  className="login__input" />
            <FaUser />
          </div>
 
          <div className="login__box">
            <input placeholder="Email" type="text" className="login__input" onChange={(e) => setemail(e.target.value)} required  />
            <FaEnvelope />
          </div>
 
          <div className="login__box">
          <input placeholder="Phone Number" type="tel" className="login__input" onChange={(e) => setphonenumber(e.target.value)} required />
            <FaPhone />
          </div>
 
          <div className="login__box">
          <input placeholder="password" type="password" className="login__input" onChange={(e) => setpassword(e.target.value)} required />
          <FaLock/>
          </div>
          <div className="login__box">
          <input placeholder="Confirm Password" type="password" className="login__input" onChange={(e) => setconformpassword(e.target.value)} required />
            <FaLock/>
          </div>
        </div>
 
        <button type="submit" onClick={handleregistration} className="login__button">Submit</button>
 
        <div className="login__register">
          Already registered? <Link to={'/login'} style={{cursor:"pointer"}}>Login</Link>
        </div>
      </form>
    </div>
  );
};
 
export default SignupForm;