import React, { useEffect, useState } from "react";
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
 
import AOS from "aos";
import "aos/dist/aos.css";
import "./Resume4.css";
import { Link } from "react-router-dom";
import bannerImg from "./assets/banner.jpg";
import { MdOutlineArrowRight } from "react-icons/md";
export default function Resumepage3({ id, match }) {
  React.useEffect(() => {
    AOS.init();
  }, []);
 
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
 
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
 
    setState({ ...state, [anchor]: open });
  };
 
  const menuList = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Home", "About us", "Contact us", "Our services"].map((text) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
 
  const photos = [
    {
      src: "images01.nicepagecdn.com/a1389d7bc73adea1e1c1fb7e/9239811de8205445af21052d/pexels-photo-4107897.jpeg",
      width: 800,
      height: 600,
    },
    {
      src: "images01.nicepagecdn.com/a1389d7bc73adea1e1c1fb7e/9511ac3b37495996a3254cb7/pexels-photo-7766563.jpeg",
      width: 1600,
      height: 900,
    },
  ];
 
 
  let c_id = id;
  const [userId, setUserId] = useState([]);
  // console.log(userId)
 
  useEffect(() => {
      const fetchUserId = async () => {
        try {
          const response = await axios.get(`https://api.booking.ashishphotography.co.in/getuserId/${c_id}`);
          setUserId(response.data.id);
         
        } catch (err) {
         console.log(err)
        }
      };
 
      fetchUserId();
    }, [c_id]);
 
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
 
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://api.booking.ashishphotography.co.in/api/portfolio4Data/${userId}`);
          setData(response.data[0]);
         
        } catch (error) {
          setError(error.response ? error.response.data.error : 'Error fetching data');
        }
      };
 
      fetchData();
    }, [userId]);
 
  return (
    <>
      <div className="Resume-4">
        {/* Banner  */}
        <div
          className="aboutSec paddTop"
          data-aos="fade-out"
          data-aos-duration="2000"
        >
          <Container maxWidth="xl" className="resume4-1">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={7}>
              <Grid item xs={12} md={12} lg={5}>
                <img
                  className="bannerimg-2"
                  src={`https://api.booking.ashishphotography.co.in/uploads/${data.image}`}
                  data-aos="slide-left"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-duration="2000"
                  alt="banner"
                  width="100%"
                />
              </Grid>
                <div
                  className="bannerLeftSec"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                >
                  <h1
                    className="pageTitle darkParaTextWhite-1"
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                  >
                    {/* I’m Linda Larson, a twenty-nine-year-old UI/UX designer from
                    Los Angeles, CA */}{data.title}
                  </h1>
                  <h5
                    className="subTitle darkParaTextWhite-2"
                    data-aos="fade-out"
                    data-aos-duration="2000"
                  >
                    {/* Currently I am co-leading a design team at web design
                    studio. */}{data.titleDescp}
                  </h5>
                  <br></br>
                  {/* <br></br> */}
                  {match? (<></>):( <Link to={`/serivcesbyid/${id}`}  className="Resume4-service">
                    <Button
                   
                      variant="contained"
                      data-aos="fade-out"
                      data-aos-duration="2000"
                    >
                      View services
                    </Button>
                  </Link>)}
                  {/* <Link to={`/serivcesbyid/${id}`}  className="Resume4-service">
                    <Button
                   
                      variant="contained"
                      data-aos="fade-out"
                      data-aos-duration="2000"
                    >
                      View services
                    </Button>
                  </Link> */}
 
        {/* Services */}
        <section className="serviceSec paddTop paddBottom100 secTop">
          <Container maxWidth="lg">
            <div className="secHeadFlex">
              <h3 className="darkParaTextWhite subTitle-2">
              <MdOutlineArrowRight />Experience :</h3>
              <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                  <div className="serviceBox">
                    <p className="secPara-1 textCenter">
                    {data.experienceDescp}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
 
        {/* Projects */}
        <section className="serviceSec paddTop paddBottom100 secTop">
          <Container maxWidth="lg">
            <div className="secHeadFlex">
              <h3 className="darkParaTextWhite subTitle-2">
              <MdOutlineArrowRight />My projects :</h3>
              <h5 className="darkParaTextWhite secTitle-3 w-200">
                {/* I've designed dozens of appealing interfaces for websites and
                web apps. Take a look at my projects below. */}{data.projectDescp}
              </h5>
            </div>
          </Container>
        </section>
 
        {/* Skills */}
        <section className="paddTop paddBottom100 secTop prototypeContainer">
          <Container maxWidth="lg">
            <div className="secHeadFlex">
              <h3 className="darkParaTextWhite subTitle-5">
              <MdOutlineArrowRight />Skills :</h3>
              <h5 className="darkParaTextWhite secTitle-3 w-200">
                {/* UI/UX design, Application design, UX research, Visual
                communication, Prototyping Headline */}{data.skills}
              </h5>
            </div>
          </Container>
        </section>
 
 
 
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={5}>
                <img
                  className="bannerimg"
                  src={`https://api.booking.ashishphotography.co.in/uploads/${data.image}`}
                  data-aos="slide-left"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-duration="2000"
                  alt="banner"
                  width="100%"
                />
              </Grid>
            </Grid>
          </Container>
        </div>
 
 
        {/* Consultation */}
        <section className="paddTop paddBottom100 secTop prototypeContainer">
          <Container maxWidth="lg">
            {/* <div className="secHeadFlexCenter">
              <div>
                <h1 className="darkParaTextWhite secTitle font35">
                  Get your free consultation now!
                </h1>
                <p className="darkParaTextWhite secPara w-200 font18">
                  Small or big, your business will love our financial help and
                  business consultations! We are happy when our clients are too…
                </p>
              </div>
            </div> */}
 
            <div className="secTop">
              <Grid container spacing={3} className="secTop">
                <Grid item xs={12} md={6} lg={6}>
                  <p className="darkParaTextWhite subTitle9-5 font25">
                    {/* New York,<br></br> 4456 Parker Rd. <br></br>Allentown,
                    <br />
                    New Mexico 4567 */}{data.address}
                  </p>
                  {/* <br /> */}
                  <h4 className="darkParaTextWhite subTitle-5 font25">
                    <span>{data.mobile}</span>
                    <br />
                    <span className="darkParaTextWhite secTitle pink font20 d-block">
                      {data.email}
                    </span>
                  </h4>
                </Grid>
                <br />
                <br />
                <Grid item xs={12} md={6} lg={6}>
                  <div className="sign-up">
                    <h2 className="darkParaTextWhite secTitle font30">
                      Sign me up!
                    </h2>
                    <p className="darkParaTextWhite secPara font18">
                     {data.signUpDescp}
                    </p>
                    <div className="secTop50">
                      <form
                        action="#"
                        className=""
                        source="email"
                        name="form"
                        style={{ padding: 0 }}
                      >
                        {/* Form fields can be added here */}
                      </form>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
}
 