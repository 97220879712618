import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import { FaBars, FaHome } from "react-icons/fa";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { IoStarSharp } from "react-icons/io5";
// import { LuFiles } from "react-icons/lu";
import { LuPhoneCall } from "react-icons/lu";
import { PiUsersThreeBold } from "react-icons/pi";
// import logolms from "../Sidebar/img/logolms.png";
import { FaCircleUser } from "react-icons/fa6";
// import { MdOutlineAdminPanelSettings } from "react-icons/md";
// import { SlCalender } from "react-icons/sl";
import { MdOutlinePayment } from "react-icons/md";
import { GrBusinessService } from "react-icons/gr";
import { ImCross } from "react-icons/im";
import { MdSchedule } from "react-icons/md";
import { BiLogOut } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
const routes = [
  {
    path: "/home",
    name: "Home",
    icon: <FaHome />,

  },
  {
    path: "/mybookings",
    name: "My Bookings",
    icon: <LuPhoneCall />,
  },
  {
    path: "/pages",
    name: "Pages",
    icon: <PiUsersThreeBold />,
  },
  {
    path: "/reviews",
    name: "Reviews",
    icon: <IoStarSharp />,
  },
  {
    path: "/services",
    name: "Services",
    icon: <GrBusinessService />,
  },
  {
    path: "/payments",
    name: "Payments",
    icon: <MdOutlinePayment />,
  },
  {
    path: "/profile",
    name: "Profile",
    icon: <FaCircleUser />,
  },
  {
    path: "/Schedule",
    name: "Schedule",
    icon: <MdSchedule />,
  },
];

const SideBar = ({ isOpen, setIsOpen, children }) => {
  // var [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };
  const [logoutStatus, setLogoutStatus] = useState("");
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      Axios.get("https://api.booking.ashishphotography.co.in/logout", { withCredentials: true }).then(
        (response) => {
          if (response.data.loggedOut) {
            setLogoutStatus("Logged out successfully");
            // localStorage.removeItem('userId');
            navigate("/"); 
         
          } else {
            setLogoutStatus("Logout failed");
          }
        }
      );
    }
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "200px" : "45px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  {/* <img src="" alt="makenow" style={{width : '40px', height: '40px' }}/> */}
                  <h3 className="mt-3">Creative Thoughts</h3>
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars">
              {isOpen ? (
                <ImCross style={{ cursor: "pointer" }} onClick={toggle} />
              ) : (
                <FaBars style={{ cursor: "pointer" }} onClick={toggle} />
              )}
            </div>
          </div>

          <section className="routes">
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                    key={index}
                  />
                );
              } 

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="m-active"
                  style={{ textDecoration: "none" }}
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
            <NavLink
              onClick={handleLogout}
              className="link"
              activeClassName="m-active"
              style={{ textDecoration: "none" }}
            >
              <div className="icon">
                <BiLogOut />
              </div>
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="link_text"
                  >
                    Logout
                  </motion.div>
                )}
              </AnimatePresence>
            </NavLink>
          </section>
        </motion.div>

        <main className={isOpen ? "open" : "closed"}>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
