// Defaultresume.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Resumepage from "./Resumepage";
import Resumepage1 from "./Resumepage1";
import Resumepage3 from "./Resumepage3";
import "./Defaultresume.css";

const Defaultresume = () => {
  let { id } = useParams();
  const [creator, setCreator] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [creatorUID, setcreatorUID] = useState("");

  const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;

  console.log(userId);
  console.log(creatorUID);

  const match = userId === creatorUID;
  console.log(match);

  useEffect(() => {
    if (id) {
      const fetchCreator = async () => {
        try {
          const response = await axios.get(
            `https://api.booking.ashishphotography.co.in/api/creators/${id}`
          );
          setCreator(response.data);
          setcreatorUID(response.data.id);
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };

      fetchCreator();
    }
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!creator) {
    return <div>No creator found</div>;
  }

  return (
    <div>
      {creator.portfolio === "portfolio2" ? (
        <Resumepage id={id} match={match} />
      ) : creator.portfolio === "default portfolio" ? (
        <>
          <div className="Default-set">
            <div className="default-image">
              <img
                src={`https://api.booking.ashishphotography.co.in/uploads/${creator.image}`}
                // style={{ height: "300px", width: "300px" }}
                alt={creator.display_name}
              />
              <h1>{creator.display_name}</h1>
            </div>
            <div className="defalt-data">
              <form>
                <label>
                  First Name:
                  <input type="text" value={creator.first_name} readOnly />
                </label>
                <br />
                <label>
                  Last Name:
                  <input type="text" value={creator.last_name} readOnly />
                </label>

                <br />
                <label>
                  Email:
                  <input type="text" value={creator.about} readOnly />
                </label>
                <br />
                <label>
                  Category:
                  <input type="text" value={creator.category} readOnly />
                </label>
                <br />
                <label>
                  Intro:
                  <input type="text" value={creator.intro} readOnly />
                </label>
                <br />
                <label>
                  About:
                  <input type="text" value={creator.about} readOnly />
                </label>
              </form>
              <br />
              {match ? (
                <></>
              ) : (
                <div className="button-container">
                  <Link to={`/serivcesbyid/${id}`}>
                    <button className="btn btn-primary view-service">
                      View Services
                    </button>
                  </Link>
                </div>
              )}
              {/* {userId !== creatorUID?(<Link to={`/serivcesbyid/${id}`}>
                <button className="btn btn-primary view-service">View Service</button>
              </Link>):(<></>)} */}
              {/* <Link to={`/serivcesbyid/${id}`}>
                <button className="btn btn-primary view-service">View Service</button>
              </Link> */}
            </div>
          </div>
        </>
      ) : creator.portfolio === "portfolio3" ? (
        <Resumepage3 id={id} match={match} />
      ) : creator.portfolio === "portfolio1" ? (
        <Resumepage1 id={id} match={match} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Defaultresume;
