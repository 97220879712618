import React, { useState, useEffect } from "react";
import "./MultiStepForm.css"; // Import the CSS file
import Img1 from "./assets/1.png";
import Img2 from "./assets/2.png";
import Img3 from "./assets/3.png";
import Img4 from "./assets/4.png";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

// import jsPDF from 'jspdf';
import axios from "axios";

const Portfolioform1 = () => {
  const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;

 
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);

  

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: "",
    expertiseDesc: "",
    skills: "",
    portfolioDesc: "",
    card1Image: "",
    card1Name: "",
    card1Skill: "",
    fullName: "",
    birthday: "",
    role: "",
    email: "",
    mobile: "",
    location: "",
    intrest: "",
    awards: "",
    xpyear: "",
    clients: "",
    projects: "",
  });

  const handleImageChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Generate a unique filename
      // const timestamp = Date.now(); // Get the current timestamp
      const originalFilename = file.name; // Get the original filename
      // const extension = originalFilename.split('.').pop(); // Get the file extension
      // const uniqueFilename = `${timestamp}_${originalFilename}`; // Combine timestamp and original filename
      // console.log(uniqueFilename)
      // // Update the form data with the unique filename
      // setFormData((prevData) => ({ ...prevData, [name]:uniqueFilename  }));
      // // uploadImage(file, uniqueFilename);
      uploadImage(file, name);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const uploadImage = async (file, name) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      // formData.append('filename', filename);
      formData.append("userId", userId);

      const response = await axios.post(
        "https://api.booking.ashishphotography.co.in/port/uploadImage",
        formData
      );
      const { filename } = response.data; // Extract the filename from the response
      console.log(filename);
      console.log("Image uploaded successfully!");
      setFormData((prevData) => ({ ...prevData, [name]: filename }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const [step, setStep] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleContinue = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // You can submit the form data here or perform any other action
    console.log(formData);

    // Send data to Backend
    const response = await fetch("https://api.booking.ashishphotography.co.in/api/savePDF", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        formData,
        userId,
      }),
    });

    if (response.ok) {
      console.log("PDF saved successfully.");
      // Reset form data after successful submission
      setFormData({
        description: "",
        image: "",
        expertiseDesc: "",
        skills: "",
        portfolioDesc: "",
        card1Image: "",
        card1Name: "",
        card1Skill: "",
        fullName: "",
        birthday: "",
        role: "",
        email: "",
        mobile: "",
        location: "",
        intrest: "",
        awards: "",
        xpyear: "",
        clients: "",
        projects: "",
      });
      navigate("/pages");
    } else {
      console.error("Error saving PDF.");
    }
  };

  const getImageForStep = () => {
    switch (step) {
      case 1:
        return <img className="screenshot" src={Img1} alt="Step 1" />;
      case 2:
        return <img className="screenshot" src={Img2} alt="Step 2" />;
      case 3:
        return <img className="screenshot" src={Img3} alt="Step 3" />;
      case 4:
        return <img className="screenshot" src={Img4} alt="Step 4" />;

      default:
        return null;
    }
  };

  return (
    <div className="multi-step-form-container">
      {getImageForStep()}
      <form onSubmit={handleSubmit} className="form" autoComplete>
        {step === 1 && (
          <div className="form-step">
            <label>
              1
              <input
                placeholder="Name"
                type="text"
                required
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </label>
            <br />
            <label>
              2
              <input
                className="img"
                type="file"
                required
                name="image"
                onChange={handleImageChange}
              />
            </label>
            <br />
            <label>
              3
              <input
                type="text"
                placeholder="Description"
                required
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </label>
            <br />
            <button type="button" onClick={handleContinue}>
              Save & Continue
            </button>
          </div>
        )}

        {step === 2 && (
          <div className="form-step">
            <label>
              1
              <input
                placeholder="Expertise Description"
                required
                type="text"
                name="expertiseDesc"
                value={formData.expertiseDesc}
                onChange={handleChange}
              />
            </label>
            <label>
              2
              {/* <input placeholder='Skills' type="text" name="skills" value={formData.skills} onChange={handleChange} /> */}
              <textarea
                placeholder="Skills"
                type="text"
                required
                name="skills"
                value={formData.skills}
                onChange={handleChange}
                cols="20"
                rows="3"
              ></textarea>
            </label>

            <div>
              <button type="button" onClick={handlePrevious}>
                Previous
              </button>
              <button type="button" onClick={handleContinue}>
                Save & Continue
              </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="form-step">
            <label>
              1
              <input
                placeholder="Portfolio Description"
                required
                type="text"
                name="portfolioDesc"
                value={formData.portfolioDesc}
                onChange={handleChange}
              />
            </label>
            <br />
            <label>card1</label>

            <label>
              2
              <input
                className="img"
                type="file"
                name="card1Image"
                required
                onChange={handleImageChange}
              />
            </label>
            <br />
            <label>
              3
              <input
                placeholder="Name"
                type="text"
                name="card1Name"
                required
                value={formData.card1Name}
                onChange={handleChange}
              />
            </label>
            <br />
            <label>
              4
              <input
                placeholder="Design"
                type="text"
                name="card1Skill"
                required
                value={formData.card1Skill}
                onChange={handleChange}
              />
            </label>
            <br />

            <br />
            <div>
              <button type="button" onClick={handlePrevious}>
                Previous
              </button>
              <button type="button" onClick={handleContinue}>
                Save & Continue
              </button>
            </div>
          </div>
        )}
        {step === 4 && (
          <div className="form-step">
            <label>
              1
              <input
                placeholder="Name"
                type="text"
                name="fullName"
                required
                value={formData.fullName}
                onChange={handleChange}
              />
            </label>
            <br />
            <label>
              2
              <input
                placeholder="birthday"
                type="date"
                name="birthday"
                required
                value={formData.birthday}
                onChange={handleChange}
              />
            </label>
            <label>
              3
              <input
                placeholder="role"
                type="text"
                name="role"
                required
                value={formData.role}
                onChange={handleChange}
              />
            </label>
            <br />
            <label>
              4
              <input
                placeholder="email"
                type="text"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </label>
            <br />
            <label>
              5
              <input
                type="tel"
                placeholder="mobile number"
                required
                value={formData.mobile}
                onChange={handleChange}
                name="mobile"
                pattern="[0-9]{10}"
              />
            </label>
            <br />
            <label>
              6
              <input
                placeholder="location"
                type="text"
                name="location"
                requiredvalue={formData.location}
                onChange={handleChange}
              />
            </label>
            <br />
            <label>
              7
              <input
                placeholder="intrests"
                type="text"
                name="intrest"
                required
                value={formData.intrest}
                onChange={handleChange}
              />
            </label>
            <br />
            <label>
              8
              <input
                placeholder="Awards Won"
                type="text"
                name="awards"
                required
                value={formData.awards}
                onChange={handleChange}
              />
            </label>
            <br />
            <label>
              9
              <input
                type="number"
                placeholder="XP years"
                value={formData.xpyear}
                onChange={handleChange}
                name="xpyear"
                required
              />
            </label>
            <br />
            <label>
              10
              <input
                placeholder="clients"
                type="text"
                name="clients"
                required
                value={formData.clients}
                onChange={handleChange}
              />
            </label>
            <br />
            <label>
              11
              <input
                placeholder="projects"
                type="text"
                name="projects"
                required
                value={formData.projects}
                onChange={handleChange}
              />
            </label>
            <br />

            <button type="button" onClick={handlePrevious}>
              Previous
            </button>

            <input type="submit" value="submit" />
          </div>
        )}
      </form>
    </div>
  );
};

export default Portfolioform1;
