 
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import "./Home.css";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import SideBar from "./Sidebar";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
 
function MyVerticallyCenteredModal(props) {
  const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;
 
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [display_name, setDisplay_name] = useState("");
  const [intro, setIntro] = useState("");
  const [about, setAbout] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
 
  const [uploadImage, setUplaodImage] = useState();
 
  const handleImageUpload = async (e) => {
    e.preventDefault();
 
    try {
      const formData = new FormData();
 
      formData.append("photo", uploadImage);
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("display_name", display_name);
      formData.append("category", category);
      formData.append("intro", intro);
      formData.append("about", about);
 
      await axios.put(
        `https://api.booking.ashishphotography.co.in/updateImageanddata/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      window.location.reload();
      console.log("Data updated successfully");
    } catch (error) {
      console.error("Error updating data:", error);
    }
    // window.location.reload();
  };
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.booking.ashishphotography.co.in/creators/${userId}`
        ); // Assuming the endpoint for a specific creator
        const data = response.data;
        setFirst_name(data.first_name);
        setLast_name(data.last_name);
        setDisplay_name(data.display_name);
        setCategory(data.category);
        setIntro(data.intro);
        setAbout(data.about);
        setImage(data.image);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
 
    fetchData();
  }, [userId]);
 
 
  const handleDropdownChange = (event) => {
    setCategory(event.target.value);
  };
 
  const dropdownValues = ["Data", "Creators", "Software"];
 
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Profile Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="frm">
          <form onSubmit={handleImageUpload}>
            <div
              className="profile-top"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="profile-photo">
                {image ? (
                  <img
                    src={`https://api.booking.ashishphotography.co.in/uploads/${image}`}
                    alt="Uploaded"
                    style={{ width: "140px", height: "170px" }}
                  />
                ) : (
                  <p>No image uploaded</p>
                )}
              </div>
              <div className="photo-dic">
                <label htmlFor="fileInput">Upload Image:</label>
                <input
                  type="file"
                  id="fileInput"
                  name="photo"
                  required
                  onChange={(e) => setUplaodImage(e.target.files[0])}
                />
              </div>
            </div>
            <div className="namebox">
              <div className="edit1">
                <label className="firstName">First Name </label> <br />
                <input
                  type="text"
                  name="first_name"
                  value={first_name}
                  onChange={(e) => setFirst_name(e.target.value)}
                  id="firstNamebox"
                  placeholder="First Name"
                  required
                />
              </div>
              <div className="edit">
                <label className="lastName">Last Name </label> <br />
                <input
                  type="text"
                  name="last_name"
                  value={last_name}
                  onChange={(e) => setLast_name(e.target.value)}
                  id="lastNamebox"
                  placeholder="Last Name"
                  required
                />
              </div>
            </div>
            <label className="fullName">Display Name</label> <br />
            <input
              type="text"
              name="display_name"
              value={display_name}
              onChange={(e) => setDisplay_name(e.target.value)}
              id="fullName"
              placeholder="Display Name"
              required
            />
            <br />
            <div className="Category">
              <label htmlFor="categoryDropdown">Select a Category : </label>
              <select
                id="categoryDropdown"
                value={category}
                onChange={handleDropdownChange}
                required
              >
                <option value="">Select...</option>
                {dropdownValues.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <br />
            <label className="additional">
              {" "}
              Your topmate intro (Required)
            </label>{" "}
            <br />
            <input
              type="text"
              id="additionalInfo"
              name="intro"
              value={intro}
              required
              onChange={(e) => setIntro(e.target.value)}
              placeholder="This is the first thing people will see"
            />
            <br />
            <label className="messagebox"> About yourself</label> <br />
            <input
              type="message"
              id="addmessage"
              name="about"
              required
              value={about}
              onChange={(e) => setAbout(e.target.value)}
              placeholder="Tell us more about yourself, your interests and your experience"
            />
            <hr />
            <input
              style={{ width: "100px" }}
              type="submit"
              value="Save"
              className="btn btn-dark save-2"
            />
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
 
export const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
 
  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate("/login");
          // localStorage.clear();
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);
 
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
 
  const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;
 
  const [uname, setuName] = useState();
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.booking.ashishphotography.co.in/user/${userId}`
        ); // Assuming the endpoint for a specific creator
        const data = response.data;
        setuName(data.username);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
 
    fetchData();
  }, [userId]);
 
  const [modalShow, setModalShow] = useState(false);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.booking.ashishphotography.co.in/creators/${userId}`
        ); // Assuming the endpoint for a specific creator
        const data = response.data;
 
        console.log(data.first_name);
 
        if (data.first_name === null) {
          setModalShow(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
 
    fetchData();
  }, [userId]);
 
  return (
    <div>
      {isLoggedIn ? (
        <div className="d-flex">
          <SideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
          <div className="home">
            <h1
              data-aos="fade-down"
              className="user-name"
              style={{ textAlign: "left" }}
            >
              Hi, {uname}
            </h1>
            <MyVerticallyCenteredModal
              show={modalShow}
              // onHide={() => setModalShow(false)}
            />
            <div className="top-cont">
              {/* <div className="left-cont"> */}
              <div data-aos="fade-up" className="accord">
                <h3>Profile Overview</h3>
                {/* <p>Get featured on our exclusive new launches page </p> */}
                <Accordion className="accordiann" defaultActiveKey={["0"]}>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <p className="accord-header">Complete your Profile</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>Add your profile pic and description</p>
                      <Link to={"/profile"}>
                        <button className="accord-btn">
                          Complete your profile
                        </button>
                      </Link>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <p className="accord-header">Create a service</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>Add a service so that your followers can book it</p>
                      <Link to={"/services"}>
                        <button className="accord-btn">Create a service</button>
                      </Link>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <p className="accord-header">Share a post</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>Share your topmate page with your followers</p>
                      <Link to={"/sharepost"}>
                        <button className="accord-btn">Share a post</button>
                      </Link>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <p className="accord-header">Connect payout</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Connect Bank, Stripe, or PayPal for seamless withdrawals
                      </p>
                      <Link to={"/payments"}>
                        <button className="accord-btn">Connect payout</button>
                      </Link>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
 
                {/* </div> */}
              </div>
            </div>
 
            <p className="KK" style={{ fontSize: "20px" }}>
              Have any queries contact us in chat box
            </p>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};