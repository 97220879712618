import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Landingpage.css";
import { IoSearch } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { FaSearch } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import people1 from "./assets/people1.avif";
import people2 from "./assets/people2.avif";
import people3 from "./assets/people3.avif";
import people4 from "./assets/people4.avif";
import people5 from "./assets/people5.avif";
import people6 from "./assets/people6.avif";
import { MdOutlineArrowOutward } from "react-icons/md";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import inf from "./assets/creatersVideo.mov";
import thoughts from "./assets/thoughts.png";
import landingpageResume from "./assets/landingpageResume.png";
import axios from "axios";
import IndianCurrencySymbol from "./assets/IndianCurrencySymbol.jpg";
import Footer from "./Footer";
import book from "./assets/callScr.png";
import web from "./assets/webnarSana.png";
import Carousel from "./Carousel";
import "./Carousel.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Pagination } from 'swiper/modules';

const Landingpage = () => {

  const imgsdata = [people1,people2,people3];

  const [image, setImage] = useState(book);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const videoRef = useRef(null);
  // const [cards, setCards] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("data"); // Default category

  useEffect(() => {
    // Fetch images from the server based on the selected category
    fetch(`https://api.booking.ashishphotography.co.in/api/getImagesByCategory/${selectedCategory}`)
      .then((response) => response.json())
      .then((data) => setImages(data))
      .catch((error) =>
        console.error(`Error fetching ${selectedCategory} images:`, error)
      );
  }, [selectedCategory]);

  const handleNavClick = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        // Autoplay was prevented
        // Handle the error or provide a user interaction to start the video
      });
    }
  }, []);

  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/");
      });
  }, [navigate]);

  const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;

  const [role, setRole] = useState("");
  // console.log(role);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.booking.ashishphotography.co.in/user/${userId}`
        ); // Assuming the endpoint for a specific creator
        const data = response.data;
        setRole(data.role);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);


  /// search results\
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const handleSearch = async () => {
    console.log("enter")
    if (query.trim() !== '') {
      try {
        const response = await axios.get(`https://api.booking.ashishphotography.co.in/getusers/search`, {
          params: { q: query }
        });
        setResults(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching data', error);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };



  return (
    <div className="landingpage">
      <div className="top-bar">
        <Navbar expand="lg" className="bg-dark text-white">
          <Container>
            <Navbar.Brand className="mr-5" href="#home">
              <img src={thoughts} className="CTlogo" alt="logo" />
            </Navbar.Brand>

            <Navbar.Toggle
              className="bg-white"
              aria-controls="responsive-navbar-nav"
            />

            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown
                  className="nav-heads"
                  style={{ color: "white" }}
                  title="For Creators"
                  id="collapsible-nav-dropdown"
                >
                  <NavDropdown.Item
                    href="/software"
                    className="navbar-dropdown-item"
                  >
                    Software Engineers
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="/creators"
                    className="navbar-dropdown-item"
                  >
                    Creators
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="/scientists"
                    className="navbar-dropdown-item"
                  >
                    Data Scientists
                  </NavDropdown.Item>
                </NavDropdown>

                {/* <Nav.Link href="#pricing" className="nav-heads">Pricings</Nav.Link>
   <Nav.Link href="#Calculator" className="nav-heads">Earning Calculator</Nav.Link> */}
              </Nav>

              <Nav>
                {isLoggedIn ? (
                  role === "admin" ? (
                    <Nav.Link href="/admin">
                      <button className="btn btn-primary p-2 border border-dark">
                        Dashboard
                      </button>
                    </Nav.Link>
                  ) : (
                    <Nav.Link href="/home">
                      <button className="btn btn-primary p-2 border border-dark">
                        Dashboard
                      </button>
                    </Nav.Link>
                  )
                ) : (
                  <>
                    <Nav.Link href="/adminlogin">
                      <button className="btn btn-primary p-2 border border-dark">
                        Login as admin
                      </button>
                    </Nav.Link>
                    <Nav.Link href="/login">
                      <button className="btn btn-primary p-2 border border-dark">
                        Login as Creator
                      </button>
                    </Nav.Link>

                    <Nav.Link eventKey={2} href="/signup">
                      <button className="btn btn-light text-dark p-2">
                        Sign Up
                      </button>
                    </Nav.Link>
                  </>
                )}

                {/* <Nav.Link href="/home"><button className="btn btn-primary p-2 border border-dark">Dashboard</button></Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="screen1 mt-5">
        <div className="screen1-left" data-aos="fade-up">
          <h1 className="hustle">
            Start Your side <b>hustle today</b>
          </h1>
          <p>
            Turn your passion and knowledge into a thriving business. Help your
            audience get ahead in life
          </p>
          <div className="screen1-buttons">
            <Link to={"/Home"}>
              <button className="btn btn-dark text-white p-3">
                Start My Page <FaArrowRightLong style={{ marginLeft: "4px" }} />
              </button>
            </Link>
          </div>
        </div>
        <div className="screen1-right">
          <video
            ref={videoRef}
            data-aos="zoom-in"
            className="inf-video"
            loop
            autoplay
            muted
          >
            <source src={inf} type="video/mp4" />
          </video>
        </div>
        <div className="screen1-mobile">
          <p>
            Turn your passion and knowledge into a thriving business. Help your
            audience get ahead in life
          </p>
          <div className="screen1-mobile-buttons">
            <Link to={"/Home"}>
              <button className="btn btn-dark text-white p-3">
                Start My Page <FaArrowRightLong style={{ marginLeft: "4px" }} />
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="screen-2">
        <h1 className="text-center">
          For people <b>making Impact</b>
        </h1>
        <Tab.Container id="left-tabs-example" defaultActiveKey="data">
          <Row className="mt-5 ">
            <Col
              sm={12}
              className="d-flex flex-column justify-content-center align-items-center tabs2"
            >
              <Nav variant="pills" className="flex-row">
                {/* ... (other nav items) */}
                {["data", "software", "creators"].map((category) => (
                  <Nav.Item key={category}>
                    <Nav.Link
                      eventKey={category}
                      onClick={() => handleNavClick(category)}
                    >
                      <button className="button">
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                        <div className="hoverEffect">
                          <div></div>
                        </div>
                      </button>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col>
              <Tab.Content className="oscroll mt-5">
                <Tab.Pane eventKey="data">
                  <div className="screen2-cards-container">
                    {images.map((image) => (
                      <div key={image.c_id} className="screen2-new-card">
                        <Link to={`/${image.username}/${image.c_id}`}>
                          <img
                            key={image.id}
                            src={`https://api.booking.ashishphotography.co.in/uploads/${image.image}`} // Update with the correct path
                            alt={image.first_name}
                          />
                          <div>
                            <h4>{image.first_name}</h4>
                            <p>{image.about}</p>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="software">
                  <div className="screen2-cards-container">
                    {images.map((image) => (
                      <div key={image.c_id} className="screen2-new-card">
                        <Link to={`/${image.username}/${image.c_id}`}>
                          <img
                            key={image.id}
                            src={`https://api.booking.ashishphotography.co.in/uploads/${image.image}`} // Update with the correct path
                            alt={image.first_name}
                          />
                          <div>
                            <h4>{image.first_name}</h4>
                            <p>{image.about}</p>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="creators">
                  <div className="screen2-cards-container">
                    {images.map((image) => (
                      <div key={image.c_id} className="screen2-new-card">
                        <Link to={`/${image.username}/${image.c_id}`}>
                          <img
                            key={image.c_id}
                            src={`https://api.booking.ashishphotography.co.in/uploads/${image.image}`} // Update with the correct path
                            alt={image.first_name}
                          />
                          <div>
                            <h4>{image.first_name}</h4>
                            <p>{image.about}</p>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      <div className="screen3" id="search">
        <h1 className="text">
          Find the <b>right expert</b>
        </h1>
        <p className="text">
          Get Personalized advice from top experts to your specific questions
        </p>
        <div className="d-flex flex-row align-items-center">
          <input
            placeholder="I'm a Software Engineer looking for a carrer advice"
            type="search"
            value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={handleKeyPress}
          />
          <div className="search-btn">
            <span>Search</span> <FaSearch />
          </div>
        </div>
        
        {/* <Carousel slides={imgsdata}/> */}
      </div>
      {/* <Swiper
        slidesPerView={3}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        className="swui"
      >
      {results.map((card,index)=>{
        return(
          <SwiperSlide className="searchcard" key={card.c_id}>
            <img alt="imasge" src={`https://api.booking.ashishphotography.co.in/uploads/${card.image}`} />
          </SwiperSlide>
        )
      })}
       
      </Swiper> */}
      <div className="search-cards">
      {results.map((card,index)=>{
        return(
          
          <div className="search-card" key={card.c_id}>
          <Link to={`/${card.username}/${card.c_id}`}>
            <img alt="imasge" src={`https://api.booking.ashishphotography.co.in/uploads/${card.image}`} />
            <p>{card.first_name}</p>
            </Link>
          </div>
          
        )
      })}
      </div>
    

      {/* <div className="screen3" id="search">
        <h1 className="text">Find the <b>right expert</b></h1>
        <p className="text">Get Personalized advice from top experts to your specific questions</p>
        <div className="d-flex flex-row align-items-center">
        <input placeholder="I'm a Software Engineer looking for a carrer advice" type="search"/>
        <div className="search-btn">
          <span>Search</span> <FaSearch/>
        </div>
        </div>
        <div className="screen3-boxes">
            <div className="screen3-box">
                <h6>Find people from a Company</h6>
                <p>I'm looking for Software Engineers working in Google</p>
            </div>
            <div className="screen3-box">
                <h6>Find a Job</h6>
                <p>I'm looking for a Job in product management</p>
            </div>
        </div>
        <div className="screen3-boxes">
            <div className="screen3-box">
                <h6>Find people from a Company</h6>
                <p>I'm looking for Software Engineers working in Google</p>
            </div>
            <div className="screen3-box">
                <h6>Find people from a Company</h6>
                <p>I'm looking for Software Engineers working in Google</p>
            </div>
        </div>
    </div> */}
      <div className="screen4">
        <div className="screen4top">
          <div className="screen4-left">
            <h1>
              Create your Creative Thoughts page in a <span>flash</span>
            </h1>

            <p>Start earning ₹₹ by the time you finish reading our website</p>

            {/* <a href="#launch">
              Launch your page <MdOutlineArrowOutward />
            </a> */}
          </div>

          <div className="screen4-right">
            <img src={landingpageResume} id="resumePic" alt="flash" />

            {/* <img src={resumeFooter} id="resumeFooter" alt="flash" /> */}
          </div>
        </div>

        <div className="screen4bottom">
          <div className="bottom4-left">
            <img src={image} alt="drop" />
          </div>

          <div className="bottom4-right">
            <Accordion className="accord" flush defaultActiveKey="1:1call">
              <Accordion.Item
                onClick={() => setImage(book)}
                eventKey="1:1call"
                active
              >
                <Accordion.Header>
                  <h4>01 Offer 1:1 sessions</h4>
                </Accordion.Header>

                <Accordion.Body>
                  <p className="accord-content">
                    Mentorship sessions, consultations, discovery calls - do
                    what you do best. We take care of everything else
                  </p>

                  <div className="d-flex justify-content-center">
                    <img className="accord-img" src={image} alt="drop" />
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item onClick={() => setImage(web)} eventKey="Webinar">
                <Accordion.Header>
                  <h4>02 Host a Webinar</h4>
                </Accordion.Header>

                <Accordion.Body>
                  <p className="accord-content">
                    Connect with 100s of followers at once. Classes, group
                    calls, workshops - do them all
                  </p>

                  <div className="d-flex justify-content-center">
                    <img className="accord-img" src={image} alt="drop" />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="screen6">
        <h1 className="text-center">
          People love using <b>Creative Thoughts</b>
        </h1>

        <div className="screen6-boxes mt-4">
          <div className="screen6-box">
            <p>
              Love the integrations with Calendar, Zoom and WhatsApp. Makes my
              life easier!
            </p>

            <div className="d-flex flex-row  align-items-center">
              <img className="screen6-img" src={people1} alt="6" />

              <div style={{ marginLeft: "10px" }}>
                <h6 style={{ marginBottom: "0px" }}>Aishwarya Srinivasan</h6>

                <span style={{ paddingTop: "0px" }}>LinkedIn Top Voice</span>
              </div>
            </div>
          </div>

          <div className="screen6-box">
            <p>
              The entire experience is just so seamless. My followers love it
            </p>

            <div className="d-flex flex-row  align-items-center">
              <img className="screen6-img" src={people2} alt="6" />

              <div style={{ marginLeft: "10px" }}>
                <h6 style={{ marginBottom: "0px" }}>Joerg Storm</h6>

                <span style={{ paddingTop: "0px" }}>300K on LinkedIn</span>
              </div>
            </div>
          </div>

          <div className="screen6-box">
            <p>
              Creative Thoughts is my go-to platform for scheduling 1:1 sessions
              and hosting webinars!
            </p>

            <div className="d-flex flex-row  align-items-center">
              <img className="screen6-img" src={people3} alt="6" />

              <div style={{ marginLeft: "10px" }}>
                <h6 style={{ marginBottom: "0px" }}>Xinran Waibel</h6>

                <span style={{ paddingTop: "0px" }}>
                  Founder of Data Engineer Things
                </span>
              </div>
            </div>
          </div>

          <div className="screen6-box">
            <p>All my monetisation now happens in one place</p>

            <div className="d-flex flex-row  align-items-center">
              <img className="screen6-img" src={people4} alt="6" />

              <div style={{ marginLeft: "10px" }}>
                <h6 style={{ marginBottom: "0px" }}>Payal & Gaurav</h6>

                <span style={{ paddingTop: "0px" }}>110K+ on Instagram</span>
              </div>
            </div>
          </div>

          <div className="screen6-box">
            <p>
              The team is extremely helpful and cares a lot about feedback. They
              keep rolling out new features too!
            </p>

            <div className="d-flex flex-row  align-items-center">
              <img className="screen6-img" src={people5} alt="6" />

              <div style={{ marginLeft: "10px" }}>
                <h6 style={{ marginBottom: "0px" }}>Lorraine Lee</h6>

                <span style={{ paddingTop: "0px" }}>
                  Speaker, 320K on LinkedIn
                </span>
              </div>
            </div>
          </div>

          <div className="screen6-box">
            <p>
              I love Creative Thoughts! It has made it seamless to schedule
              mentoring sessions! Big fan of Creative Thoughts WhatsApp
              integration.
            </p>

            <div className="d-flex flex-row  align-items-center">
              <img className="screen6-img" src={people6} alt="6" />

              <div style={{ marginLeft: "10px" }}>
                <h6 style={{ marginBottom: "0px" }}>Jessica</h6>

                <span style={{ paddingTop: "0px" }}>
                  Global Data Lead in Energy Industry
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="screen7">
        <div className="ravi">
          <div className="notification-1">
            {/* <h1> Welcome To Creators world</h1> */}
            <h1>Explore the World of Creators</h1>
            <p>
              "Welcome to the world of freelance earnings! At our platform, you
              can contribute to open-source projects and showcase your skills.
              With our easy-to-use interface, you can explore various UI
              elements and contribute to their development. Join us today and
              start earning from your contributions to the open-source
              community!"
            </p>
          </div>

          <div className="coin-profit">
            <div className="coin">
              <div className="side heads">
                <img
                  src={IndianCurrencySymbol}
                  id="dollar-1"
                  alt="dollarSymbol"
                />

                <g id="Layer_x0020_1">
                  <metadata id="CorelCorpID_0Corel-Layer"></metadata>

                  <g id="_1421344023328">
                    <path
                      fill="#F7931A"
                      fill-rule="nonzero"
                      d="M4030.06 2540.77c-273.24,1096.01 -1383.32,1763.02 -2479.46,1489.71 -1095.68,-273.24 -1762.69,-1383.39 -1489.33,-2479.31 273.12,-1096.13 1383.2,-1763.19 2479,-1489.95 1096.06,273.24 1763.03,1383.51 1489.76,2479.57l0.02 -0.02z"
                    ></path>

                    <path
                      fill="white"
                      fill-rule="nonzero"
                      d="M2947.77 1754.38c40.72,-272.26 -166.56,-418.61 -450,-516.24l91.95 -368.8 -224.5 -55.94 -89.51 359.09c-59.02,-14.72 -119.63,-28.59 -179.87,-42.34l90.16 -361.46 -224.36 -55.94 -92 368.68c-48.84,-11.12 -96.81,-22.11 -143.35,-33.69l0.26 -1.16 -309.59 -77.31 -59.72 239.78c0,0 166.56,38.18 163.05,40.53 90.91,22.69 107.35,82.87 104.62,130.57l-104.74 420.15c6.26,1.59 14.38,3.89 23.34,7.49 -7.49,-1.86 -15.46,-3.89 -23.73,-5.87l-146.81 588.57c-11.11,27.62 -39.31,69.07 -102.87,53.33 2.25,3.26 -163.17,-40.72 -163.17,-40.72l-111.46 256.98 292.15 72.83c54.35,13.63 107.61,27.89 160.06,41.3l-92.9 373.03 224.24 55.94 92 -369.07c61.26,16.63 120.71,31.97 178.91,46.43l-91.69 367.33 224.51 55.94 92.89 -372.33c382.82,72.45 670.67,43.24 791.83,-303.02 97.63,-278.78 -4.86,-439.58 -206.26,-544.44 146.69,-33.83 257.18,-130.31 286.64,-329.61l-0.07 -0.05zm-512.93 719.26c-69.38,278.78 -538.76,128.08 -690.94,90.29l123.28 -494.2c152.17,37.99 640.17,113.17 567.67,403.91zm69.43 -723.3c-63.29,253.58 -453.96,124.75 -580.69,93.16l111.77 -448.21c126.73,31.59 534.85,90.55 468.94,355.05l-0.02 0z"
                    ></path>
                  </g>
                </g>
              </div>

              <div className="side tails">
                <img
                  src={IndianCurrencySymbol}
                  id="dollar-1"
                  alt="dollarSymbol"
                />
                <b className="text-white">₹</b>
                <g id="Layer_x0020_1">
                  <metadata id="CorelCorpID_0Corel-Layer"></metadata>

                  <g id="_1421344023328">
                    <path
                      fill="#F7931A"
                      fill-rule="nonzero"
                      d="M4030.06 2540.77c-273.24,1096.01 -1383.32,1763.02 -2479.46,1489.71 -1095.68,-273.24 -1762.69,-1383.39 -1489.33,-2479.31 273.12,-1096.13 1383.2,-1763.19 2479,-1489.95 1096.06,273.24 1763.03,1383.51 1489.76,2479.57l0.02 -0.02z"
                    ></path>

                    <path
                      fill="white"
                      fill-rule="nonzero"
                      d="M2947.77 1754.38c40.72,-272.26 -166.56,-418.61 -450,-516.24l91.95 -368.8 -224.5 -55.94 -89.51 359.09c-59.02,-14.72 -119.63,-28.59 -179.87,-42.34l90.16 -361.46 -224.36 -55.94 -92 368.68c-48.84,-11.12 -96.81,-22.11 -143.35,-33.69l0.26 -1.16 -309.59 -77.31 -59.72 239.78c0,0 166.56,38.18 163.05,40.53 90.91,22.69 107.35,82.87 104.62,130.57l-104.74 420.15c6.26,1.59 14.38,3.89 23.34,7.49 -7.49,-1.86 -15.46,-3.89 -23.73,-5.87l-146.81 588.57c-11.11,27.62 -39.31,69.07 -102.87,53.33 2.25,3.26 -163.17,-40.72 -163.17,-40.72l-111.46 256.98 292.15 72.83c54.35,13.63 107.61,27.89 160.06,41.3l-92.9 373.03 224.24 55.94 92 -369.07c61.26,16.63 120.71,31.97 178.91,46.43l-91.69 367.33 224.51 55.94 92.89 -372.33c382.82,72.45 670.67,43.24 791.83,-303.02 97.63,-278.78 -4.86,-439.58 -206.26,-544.44 146.69,-33.83 257.18,-130.31 286.64,-329.61l-0.07 -0.05zm-512.93 719.26c-69.38,278.78 -538.76,128.08 -690.94,90.29l123.28 -494.2c152.17,37.99 640.17,113.17 567.67,403.91zm69.43 -723.3c-63.29,253.58 -453.96,124.75 -580.69,93.16l111.77 -448.21c126.73,31.59 534.85,90.55 468.94,355.05l-0.02 0z"
                    ></path>
                  </g>
                </g>
              </div>
            </div>

            <p> Creators earnings on Creative Thoughts (2023)</p>

            <h1>
              <b>₹ 2,104,239</b>
            </h1>
          </div>
        </div>
      </div>
      <div className="screen9">
        <div className="screen9-left">
          <h1>Frequently asked questions</h1>

          <p>Can't find the answer you are looking for?</p>

          <span>Reach out us</span>
        </div>

        <div className="screen9-left">
          <Accordion className="accord2" defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h5>What is Creative Thoughts?</h5>
              </Accordion.Header>

              <Accordion.Body>
                Creative Thoughts is a tool that enables you to connect with
                your audience through 1:1 sessions - to share your knowledge and
                monetise your time better. Just create your personal link, add
                your services, availability and charges; and share your link
                with your followers. Your followers can now book a call to
                connect with you
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5>Can I conduct paid sessions?</h5>
              </Accordion.Header>

              <Accordion.Body>
                Absolutely, we enable you to conduct both free and paid sessions
                with your followers
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <h5>How much can I charge for my sessions?</h5>
              </Accordion.Header>

              <Accordion.Body>
                It really depends on how much value you think you're providing
                and how much are your followers willing to pay. For idea, our
                creators charge anywhere from $50 to $500 for a session
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <h5>What are the transaction charges?</h5>
              </Accordion.Header>

              <Accordion.Body>
                It really depends on how much value you think you're providing
                and how much are your followers willing to pay. For idea, our
                creators charge anywhere from $50 to $500 for a session
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <h5>How do the payments work?</h5>
              </Accordion.Header>

              <Accordion.Body>
                You can collect payments from anywhere in the world in your
                preferred currency. Payouts are Instant with the help of our
                integration with Stripe and Paypal
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <h5>Wait, is Creative Thoughts really free?</h5>
              </Accordion.Header>

              <Accordion.Body>
                Yes! We're free to use and charge a very tiny commission on your
                earnings (7%). So that we grow only when you grow
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Landingpage;
