import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SideBar from "./Sidebar";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import "./payment.css";
import { Button, Modal, Form, Toast, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import service1 from "../images/img-empty-state-video.png";
import service5 from "../images/img-empty-state-webinar.png";
import { FaPhoneAlt, FaVideo } from "react-icons/fa";
import "./Service.css";
import { toast } from 'react-toastify';
import Aos from "aos";


const Services = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  const [alertMessage, setAlertMessage] = useState({ text: "", variant: "" });
  const [servicesData, setServicesData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editServiceId, setEditServiceId] = useState(null);
  const [description, setDescription] = useState("");
  const [selectedSchedule, setSelectedSchedule] = useState("");
  const [schedules, setSchedules] = useState([]);

  const storedUserId = localStorage.getItem("userId");
  const [userId, setUserId] = useState(
    storedUserId ? parseInt(storedUserId) : null
  );

  const navigate = useNavigate();

  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          // setIsLoggedIn(true);
        } else {
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);

  useEffect(() => {
    fetchServices();
  });

  useEffect(() => {
    fetchSchedules();
  }, []);

  const fetchServices = () => {
    if(userId){
    Axios.get(`https://api.booking.ashishphotography.co.in/api/services/${userId}`)
      .then((response) => {
        setServicesData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
        setAlertMessage({ text: "Error fetching services", variant: "danger" });
      });
    }
  };

  const fetchSchedules = () => {
    if(userId){
    Axios.get(`https://api.booking.ashishphotography.co.in/api/schedule/${userId}`)
      .then((response) => {
        setSchedules(response.data);
      })
      .catch((error) => {
        console.error("Error fetching schedules:", error);
      });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAddNew = () => {
    setEditMode(false);
    setShowModal(true);
    resetForm();
  };

  const handleEdit = (service_id) => {
    const serviceToEdit = servicesData.find(
      (service) => service.service_id === service_id
    );
    if (serviceToEdit) {
      setEditMode(true);
      setEditServiceId(service_id);
      setShowModal(true);
      setServiceType(serviceToEdit.serviceType);
      setServiceName(serviceToEdit.serviceName);
      setDuration(serviceToEdit.duration);
      setPrice(serviceToEdit.price);
      setDescription(serviceToEdit.description);
      setSelectedSchedule(serviceToEdit.selectedSchedule);
    }
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const handleSave = () => {
    if (
      !serviceType ||
      !serviceName ||
      !description ||
      !duration ||
      !price ||
      !selectedSchedule
    ) {
      setAlertMessage({
        text: "Please fill in all fields",
        variant: "danger",
      });
    
      return;
    }

    if (editMode) {
      Axios.put(`https://api.booking.ashishphotography.co.in/services/${editServiceId}`, {
        serviceType,
        serviceName,
        description,
        duration,
        price,
        selectedSchedule,
      })
        .then((response) => {
          if (response.status === 200) {
            fetchServices();
            setShowModal(false);
            setAlertMessage({
              text: "Service updated successfully",
              variant: "success",
            });
          } else {
            setAlertMessage({
              text: "Error updating service",
              variant: "danger",
            });
          }
        })
        .catch((error) => {
          console.error("Error updating service:", error);
          setAlertMessage({
            text: "Error updating service",
            variant: "danger",
          });
        });
    } else {
      Axios.post("https://api.booking.ashishphotography.co.in/services", {
        serviceType,
        serviceName,
        description,
        duration,
        price,
        selectedSchedule,
        userId,
      })
        .then((response) => {
          if (response.status === 201) {
            fetchServices();
            setShowModal(false);
            setAlertMessage({
              text: "Service added successfully",
              variant: "success",
            });
          } else {
            setAlertMessage({
              text: "Error adding service",
              variant: "danger",
            });
          }
        })
        .catch((error) => {
          console.error("Error saving service:", error);
          setAlertMessage({ text: "Error saving service", variant: "danger" });
        });
    }
  };

  const handleDelete = (service_id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this service?");
  
    if (confirmDelete) {
      Axios.delete(`https://api.booking.ashishphotography.co.in/services/${service_id}`)
        .then((response) => {
          if (response.status === 200) {
            const updatedServices = servicesData.filter(
              (service) => service.service_id !== service_id
            );
            setServicesData(updatedServices);
            toast.success("Service deleted successfully"); // Show success toast
          } else {
            toast.error("Error deleting service");
          }
        })
        .catch((error) => {
          console.error("Error deleting service:", error);
          toast.error("Error deleting service");
        });
    }
  };

  const resetForm = () => {
    setServiceType("");
    setServiceName("");
    setDuration("");
    setPrice("");
    setDescription("");
    setSelectedSchedule("");
  };

  return (
    <div className="d-flex">
      <SideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
      <div className="pageContainer">
        <div className="p-30">
          <h1 className="page-title">Services</h1>
          <div className="tabs-container">
            <Tab.Container id="left-tabs-example" defaultActiveKey="call">
              <div className="flexJustify">
                <Nav className="flex-row nav-tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="call">1:1 Call</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Webinar">Webinar</Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className="btnDiv">
                  <Link>
                    <Button variant="dark" className="" onClick={handleAddNew}>
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="iconGap iconSmall"
                      />
                      Add
                    </Button>
                  </Link>
                </div>
              </div>
              <Tab.Content className="serviceDivContainer">
                <Tab.Pane eventKey="call">
                  <div>
                    {servicesData.length === 0 ? (
                      <div className="flexCenter bg-white">
                        <img src={service1} alt="service" />
                        <h5 className="sec-title">Create a 1:1 service</h5>
                        <p>
                          Users on topmate sell over 10k services each month.
                        </p>
                        <div className="btnDiv mt-4">
                          <Link>
                            <Button
                              variant="dark"
                              className="btnRight"
                              onClick={handleAddNew}
                            >
                              <FontAwesomeIcon
                                icon={faPlus}
                                className="iconGap iconSmall"
                              />
                              Add New
                            </Button>
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div className="service-container">
                        {servicesData
                          .filter((service) => service.serviceType === "1:1")
                          .map((service) => (
                            <div
                              key={service.service_id}
                              className="service-card"  data-aos="fade-up"
                            >
                              <div className="card-header">
                                <FaPhoneAlt className="icon" />
                                <span>
                                  {" "}
                                  <strong>call 1:1</strong>
                                </span>
                              </div>
                              <div className="card-body9">
                                <h4>{service.serviceName}</h4>
                                <div className="card-mater">
                                  <span className="price-label">
                                    <p>Description</p>
                                  </span>{": "}
                                  {service.description}
                                </div>
                                <div className="card-mater">
                                  {" "}
                                  <span className="price-label">
                                    <p>Price</p>
                                  </span>{": "}
                                  {service.price}
                                </div>
 
                                <div className="card-mater">
                                  <span className="price-label">
                                    <p>Duration</p>
                                  </span>{": "}
                                  {service.duration}
                                </div>
                                <div className="card-mater">
                                  <span className="price-label">
                                    <p>Selected Schedule</p>
                                  </span>{": "}
                                  {service.selectedSchedule}
                                </div>
                              </div>
                              <div className="card-footer">
                                <button
                                  onClick={() => handleEdit(service.service_id)}
                                  className="edit-btn"
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() =>
                                    handleDelete(service.service_id)
                                  }
                                  className="delete-btn"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="Webinar">
                  <div>
                    {servicesData.length === 0 ? (
                      <div className="flexCenter bg-white">
                        <img src={service5} alt="service" />
                        <h5 className="sec-title">Create a webinar</h5>
                        <p>
                          Users on topmate sell over 10k services each month.
                        </p>
                        <div className="btnDiv mt-4">
                          <Link>
                            <Button
                              variant="dark"
                              className="btnRight"
                              onClick={handleAddNew}
                            >
                              <FontAwesomeIcon
                                icon={faPlus}
                                className="iconGap iconSmall"
                              />
                              Add New
                            </Button>
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div className="service-container">
                        {/* Filter the servicesData array to only include services with type "Webinar" */}
                        {servicesData
                          .filter(
                            (service) => service.serviceType === "Webinar"
                          )
                          .map((service) => (
                            <div
                              key={service.service_id}
                              className="service-card"  data-aos="fade-up"
                            >
                              <div className="card-header">
                                <FaVideo className="icon" />
                                <span>
                                  <strong>Webinar</strong>
                                </span>
                              </div>
                              <div className="card-body9">
                                <h4>{service.serviceName}</h4>
                                <div className="card-mater">
                                  <span className="price-label">
                                    <p className="desc">Description</p>
                                  </span>{": "}
                                  {service.description}
                                </div>
                                <div className="card-mater">
                                  {" "}
                                  <span className="price-label">
                                    <p>Price</p>
                                  </span>{": "}
                                  {service.price}
                                </div>
 
                                <div className="card-mater">
                                  <span className="price-label">
                                    <p>Duration</p>
                                  </span>{": "}
                                  {service.duration}
                                </div>
                                <div className="card-mater">
                                  <span className="price-label">
                                    <p>Selected Schedule</p>
                                  </span>{": "}
                                  {service.selectedSchedule}
                                </div>
                              </div>
                              <div className="card-footer">
                                <button
                                  onClick={() => handleEdit(service.service_id)}
                                  className="edit-btn"
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() =>
                                    handleDelete(service.service_id)
                                  }
                                  className="delete-btn"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {editMode ? "Edit Service" : "Add New Service"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {alertMessage.text && (
              <Toast
                onClose={() => setAlertMessage({ text: "", variant: "" })}
                show={true}
                delay={1000}
                autohide
              >
                <Toast.Body className={`bg-${alertMessage.variant}`}>
                  {alertMessage.text}
                </Toast.Body>
              </Toast>
            )}
            <Form>
              <Form.Group>
                <Form.Label>Service Type</Form.Label>
                <Form.Control
                  as="select"
                  value={serviceType}
                  onChange={(e) => setServiceType(e.target.value)}
                >
                  <option value="">Select service type</option>
                  <option value="1:1">1:1</option>
                  <option value="Webinar">Webinar</option>
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Label>Service Name</Form.Label>
                <Form.Control
                  type="text"
                  value={serviceName}
                  onChange={(e) => setServiceName(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Duration (minutes)</Form.Label>
                <Form.Control
                  type="number"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  value={price} min='0'
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Schedule</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedSchedule}
                  onChange={(e) => setSelectedSchedule(e.target.value)}
                >
                  <option value="">Select schedule</option>
                  {schedules.map((schedule, index) => (
                    <option key={index} value={schedule}>
                      {schedule}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSave}>
              {editMode ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Services;
