import React, { useState, useEffect } from "react";
import Axios from 'axios';
import '../style.css';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
export const Login = () => {
    const [email, setemail] = useState('');
    const [phonenumber, setphonenumber] = useState('');
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [conformpassword, setconformpassword] = useState('');
 
    const [loginusername, setloginusername] = useState('');
    const [loginpassword, setloginpassword] = useState('');
    const navigate = useNavigate();
    const [loginstatus, setloginstatus] = useState('');
    const [token, setToken] = useState(null);
 
    Axios.defaults.withCredentials = true;
 
    function isEmptyOrSpaces(str) {
        return str === null || str.match(/^ *$/) !== null;
    }
 
    function Validation() {
        let emailregex = /^[a-zA-Z0-9]+@(gmail|yahoo|outlook)\.com$/;
        let userregex = /^[a-zA-Z0-9]{5,}$/;
        let phoneRegex = /^\d{10}$/;

 
        if (isEmptyOrSpaces(username) || isEmptyOrSpaces(email) || isEmptyOrSpaces(phonenumber) || isEmptyOrSpaces(password)) {
            alert("you cannot leave any field empty");
            return false;
        }
 
        if (!emailregex.test(email)) {
            alert("Enter a valid email");
            return false;
        }
        if (!userregex.test(username)) {
            alert("- Username can only be alphanumeric\n- Username must be at least 5 characters\n- Username cannot contain spaces");
            return false;
        }
        if (!phoneRegex.test(phonenumber)) {
            alert("Enter a valid phone number");
            return false;
        }
 
        return true;
    }
 
    const handleregistration = (event) => {
        event.preventDefault();
        if (!Validation()) {
            return;
        }
 
        if (password === conformpassword) {
            Axios.post('https://api.booking.ashishphotography.co.in/registration', {
                username: username,
                password: password,
                email: email,
                phonenumber: phonenumber
            }).then((response) => {
                console.log(response);
                toast.success('Registration successful');
                 // Reset form values to empty
                 setusername('');
                 setemail('');
                 setphonenumber('');
                 setpassword('');
                 setconformpassword('');
            }).catch((error) => {
                console.error("Error registering:", error);
                if (error.response && error.response.status === 400) {
                    if (error.response.data.includes("Username")) {
                        toast.error("Username already exists");
                    } else if (error.response.data.includes("Email")) {
                        toast.error("Email already exists");
                    } else if (error.response.data.includes("Phone number")) {
                        toast.error("Phone number already exists");
                    } else {
                        toast.error("Error during registration");
                    }
                } else {
                    toast.error("An error occurred during registration");
                }
            });
        } else {
            toast.error("Passwords do not match");
        }
    };
 
    const handlelogin = (event) => {
        event.preventDefault();
        const trimmedLoginPassword = loginpassword.trim();
        Axios.post('https://api.booking.ashishphotography.co.in/login', {
            username: loginusername,
            password: trimmedLoginPassword
        }, { withCredentials: true }).then((response) => {
            if (response.data.token) {
                setToken(response.data.token);
                const user = response.data.user;
                console.log(user.role);
                localStorage.setItem('userId', user.id);
                localStorage.setItem('role', user.role);
                if (user) {
                    setloginstatus(`Welcome ${user.username}`);
                    if (user.role === "admin") {
                        navigate(`/admin`);
                    } else {
                        navigate(`/home`);
                    }
                } else {
                    setloginstatus("User data not available in the token.");
                }
            } else {
                setloginstatus(response.data.message);
            }
        }).catch((error) => {
            console.error("Error logging in:", error);
            toast.error("Incorrect username or password.");
        });
    };
 
    useEffect(() => {
        if (token) {
            Axios.get('https://api.booking.ashishphotography.co.in/login', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((response) => {
                if (response.data.loggedIn === true) {
                    setloginstatus(`Welcome, ${response.data.user.name}`);
                }
            });
        }
    }, [token]);
 
    return (
        <div className="login-cont">
            <div>
                <div className="main-login">
                    <input type="checkbox" id="chk" aria-hidden="true" />
                    <div className="signup">
                        <form autoComplete="off">
                            <label htmlFor="chk" aria-hidden="true">Sign up</label>
                            <input placeholder="username" type="text" required onChange={(e) => setusername(e.target.value)} />
                            <input placeholder="Email" type="text" onChange={(e) => setemail(e.target.value)} required />
                            <input placeholder="Phone Number" type="tel" onChange={(e) => setphonenumber(e.target.value)} required />
                            <input placeholder="password" type="password" onChange={(e) => setpassword(e.target.value)} required />
                            <input placeholder="Confirm Password" type="password" onChange={(e) => setconformpassword(e.target.value)} required />
                            <button onClick={handleregistration}>submit</button>
                        </form> 
                    </div>
                    <div className="login">
                        <form onSubmit={handlelogin}>
                            <label htmlFor="chk" aria-hidden="true">Login</label>
                            <input placeholder="username" type="text" onChange={(e) => setloginusername(e.target.value)} required />
                            <input placeholder="password" type="password" onChange={(e) => setloginpassword(e.target.value)} required />
                            <button>submit</button>
                            <Link to='/forgetpassword' className="ml-5"><b>Forget Password</b></Link>
                            <h1 className="loginstatus">{loginstatus}</h1>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
 