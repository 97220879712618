import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

 
import { IoSearch } from "react-icons/io5";
import "./Creators.css";
import { FaArrowRight } from "react-icons/fa";

import { MdOutlineArrowOutward } from "react-icons/md";

import { HiOutlineArrowSmallRight } from "react-icons/hi2";
import thoughts from './assets/thoughts.png';
import callSupport from "./assets/callSupport.jpg";
import Monetise from "./assets/Monetise.jpg";
import queries from "./assets/queries.jpg";
import { SlNote } from "react-icons/sl";
import { IoIosGlobe } from "react-icons/io";
import { FaChildren } from "react-icons/fa6";
import createrMen from "./assets/createrMen.png";
import creativeLaptopGIrl from "./assets/creativeLaptopGIrl.png";
import Footer from "./Footer";
 
 
 

  function Creators() {
    const [logoutStatus, setLogoutStatus] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          // navigate("/login"); 
          // localStorage.clear();
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        // navigate("/login");
      });
  }, [navigate]);
  return (
    <div className="Creators-page">
      <div className="top-bar" >

      <Navbar  expand="lg" className="bg-dark text-white">

<Container >

<Navbar.Brand className="mr-5" href="#home"><img src={thoughts} className="CTlogo"  alt="logo" /></Navbar.Brand>



<Navbar.Toggle className="bg-white" aria-controls="responsive-navbar-nav" />

<Navbar.Collapse id="responsive-navbar-nav">

 <Nav className="me-auto" >

 <NavDropdown className="nav-heads" style={{color:"white"}} title="For Creators" id="collapsible-nav-dropdown">

 <NavDropdown.Item href="/software" className="navbar-dropdown-item">Software Engineers</NavDropdown.Item>
 <NavDropdown.Item href="/creators" className="navbar-dropdown-item">Creators</NavDropdown.Item>
 <NavDropdown.Item href="/scientists" className="navbar-dropdown-item">Data Scientists</NavDropdown.Item>
   </NavDropdown>


   {/* <Nav.Link href="#pricing" className="nav-heads">Pricings</Nav.Link>
   <Nav.Link href="#Calculator" className="nav-heads">Earning Calculator</Nav.Link> */}
 </Nav>

 <Nav>



   {isLoggedIn?(

     <Nav.Link href="/home"><button className="btn btn-primary p-2 border border-dark">Dashboard</button></Nav.Link>

   ):(

     <>

     <Nav.Link href="/login"><button className="btn btn-primary p-2 border border-dark">Login</button></Nav.Link>

   <Nav.Link eventKey={2} href="/login">

     <button className="btn btn-light text-dark p-2">Sign Up</button>

   </Nav.Link></>

   )}

  

   {/* <Nav.Link href="/home"><button className="btn btn-primary p-2 border border-dark">Dashboard</button></Nav.Link> */}



 </Nav>

</Navbar.Collapse>

</Container>

</Navbar>

</div>
 
   

  <div className="Creators-Screen-1">
    <div className="Creators-1-block">
        <div className='Creators-1-textbox'>
            <div className="you-1">
            The ultimate monestisation tool for creators
                  <p>Connect 1:1 with your followers. Host group sessions. Sell your digital products. It's all possible with Creative Thoughts!</p>
            
            </div> 
            {/* <button id="Sign">Sign Up Free <FaArrowRight id="ArrowRight" /></button> */}
        </div>
        <div className='createrMen'>
        <img src={createrMen} alt="createrMen"/>
  
  <div className='name-plate'>
    prajuval
  </div>
  
</div>

    </div>
</div>


<div className="Creators-Screen-2">
<h1>Why should Creators use Creative Thoughts?</h1>
    <div className="should">

        <div className="notePad">
      
        <div className='begin'>
        
        <h2>
            Begin your side hustle
        </h2>
       
        <SlNote id="notepad" />
        </div>
        
        <p>Always had the kick to start something of your own? Start with manetising your time with Creative Thoughts!</p>
        </div>
        <div className="connect">
      
        <div className='begin'>
        <h2>
            Connect with your followers
        </h2>
        <IoIosGlobe  id="world" />
        </div>
        <p>1:1 |Group calls | webinar | Queries | Seamless Packages. Get your hands o everything at one palce  </p>
        </div>
        <div className="up-skill">
        <div className='begin'> 
        <h2>
            Up-skill your community
        </h2>
       
        <FaChildren id="dimand" />
        </div>
        <p> Build and connect with folks from your ecosystem,exchange, share and monetise your community</p>
        </div>
       
    </div>

   
     </div>
   

<div className="Creators-Screen-3">

      <div className="carly">
      <div className="Love">
      <h2>
        “I am on track to earn 6 figures on Creative Thoughts. I never imagined I could do this”
        </h2>
        <h3>
        Carly 🎰 Taylor
        </h3>
        <p>
        145k+ Instagram | Study & Work in Germany Expert
          </p>
          <h5>
          103+ calls conducted
          </h5>
        {/* <a href="#view">View Profile <MdOutlineArrowOutward /> </a> */}
      </div>
    
      <div className="employ-girl">
      
      <img src={creativeLaptopGIrl} id="girl" alt="girl" />
     
      </div>
     </div>
     </div>



<div className="Software-Screen-4">
    <h1> What all you can do?</h1>
    
    <div class="container-3">
           <div class="mycard">
               <div class="card-img">
               <img src={callSupport} id="callSupport" alt="WEB DEVELOPMENT" />  
               </div>
               <div class="card-detail">
               <div className="content">
              <h3>
                   1:1 sessions with Software Engineering... aspirants
              </h3>
                 <p>Take 1:1 mock interviews, guide and help your followers get a kick-start in their career</p>
                 <a href="#conduct">Conduct 1:1s <HiOutlineArrowSmallRight /> </a>
               
              </div>
               </div>
           </div>
           <div class="mycard">
            <div class="card-img">
            <img src={Monetise} id="callSupport" alt="WEB DEVELOPMENT" />   
            </div>
            <div class="card-detail">
            <div className="content">
              <h3>
                Conduct a coding workshop
              </h3>
                <p>Connect personally with your followers, help them master the skill</p>
                <a href="#web">Host a webinar <HiOutlineArrowSmallRight /> </a>
             </div>
            </div>
        </div>
        <div class="mycard">
            <div class="card-img">
            <img src={queries} id="callSupport" alt="WEB DEVELOPMENT" />   
            </div>
            <div class="card-detail">
            <div className="content">
              <h3>
                Answer tech related queries
              </h3>
              <p> Monetise your incoming queries and provide priority help to your followers</p>
              <a href="#getstarted">Get Started <HiOutlineArrowSmallRight /> </a>
            
              </div>
        </div>
       </div>
     </div>
     {/* <div>
      <button id="join">Join the club</button>
      </div> */}
     </div>
     
   
       <div className="Creators-Screen-5">
     
     <div className="spreading">
     <h2> Creators on Creative Thoughts spreading awesomeness with </h2>
     </div>
     <div className="numbers">
       <div className="block-1">
         <h1>6k</h1>
         <p>Creators</p>
       </div>
       <div className="block-2">
      
       <h1>65k</h1>
        
         <p>sessions</p>
       </div>
       <div className="block-3">
         <h1>56k</h1>
         <p>Followers connected</p>
       </div>
     </div>
     
    </div>  
    <Footer/>
    </div>
   
  );
};
 
export default Creators;