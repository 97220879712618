import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { Button, TextField } from '@mui/material';
import { AiOutlineLike, AiOutlineDislike } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
// import Cookies from 'js-cookie';
import img from './assets/yatayati.jpg';
import './Comment.css';


import { AiFillLike } from "react-icons/ai";
import { AiFillDislike } from "react-icons/ai";
import SideBar from "./Sidebar";
 
const Admin = () => {
    const [logoutStatus, setLogoutStatus] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
 
    useEffect(() => {
      axios.get('https://api.booking.ashishphotography.co.in/home', { withCredentials: true })
        .then((response) => {
          if (response.data.loggedIn) {
            setIsLoggedIn(true);
          } else {
            navigate('/login');
            // localStorage.clear();
          }
        })
        .catch((error) => {
          console.error('Error checking authentication:', error);
          navigate('/login');
        });
    }, [navigate]);


    const [reviews, setReviews] = useState([]);
    // const [openDialog, setOpenDialog] = useState(false);
    const [likedReviews, setLikedReviews] = useState(new Set());
    const [dislikedReviews, setDislikedReviews] = useState(new Set());
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [overallRating, setOverallRating] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        username: '',
        stars: 0,
        comment: '',
        reply: '',
        replies: {},
    });
    const [starCounts, setStarCounts] = useState({
        5: 0,
        4: 0,
        3: 0,
        2: 0,
        1: 0,
    });
 
 
    // const navigate = useNavigate();
    // const location = useLocation();
    // const [role, setRole] = useState('');
    const [username, setUsername] = useState('');
 
    // useEffect(() => {
    //     const token = Cookies.get('token');
    //     const { state } = location;
 
    //     if (!token || !state || !state.username || !state.role) {
    //         navigate('/');
    //     } else {
    //         setUsername(state.username);
    //         setRole(state.role);
    //         console.log(state.username, state.role); // Log username and role
    //     }
    // }, [location, navigate]);
 
    // const handleLogout = () => {
    //     Cookies.remove('token');
    //     navigate('/');
    // };
 
 
    // useEffect(() => {
    //     if (c_id) {
    //         fetchReviews();
    //         fetchLikes();
    //     }
    // }, [username, likedReviews, dislikedReviews]);
 
     useEffect(() => {
       
            // fetchReviews();
            fetchLikes();
     
    }, []);
 
    const fetchLikes = () => {
        axios.get('https://api.booking.ashishphotography.co.in/likes', { params: { username } })
            .then(response => {
                const { likes, dislikes } = response.data;
                setLikedReviews(new Set(likes));
                setDislikedReviews(new Set(dislikes));
                // Force re-render to update UI
                setReviews(prevReviews => [...prevReviews]);
            })
            .catch(error => {
                console.error('Error fetching likes:', error);
            });
    };
 
    const storedUserId = localStorage.getItem("userId");
    const userId = storedUserId ? parseInt(storedUserId) : null;
 
 
    const [c_id, setCId] = useState(null);
    // console.log(c_id)
 
  useEffect(() => {
    // Fetch the c_id when the component mounts
    axios.get(`https://api.booking.ashishphotography.co.in/getCID/${userId}`)
      .then(response => {
        // Extract c_id from the response data
        const { c_id } = response.data;
        setCId(c_id);
      })
      .catch(error => {
        console.error('Error fetching c_id:', error);
      });
  }, [userId]);
 
    // const fetchReviews = () => {
    //     axios.get(`https://api.booking.ashishphotography.co.in/reviews/${c_id}`)
    //         .then(response => {
    //             setReviews(response.data);
    //         })
    //         .catch(error => {
    //             console.error('Error fetching reviews:', error);
    //         });
    // };
 
 
    useEffect(() => {
        axios.get(`https://api.booking.ashishphotography.co.in/reviews/${c_id}`)
            .then(response => {
                setReviews(response.data);
            })
            .catch(error => {
                console.error('Error fetching reviews:', error);
            });
      }, [c_id]);
 
    const handleChange = (e, reviewId) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
            replies: {
                ...prevState.replies,
                [reviewId]: value,
            },
        }));
    };
   
 
    const handleReply = (reviewId) => {
        const { reply } = formData;
        console.log("reply",reply)
        axios.post('https://api.booking.ashishphotography.co.in/reply', { reviewId, reply })
            .then(response => {
                console.log('Reply added successfully:', response.data);
                // Update the review's reply locally without making another API call
                setReviews(prevReviews =>
                    prevReviews.map(review =>
                        review.id === reviewId ? { ...review, reply } : review
                    )
                );
                // Clear the reply field
                setFormData(prevState => ({ ...prevState, reply: '',replies: {} }));
            })
            .catch(error => {
                console.error('Error adding reply:', error);
            });
    };
 
    const confirmDelete = (reviewId) => {
        if (window.confirm('Are you sure you want to delete this review?')) {
            handleDelete(reviewId);
        }
    };
   
 
    const handleDelete = (reviewId) => {
        axios.delete(`https://api.booking.ashishphotography.co.in/reviews/${reviewId}`)
            .then(response => {
                console.log('Review deleted successfully:', response.data);
                // Remove the deleted review from the local state
                setReviews(prevReviews => prevReviews.filter(review => review.id !== reviewId));
            })
            .catch(error => {
                console.error('Error deleting review:', error);
            });
    };
 
 
 
 
    useEffect(() => {
        calculateOverallRating();
    }, [reviews, likedReviews, dislikedReviews]); // Include dependencies that affect overall rating
   
    // Inside calculateOverallRating function
    const calculateOverallRating = () => {
        if (!reviews || reviews.length === 0) {
            // console.log('No reviews available.');
            setOverallRating(0);
            return;
        }
   
        const totalStars = reviews.reduce((accumulator, review) => accumulator + parseFloat(review.stars), 0);
        const averageRating = totalStars / reviews.length;
        if (averageRating !== overallRating) {
            console.log('Overall Rating changed:', averageRating);
            setOverallRating(averageRating);
        }
    };
   
 
    useEffect(() => {
        updateStarCounts(reviews);
    }, [reviews]);
   
    const updateStarCounts = (reviews) => {
        const counts = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
        reviews.forEach(review => {
            counts[Math.floor(review.stars)]++;
        });
        setStarCounts(counts);
    };
 
 
    function StarRating({ count }) {
        const stars = [];
        for (let i = 0; i < count; i++) {
          stars.push(<FaStar key={i} className="stars" />);
        }
        return stars;
      }
     
      function StarCount({ count }) {
        return (
          <span>
            <StarRating count={count} />
          </span>
        );
      }
     
   
   
 
    return (
        <>
        <div>
      <SideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
            <div className="testimonial-heading">
                <span>Reviews & Reply</span>
                {/* <h1>Admin Reply</h1>
                <br /> */}
                {/* <button onClick={handleLogout} className='btn btn-primary'>Logout</button> */}
            </div>
 
 
 
            <section id="testimonials">
                <div className="testimonial-box-container">
                    {reviews.map((review) => (
                        <div key={review.id} className="testimonial-box">
                           
                        <div className="box-top">
                        <div className="profile5">
                                    <div className="profile5-img">
                                         <img src={img} alt={`Client ${review.id}`} />
                                    </div>
                                    <div className="name-user">
                                        <strong>{review.name}</strong>
                                        <span>{review.username}</span>
                                    </div>
                                </div>
 
                                <div className="reviews">
                                    {Array.from({ length: Math.floor(review.stars) }, (_, index) => (
                                        <FaStar key={index} className="stars" />
                                    ))}
                                    {review.stars % 1 !== 0 && <FaStarHalfAlt className="stars" />}
                                    {Array.from({ length: Math.floor(5 - review.stars) }, (_, index) => (
                                        <FaRegStar key={index + Math.floor(review.stars)} className="stars" />
                                    ))}
                                </div>
                            </div>
                            <div className="client-comment">
                                <p>{review.comment}</p>
                            </div>
 
                            {/* <div className='Helpful d-flex gap-3'>
                                <p>Likes & Dislikes</p>
                                <span>
                                    {likedReviews.has(review.id) ? <p><AiFillLike className='likeicon' /> {review.likeCount}</p> : <p><AiOutlineLike className='likeicon' /> {review.likeCount}</p>}
                                </span>
                                <span >
                                    {dislikedReviews.has(review.id) ? <p><AiFillDislike className='likeicon' /> {review.dislikeCount}</p> : <p><AiOutlineDislike className='likeicon' /> {review.dislikeCount}</p>}
                                </span>
                            </div> */}
 
                            {review.reply && (      
                                <p>Admin Reply : {review.reply}</p>
                            )}
                                <div className='reply-input'>
                                    {review.reply ? (
                                      <TextField className='reply-input-text'
                                      multiline
                                      rows={2}
                                      name="reply"
                                      value={formData.replies[review.id] || ''}
                                      onChange={(e) => handleChange(e, review.id)}
                                      label="Update Reply"
                                      fullWidth
                                      variant="outlined"
                                      InputProps={{
                                          style: {
                                              border: "none"
                                          }
                                      }}
                                  />
                                 
                                   
                                    ) : (
                                        <TextField
                                            multiline
                                            rows={2}
                                            name="reply"
                                            value={formData.replies[review.id] || ''}
                                            onChange={(e) => handleChange(e, review.id)}
                                            label="Reply"
                                            fullWidth
                                        />
                                    )}
 
 
                                    <br/>
                                    <br/>
                                    <Button onClick={() => handleReply(review.id)} variant="outlined">Submit Reply</Button>
                                    {/* <Button style={{marginLeft:"10px" , color: "red"}} onClick={() => confirmDelete(review.id)} variant="outlined">Delete</Button> */}
                                </div>
                         
                        </div>
                    ))}
                </div>
            </section>
            </div>
        </>
    );
};
 
export default Admin;