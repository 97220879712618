import React, { useState, useEffect } from "react";
import "./Schedule.css";

import axios from "axios";
import { Modal, Input, Card, Button } from "antd";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

import Sidebar from "./Components/Sidebar";
import * as HoverCard from "@radix-ui/react-hover-card";
// import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { MdAddToPhotos } from "react-icons/md";
import DeleteIcon from "@mui/icons-material/Delete";
import { IoIosArrowRoundBack } from "react-icons/io";

const Schedule = () => {
  const [mondayChecked, setMondayChecked] = useState(false);
  const [tuesdayChecked, setTuesdayChecked] = useState(false);
  const [wednesdayChecked, setWednesdayChecked] = useState(false);
  const [thursdayChecked, setThursdayChecked] = useState(false);
  const [fridayChecked, setFridayChecked] = useState(false);
  const [saturdayChecked, setSaturdayChecked] = useState(false);
  const [sundayChecked, setSundayChecked] = useState(false);

  const [mondaySlots, setMondaySlots] = useState([
    { start_time: "", end_time: "" },
  ]);
  const [tuesdaySlots, setTuesdaySlots] = useState([
    { start_time: "", end_time: "" },
  ]);
  const [wednesdaySlots, setWednesdaySlots] = useState([
    { start_time: "", end_time: "" },
  ]);
  const [thursdaySlots, setThursdaySlots] = useState([
    { start_time: "", end_time: "" },
  ]);
  const [fridaySlots, setFridaySlots] = useState([
    { start_time: "", end_time: "" },
  ]);
  const [saturdaySlots, setSaturdaySlots] = useState([
    { start_time: "", end_time: "" },
  ]);
  const [sundaySlots, setSundaySlots] = useState([
    { start_time: "", end_time: "" },
  ]);

  const [timeInterval, setTimeInterval] = useState("15");
  const [cards, setCards] = useState([]);
  const [visible, setVisible] = useState(false);
  const [scheduleName, setScheduleName] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/");
      });
  }, [navigate]);

  useEffect(() => {
    fetchScheduleData();
  });

  const [slotData, setSlotData] = useState("");
  // console.log(slotData);

  const fetchScheduleData = async () => {
    try {
      const response = await axios.get(
        `https://api.booking.ashishphotography.co.in/api/schedule/save/${userId}`
      );
      const fetchedData = response.data;
      setSlotData(fetchedData);
      const newCards = fetchedData.map((schedule) => {
        const { scheduleName, scheduleData } = schedule;
        const cardContent = scheduleData.map(
          ({ day, start_time, end_time }) => (
            <div key={day}>
              <p>Day: {day}</p>
              <p>From: {start_time}</p>
              <p>To: {end_time}</p>
            </div>
          )
        );
        return (
          <div
            className="Schudle-card"
            key={scheduleName}
            style={{ marginBottom: 10 }}
          >
            <p>Schedule Name: {scheduleName}</p>
            {cardContent}
            <button
              className="Schudle-delete"
              onClick={() => handleDeleteCard(scheduleName)}
            >
              {" "}
              <DeleteIcon /> Delete
            </button>
          </div>
        );
      });
      setCards(newCards);
    } catch (error) {
      console.error("Error fetching schedule data:", error);
    }
  };

  const handleDayCheckboxChange = (day) => {
    switch (day) {
      case "monday":
        setMondayChecked(!mondayChecked);
        break;
      case "tuesday":
        setTuesdayChecked(!tuesdayChecked);
        break;
      case "wednesday":
        setWednesdayChecked(!wednesdayChecked);
        break;
      case "thursday":
        setThursdayChecked(!thursdayChecked);
        break;
      case "friday":
        setFridayChecked(!fridayChecked);
        break;
      case "saturday":
        setSaturdayChecked(!saturdayChecked);
        break;
      case "sunday":
        setSundayChecked(!sundayChecked);
        break;
      default:
        break;
    }
  };

  const handleAddSlot = (day) => {
    let updatedSlots;
    switch (day) {
      case "monday":
        updatedSlots = [...mondaySlots];
        setMondaySlots([...updatedSlots, { start_time: "", end_time: "" }]);
        break;
      case "tuesday":
        updatedSlots = [...tuesdaySlots];
        setTuesdaySlots([...updatedSlots, { start_time: "", end_time: "" }]);
        break;
      case "wednesday":
        updatedSlots = [...wednesdaySlots];
        setWednesdaySlots([...updatedSlots, { start_time: "", end_time: "" }]);
        break;
      case "thursday":
        updatedSlots = [...thursdaySlots];
        setThursdaySlots([...updatedSlots, { start_time: "", end_time: "" }]);
        break;
      case "friday":
        updatedSlots = [...fridaySlots];
        setFridaySlots([...updatedSlots, { start_time: "", end_time: "" }]);
        break;
      case "saturday":
        updatedSlots = [...saturdaySlots];
        setSaturdaySlots([...updatedSlots, { start_time: "", end_time: "" }]);
        break;
      case "sunday":
        updatedSlots = [...sundaySlots];
        setSundaySlots([...updatedSlots, { start_time: "", end_time: "" }]);
        break;
      default:
        break;
    }
  };

  const handleRemoveSlot = (day, index) => {
    let updatedSlots;
    switch (day) {
      case "monday":
        updatedSlots = [...mondaySlots];
        updatedSlots.splice(index, 1);
        setMondaySlots(updatedSlots);
        break;
      case "tuesday":
        updatedSlots = [...tuesdaySlots];
        updatedSlots.splice(index, 1);
        setTuesdaySlots(updatedSlots);
        break;
      case "wednesday":
        updatedSlots = [...wednesdaySlots];
        updatedSlots.splice(index, 1);
        setWednesdaySlots(updatedSlots);
        break;
      case "thursday":
        updatedSlots = [...thursdaySlots];
        updatedSlots.splice(index, 1);
        setThursdaySlots(updatedSlots);
        break;
      case "friday":
        updatedSlots = [...fridaySlots];
        updatedSlots.splice(index, 1);
        setFridaySlots(updatedSlots);
        break;
      case "saturday":
        updatedSlots = [...saturdaySlots];
        updatedSlots.splice(index, 1);
        setSaturdaySlots(updatedSlots);
        break;
      case "sunday":
        updatedSlots = [...sundaySlots];
        updatedSlots.splice(index, 1);
        setSundaySlots(updatedSlots);
        break;
      default:
        break;
    }
  };

  const handleTimeChange = (day, index, field, value) => {
    let updatedSlots;
    switch (day) {
      case "monday":
        updatedSlots = [...mondaySlots];
        if (field === "end_time" && value === updatedSlots[index].start_time) {
          alert("To time cannot be the same as From time.");
          return;
        }
        updatedSlots[index][field] = value;
        setMondaySlots(updatedSlots);
        break;
      case "tuesday":
        updatedSlots = [...tuesdaySlots];
        if (field === "end_time" && value === updatedSlots[index].start_time) {
          alert("To time cannot be the same as From time.");
          return;
        }
        updatedSlots[index][field] = value;
        setTuesdaySlots(updatedSlots);
        break;
      case "wednesday":
        updatedSlots = [...wednesdaySlots];
        if (field === "end_time" && value === updatedSlots[index].start_time) {
          alert("To time cannot be the same as From time.");
          return;
        }
        updatedSlots[index][field] = value;
        setWednesdaySlots(updatedSlots);
        break;
      case "thursday":
        updatedSlots = [...thursdaySlots];
        if (field === "end_time" && value === updatedSlots[index].start_time) {
          alert("To time cannot be the same as From time.");
          return;
        }
        updatedSlots[index][field] = value;
        setThursdaySlots(updatedSlots);
        break;
      case "friday":
        updatedSlots = [...fridaySlots];
        if (field === "end_time" && value === updatedSlots[index].start_time) {
          alert("To time cannot be the same as From time.");
          return;
        }
        updatedSlots[index][field] = value;
        setFridaySlots(updatedSlots);
        break;
      case "saturday":
        updatedSlots = [...saturdaySlots];
        if (field === "end_time" && value === updatedSlots[index].start_time) {
          alert("To time cannot be the same as From time.");
          return;
        }
        updatedSlots[index][field] = value;
        setSaturdaySlots(updatedSlots);
        break;
      case "sunday":
        updatedSlots = [...sundaySlots];
        if (field === "end_time" && value === updatedSlots[index].start_time) {
          alert("To time cannot be the same as From time.");
          return;
        }
        updatedSlots[index][field] = value;
        setSundaySlots(updatedSlots);
        break;
      default:
        break;
    }
  };

  const handleback = () => {
    window.history.back()
  }

  const handleTimeIntervalChange = (value) => {
    setTimeInterval(value);
  };

  const renderTimeOptions = () => {
    const options = [];
    const amOptions = [];
    const pmOptions = [];
    for (let hour = 1; hour <= 12; hour++) {
      for (let minute = 0; minute < 60; minute += parseInt(timeInterval)) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        const time = `${formattedHour}:${formattedMinute}`;
        amOptions.push(`${time} AM`);
        pmOptions.push(`${time} PM`);
      }
    }
    const allOptions = [...amOptions, ...pmOptions];
    allOptions.forEach((option) => {
      options.push(<option key={option}>{option}</option>);
    });
    return options;
  };
  const handleSave = () => {
    setVisible(true);
  };

  const renderSlots = (day, slots) => {
    return (
      <div className="ant-col ant-col-24 add-slots css-166aztq schedule-box">
        <HoverCard.Content
          side="right"
          className="Schedule-HoverCardContent"
          sideOffset={5}
        >
          <div className="card-text">
            {slots.map((slot, index) => (
              // <div className="ant-row slot-item css-166aztq" key={index}>
              //   <div className="ant-col ant-col-xs-24 ant-col-md-8 css-166aztq"></div>
              //   <div className="ant-col ant-col-xs-24 ant-col-md-16 css-166aztq">
              //     <div className="slots-section">
              //       <div className="ant-typography slots-select-box css-166aztq time">
              //         <select
              //         size="10"
              //           value={slot.start_time}
              //           onChange={(e) =>
              //             handleTimeChange(
              //               day,
              //               index,
              //               "start_time",
              //               e.target.value
              //             )
              //           }
              //         >
              //           <option value="">From</option>

              //           {renderTimeOptions()}

              //         </select>
              //         <div style={{ display: "inline", margin: "15px 1em" }} className="dash">
              //           -
              //         </div>
              //         <select
              //           value={slot.end_time}
              //           onChange={(e) =>
              //             handleTimeChange(
              //               day,
              //               index,
              //               "end_time",
              //               e.target.value
              //             )
              //           }
              //           className="ant-select-selection-search-input custom-dropdown"
              //         >
              //           <option value="">To</option>
              //           {renderTimeOptions()}
              //         </select>

              //             <div className='Schedule-buttons'>
              //               <button type="button" className="ant-btn css-166aztq ant-btn-text add-slot-btn Slot-Add" onClick={()=>handleAddSlot(day)}>
              //               <MdAddToPhotos id='ScheduleAdd' />Add
              //               </button>
              //               {index > 0 && (
              //                 <button type="button" className="ant-btn css-166aztq ant-btn-text remove-slot-btn Slot-Delete" onClick={() => handleRemoveSlot(day,index)}>
              //                 <DeleteIcon id='ScheduleDelete'/> Delete
              //                 </button>
              //               )}
              //             </div>
              //       </div>
              //     </div>
              //   </div>
              // </div>
              <div className="row slot-item css-166aztq" key={index}>
                <div className="col-12 col-md-8 css-166aztq"></div>
                <div className="col-12 col-md-16 css-166aztq">
                  <div className="slots-section">
                    <div className="form-group slots-select-box css-166aztq time">
                      <select
                        id="form-control"
                        value={slot.start_time}
                        onChange={(e) =>
                          handleTimeChange(
                            day,
                            index,
                            "start_time",
                            e.target.value
                          )
                        }
                      >
                        <option value="">From</option>
                        {renderTimeOptions()}
                      </select>
                      <div
                        style={{ display: "inline", margin: "15px 1em" }}
                        className="dash"
                      >
                        -
                      </div>
                      <select
                        className="form-control" // Use Bootstrap's form-control class
                        value={slot.end_time}
                        onChange={(e) =>
                          handleTimeChange(
                            day,
                            index,
                            "end_time",
                            e.target.value
                          )
                        }
                      >
                        <option value="">To</option>
                        {renderTimeOptions()}
                      </select>

                      <div className="Schedule-buttons">
                        <button
                          type="button"
                          className="btn btn-primary add-slot-btn Slot-Add"
                          onClick={() => handleAddSlot(day)}
                        >
                          Add
                        </button>
                        {index > 0 && (
                          <button
                            type="button"
                            className="btn btn-danger remove-slot-btn Slot-Delete"
                            onClick={() => handleRemoveSlot(day, index)}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <HoverCard.Arrow className="HoverCard-indication" />
        </HoverCard.Content>
      </div>
    );
  };

  const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;

  const handleModalSave = async () => {
    setVisible(false);
    try {
      const selectedDays = [
        { day: "Monday", slots: mondayChecked ? mondaySlots : [] },
        { day: "Tuesday", slots: tuesdayChecked ? tuesdaySlots : [] },
        { day: "Wednesday", slots: wednesdayChecked ? wednesdaySlots : [] },
        { day: "Thursday", slots: thursdayChecked ? thursdaySlots : [] },
        { day: "Friday", slots: fridayChecked ? fridaySlots : [] },
        { day: "Saturday", slots: saturdayChecked ? saturdaySlots : [] },
        { day: "Sunday", slots: sundayChecked ? sundaySlots : [] },
      ];

      await axios.post("https://api.booking.ashishphotography.co.in/api/schedule/save", {
        scheduleName,
        scheduleData: selectedDays,
        userId,
      });

      const newCards = selectedDays.map(({ day, slots }) => {
        if (slots.length === 0) return null; // If no slots, don't create a card
        return (
          <Card key={`${scheduleName}_${day}`} style={{ marginBottom: 10 }}>
            <p>Schedule Name: {scheduleName}</p>
            <p>Day: {day}</p>
            {slots.map(({ start_time, end_time }, index) => (
              <p key={index}>
                From: {start_time}, To: {end_time}
              </p>
            ))}
            <Button
              type="danger"
              onClick={() => handleDeleteCard(scheduleName)}
            >
              Delete
            </Button>
          </Card>
        );
      });

      setCards([...cards, ...newCards.filter((card) => card !== null)]);
    } catch (error) {
      console.error("Error saving schedule:", error);
      alert("Failed to save schedule. Please try again later.");
    }
  };

  const handleModalCancel = () => {
    setVisible(false);
  };

  const handleDeleteCard = async (scheduleName) => {
    try {
      await axios.delete(`https://api.booking.ashishphotography.co.in/api/schedule/${scheduleName}`);
      setCards(cards.filter((card) => card.key !== scheduleName));
      fetchScheduleData();
      alert("Card Deleted Successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error deleting schedule:", error);
      alert("Failed to delete schedule. Please try again later.");
    }
  };

  // const [selectedScheduleName, setSelectedScheduleName] = useState('');

  // const handleScheduleSelect = (e) => {
  //   setSelectedScheduleName(e.target.value);
  // };

  const [scheduleData, setScheduleData] = useState([]);
  // console.log(scheduleData);
  const [filteredScheduleNames, setFilteredScheduleNames] = useState([]);
  const [selectedScheduleName, setSelectedScheduleName] = useState("");
  const handleFilterChange = (e) => {
    setSelectedScheduleName(e.target.value);
  };
  useEffect(() => {
    // Fetch schedule data from the backend when the component mounts
    axios
      .get(`https://api.booking.ashishphotography.co.in/api/timeslots/${userId}`)
      .then((response) => {
        setScheduleData(response.data);
        const uniqueScheduleNames = [
          ...new Set(response.data.map((schedule) => schedule.scheduleName)),
        ];
        setFilteredScheduleNames(uniqueScheduleNames);
      })
      .catch((error) => {
        console.error("Error fetching schedule data: ", error);
      });
  }, [userId]);

  return (
    <div className="d-flex">
      {windowWidth > 390 && (
        <Sidebar
          isOpen={isSidebarOpen}
          setIsOpen={setIsSidebarOpen}
          className="Schudle-sidebar"
        />
      )}

      <div className="Schudle">
        <div className="ant-col ant-col-24 add-slots css-166aztq">
          <div className="ant-row css-166aztq">
            <div className="ant-col ant-col-24 add-slots-header-section css-166aztq">
              <div className="Schedule-top">
                <div
                  className="ant-typography ant-typography-ellipsis ant-typography-ellipsis-multiple-line slots-header css-166aztq Schedule-default"
                  style={{ WebkitLineClamp: 2 }}
                >
                <div className="def-icon-arrow">
                <IoIosArrowRoundBack onClick={handleback} className="mb-3 "/>
                <p>Default</p>
                </div>
                  
                </div>
                <div className="Top-2">
                  <div className="ant-space css-166aztq ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small">
                    <div className="ant-space-item">
                      <select
                        value={timeInterval}
                        onChange={(e) =>
                          handleTimeIntervalChange(e.target.value)
                        }
                        className="ant-select-selection-search-input custom-dropdown  Schedule-time"
                      >
                        <option value="15">15 min</option>
                        <option value="30">30 min</option>
                        <option value="45">45 min</option>
                      </select>
                    </div>
                    <Modal
                      title="Enter Schedule Name"
                      visible={visible}
                      onOk={handleModalSave}
                      onCancel={handleModalCancel}
                    >
                      <Input
                        value={scheduleName}
                        onChange={(e) => setScheduleName(e.target.value)}
                      />
                    </Modal>
                  </div>
                  <div className="ant-space-item">
                    <button
                      type="button"
                      className="ant-btn css-166aztq ant-btn-default btn-dark Schedule-save"
                      onClick={handleSave}
                    >
                      <span>Save</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="7-days">
            <div className="Schedule-weekdays">
              <HoverCard.Root>
                <HoverCard.Trigger asChild>
                  <p>
                    <div className="Schedule-day-1">
                      <div className="ant-row slot-item css-166aztq">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="ant-col ant-col-xs-24 ant-col-md-8 css-166aztq">
                              <label className="ant-checkbox-wrapper slots-title css-166aztq cat">
                                <span
                                  className={`ant-checkbox ant-wave-target css-166aztq ${
                                    mondayChecked ? "ant-checkbox-checked" : ""
                                  }`}
                                >
                                  <input
                                    className="ant-checkbox-input checkbox-1"
                                    type="checkbox"
                                    value="0"
                                    checked={mondayChecked}
                                    onChange={() =>
                                      handleDayCheckboxChange("monday")
                                    }
                                  />
                                  {/* <span className="ant-checkbox-inner"></span> */}
                                </span>
                                <span className="day-1">Monday</span>
                                <p className="day-8">Mon</p>
                              </label>
                            </div>
                            <div className="col-lg-6">
                              {mondayChecked ? (
                                renderSlots("monday", mondaySlots)
                              ) : (
                                <div className="col-lg-6">
                                  <div className="ant-col ant-col-xs-24 ant-col-md-16 css-166aztq">
                                    <div className="slots-section">
                                      <div className="ant-typography slots-unavailable css-166aztq"></div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p>
                </HoverCard.Trigger>
              </HoverCard.Root>
            </div>

            <div className="Schedule-weekdays">
              <HoverCard.Root>
                <HoverCard.Trigger asChild>
                  <p
                    className="ImageTrigger"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className="Schedule-day-2">
                      <div className="ant-row slot-item css-166aztq">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="ant-col ant-col-xs-24 ant-col-md-8 css-166aztq">
                              <label className="ant-checkbox-wrapper slots-title css-166aztq cat">
                                <span
                                  className={`ant-checkbox ant-wave-target css-166aztq ${
                                    tuesdayChecked ? "ant-checkbox-checked" : ""
                                  }`}
                                >
                                  <input
                                    className="ant-checkbox-input checkbox-1"
                                    type="checkbox"
                                    value="0"
                                    checked={tuesdayChecked}
                                    onChange={() =>
                                      handleDayCheckboxChange("tuesday")
                                    }
                                  />
                                  {/* <span className="ant-checkbox-inner"></span> */}
                                </span>
                                <span className="day-1">Tuesday</span>
                                <p className="day-8">Tue</p>
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            {tuesdayChecked ? (
                              renderSlots("tuesday", tuesdaySlots)
                            ) : (
                              <div className="col-lg-6">
                                <div className="ant-col ant-col-xs-24 ant-col-md-16 css-166aztq">
                                  <div className="slots-section">
                                    <div className="ant-typography slots-unavailable css-166aztq"></div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </p>
                </HoverCard.Trigger>
              </HoverCard.Root>
            </div>

            <div className="Schedule-weekdays">
              <HoverCard.Root>
                <HoverCard.Trigger asChild>
                  <p
                    className="ImageTrigger"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className="Schedule-day-3">
                      <div className="ant-row slot-item css-166aztq">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="ant-col ant-col-xs-24 ant-col-md-8 css-166aztq">
                              <label className="ant-checkbox-wrapper slots-title css-166aztq cat">
                                <span
                                  className={`ant-checkbox ant-wave-target css-166aztq ${
                                    wednesdayChecked
                                      ? "ant-checkbox-checked"
                                      : ""
                                  }`}
                                >
                                  <input
                                    className="ant-checkbox-input checkbox-1"
                                    type="checkbox"
                                    value="0"
                                    checked={wednesdayChecked}
                                    onChange={() =>
                                      handleDayCheckboxChange("wednesday")
                                    }
                                  />
                                  {/* <span className="ant-checkbox-inner"></span> */}
                                </span>
                                <span className="day-1">Wednesday</span>
                                <p className="day-8">Wed</p>
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            {wednesdayChecked ? (
                              renderSlots("wednesday", wednesdaySlots)
                            ) : (
                              <div className="col-lg-6">
                                <div className="ant-col ant-col-xs-24 ant-col-md-16 css-166aztq">
                                  <div className="slots-section">
                                    <div className="ant-typography slots-unavailable css-166aztq"></div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </p>
                </HoverCard.Trigger>
              </HoverCard.Root>
            </div>

            <div className="Schedule-weekdays">
              <HoverCard.Root>
                <HoverCard.Trigger asChild>
                  <p
                    className="ImageTrigger"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className="Schedule-day-4">
                      <div className="ant-row slot-item css-166aztq">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="ant-col ant-col-xs-24 ant-col-md-8 css-166aztq">
                              <label className="ant-checkbox-wrapper slots-title css-166aztq cat">
                                <span
                                  className={`ant-checkbox ant-wave-target css-166aztq ${
                                    thursdayChecked
                                      ? "ant-checkbox-checked"
                                      : ""
                                  }`}
                                >
                                  <input
                                    className="ant-checkbox-input checkbox-1"
                                    type="checkbox"
                                    value="0"
                                    checked={thursdayChecked}
                                    onChange={() =>
                                      handleDayCheckboxChange("thursday")
                                    }
                                  />
                                  {/* <span className="ant-checkbox-inner"></span> */}
                                </span>
                                <span className="day-1">Thursday</span>
                                <p className="day-8">Thur</p>
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            {thursdayChecked ? (
                              renderSlots("thursday", thursdaySlots)
                            ) : (
                              <div className="col-lg-6">
                                <div className="ant-col ant-col-xs-24 ant-col-md-16 css-166aztq">
                                  <div className="slots-section">
                                    <div className="ant-typography slots-unavailable css-166aztq"></div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </p>
                </HoverCard.Trigger>
              </HoverCard.Root>
            </div>

            <div className="Schedule-weekdays">
              <HoverCard.Root>
                <HoverCard.Trigger asChild>
                  <p
                    className="ImageTrigger"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className="Schedule-day-5">
                      <div className="ant-row slot-item css-166aztq">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="ant-col ant-col-xs-24 ant-col-md-8 css-166aztq">
                              <label className="ant-checkbox-wrapper slots-title css-166aztq cat">
                                <span
                                  className={`ant-checkbox ant-wave-target css-166aztq ${
                                    fridayChecked ? "ant-checkbox-checked" : ""
                                  }`}
                                >
                                  <input
                                    className="ant-checkbox-input checkbox-1"
                                    type="checkbox"
                                    value="0"
                                    checked={fridayChecked}
                                    onChange={() =>
                                      handleDayCheckboxChange("friday")
                                    }
                                  />
                                  {/* <span className="ant-checkbox-inner"></span> */}
                                </span>
                                <span className="day-1">Friday</span>
                                <p className="day-8">Fri</p>
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            {fridayChecked ? (
                              renderSlots("friday", fridaySlots)
                            ) : (
                              <div className="col-lg-6">
                                <div className="ant-col ant-col-xs-24 ant-col-md-16 css-166aztq">
                                  <div className="slots-section">
                                    <div className="ant-typography slots-unavailable css-166aztq"></div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </p>
                </HoverCard.Trigger>
              </HoverCard.Root>
            </div>

            <div className="Schedule-weekdays">
              <HoverCard.Root>
                <HoverCard.Trigger asChild>
                  <p
                    className="ImageTrigger"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className="Schedule-day-6">
                      <div className="ant-row slot-item css-166aztq">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="ant-col ant-col-xs-24 ant-col-md-8 css-166aztq">
                              <label className="ant-checkbox-wrapper slots-title css-166aztq cat">
                                <span
                                  className={`ant-checkbox ant-wave-target css-166aztq ${
                                    saturdayChecked
                                      ? "ant-checkbox-checked"
                                      : ""
                                  }`}
                                >
                                  <input
                                    className="ant-checkbox-input checkbox-1"
                                    type="checkbox"
                                    value="0"
                                    checked={saturdayChecked}
                                    onChange={() =>
                                      handleDayCheckboxChange("saturday")
                                    }
                                  />
                                  {/* <span className="ant-checkbox-inner"></span> */}
                                </span>
                                <span className="day-1">Saturday</span>
                                <p className="day-8">Sat</p>
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            {saturdayChecked ? (
                              renderSlots("saturday", saturdaySlots)
                            ) : (
                              <div className="col-lg-6">
                                <div className="ant-col ant-col-xs-24 ant-col-md-16 css-166aztq">
                                  <div className="slots-section">
                                    <div className="ant-typography slots-unavailable css-166aztq"></div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </p>
                </HoverCard.Trigger>
              </HoverCard.Root>
            </div>

            <div className="Schedule-weekdays">
              <HoverCard.Root>
                <HoverCard.Trigger asChild>
                  <p
                    className="ImageTrigger"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div className="Schedule-day-7">
                      <div className="ant-row slot-item css-166aztq">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="ant-col ant-col-xs-24 ant-col-md-8 css-166aztq">
                              <label className="ant-checkbox-wrapper slots-title css-166aztq cat">
                                <span
                                  className={`ant-checkbox ant-wave-target css-166aztq ${
                                    sundayChecked ? "ant-checkbox-checked" : ""
                                  }`}
                                >
                                  <input
                                    className="ant-checkbox-input checkbox-1"
                                    type="checkbox"
                                    value="0"
                                    checked={sundayChecked}
                                    onChange={() =>
                                      handleDayCheckboxChange("sunday")
                                    }
                                  />
                                  {/* <span className="ant-checkbox-inner"></span> */}
                                </span>
                                <span className="day-1">Sunday</span>
                                <p className="day-8">Sun</p>
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            {sundayChecked ? (
                              renderSlots("sunday", sundaySlots)
                            ) : (
                              <div className="col-lg-6">
                                <div className="ant-col ant-col-xs-24 ant-col-md-16 css-166aztq">
                                  <div className="slots-section">
                                    <div className="ant-typography slots-unavailable css-166aztq"></div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </p>
                </HoverCard.Trigger>
              </HoverCard.Root>
            </div>
          </div>
        </div>
        <div>
          <Tab.Container id="horizontal-tabs-example" defaultActiveKey="first">
            <Col className="bottom-nav">
              {/* <Nav variant=" nav-buttons">
                <Nav.Item>
                  <Nav.Link eventKey="first">Slots</Nav.Link>
                </Nav.Item>
 
                <Nav.Item>
                  <Nav.Link eventKey="second">schudule</Nav.Link>
                </Nav.Item>
              </Nav> */}
              <div></div>

              <h3 className="sch-head">Scheduled Slots</h3>
              <select
                className="schedule-filter"
                value={selectedScheduleName}
                onChange={handleFilterChange}
              >
                <option value="">All Schedules</option>
                {filteredScheduleNames.map((scheduleName) => (
                  <option key={scheduleName} value={scheduleName}>
                    {scheduleName}
                  </option>
                ))}
              </select>
            </Col>

            <Col className="saved-data">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="Schedul-booked-cards">
                    {/* <select
                        value={selectedScheduleName}
                        onChange={handleFilterChange}
                      >
                        <option value="">All Schedules</option>
                        {filteredScheduleNames.map((scheduleName) => (
                          <option key={scheduleName} value={scheduleName}>
                            {scheduleName}
                          </option>
                        ))}
                      </select> */}

                    {scheduleData
                      .filter(
                        (schedule) =>
                          !selectedScheduleName ||
                          schedule.scheduleName === selectedScheduleName
                      )
                      .map((schedule) => (
                        <div
                          className="Schudle-card"
                          key={schedule}
                          style={{ marginBottom: 20 }}
                        >
                          <p>
                            <h6> Name </h6>: {schedule.scheduleName}
                          </p>
                          <p>
                            <h6>Day</h6>: {schedule.day}
                          </p>
                          <p>
                            {" "}
                            <h6>From</h6>: {schedule.start_time}
                          </p>
                          <p>
                            {" "}
                            <h6>To</h6>: {schedule.end_time}
                          </p>
                          <button
                            className="Schudle-delete"
                            onClick={() =>
                              handleDeleteCard(schedule.scheduleName)
                            }
                          >
                            {" "}
                            <DeleteIcon id="Schedule-delete" /> Delete
                          </button>
                        </div>
                      ))}
                  </div>
                </Tab.Pane>
                {/* <Tab.Pane eventKey="second">
                  <div className="Schedule-Slots-cards">{cards}</div>
                </Tab.Pane> */}
              </Tab.Content>
            </Col>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
