import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import "./MyBookings.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
// import call from "./assets/1call.svg";
// import booking from "./assets/package.svg";
// import webinar from "./assets/webinar.svg";
// import digital from "./assets/digital.svg";
import SideBar from "./Sidebar";
import axios from "axios";
import { Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import { FaHandshakeSimple } from "react-icons/fa6";
const useStyles = makeStyles({
  card: {
    marginBottom: "10px",
  },
});
 
const MyBookings = () => {
  const classes = useStyles();
 
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
 
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
 
  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);
 
  const [c_id, setCId] = useState("");
  const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;
  console.log(userId);
 
  useEffect(() => {
    axios
      .get(`https://api.booking.ashishphotography.co.in/getCID/${userId}`)
      .then((response) => {
        // Handle success
        const { data } = response;
        const fetchedCId = data.c_id;
        setCId(fetchedCId);
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching c_id:", error);
        // Handle errors here
      });
  }, [userId]);
  console.log(c_id);
 
  const [hostMeetings, setHostMeetings] = useState([]);
 
  const fetchHostData = async () => {
    try {
      const response = await axios.get(`https://api.booking.ashishphotography.co.in/host/${c_id}`);
      const { data } = response;
      if (data.success) {
        setHostMeetings(data.data);
      } else {
        console.error("Error fetching host data:", data.message);
        // Handle errors here
      }
    } catch (error) {
      console.error("Error fetching host data:", error);
      // Handle errors here
    }
  };
  useEffect(() => {
    if (c_id){
    fetchHostData();
  }
  });
 
  const [completedMeetings, setCompletedMeetings] = useState([]);
 
  useEffect(()  => {
    if(c_id){
     axios
      .get(`https://api.booking.ashishphotography.co.in/attendedmeetings/${c_id}`)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          setCompletedMeetings(data.data);
        } else {
          console.error("Error fetching completed meetings:", data.message);
          // Handle errors here
        }
      })
      .catch((error) => {
        console.error("Error fetching completed meetings:", error);
        // Handle errors here
      });
    }
  }, [c_id]);
 
  const [completedUserMeetings, setCompletedUserMeetings] = useState([]);
 
  useEffect(() => {
    if(userId){
    axios
      .get(`https://api.booking.ashishphotography.co.in/userattendedmeetings/${userId}`)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          setCompletedUserMeetings(data.data);
        } else {
          console.error("Error fetching completed meetings:", data.message);
          // Handle errors here
        }
      })
      .catch((error) => {
        console.error("Error fetching completed meetings:", error);
        // Handle errors here
      });
    }
  }, [userId]);
 
 
 
  const [userMeetings, setUserMeetings] = useState([]);
  // console.log(userMeetings);
 
  useEffect(() => {
    axios
      .get(`https://api.booking.ashishphotography.co.in/usermeet/${userId}`)
      .then((response) => { 
        const { data } = response;
        if (data.success) {
          setUserMeetings(data.data);
        } else {
          console.error("Error fetching user meetings:", data.message);
          // Handle errors here
        }
      })
      .catch((error) => {
        console.error("Error fetching user meetings:", error);
        // Handle errors here
      });
  }, [userId]);
 
  const deleteMeeting = (bookId) => {
    if (window.confirm("Are you sure you want to logout?")) {
      axios
        .delete(`https://api.booking.ashishphotography.co.in/meeting/${bookId}`)
        .then((response) => {
          console.log("Meeting deleted successfully:", response.data);
          // Handle UI updates or any other logic after successful deletion
          fetchHostData();
        })
        .catch((error) => {
          console.error("Error deleting meeting:", error);
          // Handle errors here
        });
    }
  };
 
  return (
    <div className="d-flex">
      <SideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
      <div className="mybookings">
        <h1 className="mb-4">My Bookings</h1>
        <Tab.Container id="left-tabs-example" defaultActiveKey="1:1 calls">
          <Row>
            <Col sm={12}>
              <Nav variant="pills" className="flex-row">
                <Nav.Item>
                  <Nav.Link eventKey="1:1 calls">My Bookings</Nav.Link>
                </Nav.Item>
               
                <Nav.Item>
                  <Nav.Link eventKey="Webinars">Bookings</Nav.Link>
                </Nav.Item>
               
              </Nav>
            </Col>
            <hr className="mt-3 mb-3" />
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane className="tab-content" eventKey="1:1 calls">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="upcoming"
                  >
                    <Row>
                      <Col sm={12}>
                        <Nav variant="pills" className="flex-row">
                          <Nav.Item>
                            <Nav.Link eventKey="upcoming">
                              <div className="inside-tabs">Upcoming</div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Completed">Completed</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane
                            className="tab-content "
                            eventKey="upcoming"
                          >
                         
                            <div className="calls-container">
                         
                            <div className="meeting-cards-container">
                              {userMeetings && userMeetings.length > 0 ? (
                                userMeetings.map((meeting) => (
                                 
                                  <div
                                    key={meeting.meetingId}
                                    className={classes.card}
                                  >
                                    <CardContent className="upcoming-card">
                                      <div className="Card-title">
                                        User Meeting Details
                                      </div>
                                      <Typography className="body1">
                                        <h6>Meeting ID</h6>{": "}
                                        {meeting.meetingId}
                                      </Typography>
                                      <Typography className="body2">
                                        <h6>Room Name</h6>{": "}
                                        {meeting.name}
                                      </Typography>
                                      <Typography className="body2">
                                        <h6>Start time</h6>{": "}
                                        {new Date(
                                          new Date(
                                            meeting.startDate
                                          ).getTime() -
                                            5.5 * 60 * 60 * 1000
                                        ).toLocaleString()}
                                      </Typography>
 
                                      {(() => {
                                        const startTime = new Date(
                                          new Date(
                                            meeting.startDate
                                          ).getTime() -
                                            5.5 * 60 * 60 * 1000
                                        ).getTime();
                                        const endTime = new Date(
                                          new Date(meeting.endDate).getTime() -
                                            5.5 * 60 * 60 * 1000
                                        ).getTime();
                                        const currentTime =
                                          new Date().getTime();
 
                                        if (endTime <= currentTime) {
                                          return (
                                            <b style={{ color: "red" }}>
                                              Meeting Ended
                                            </b>
                                          );
                                        } else if (startTime > currentTime) {
                                          return (
                                           
                                            <button
                                              className="btn btn-primary mybooking-meeting"
                                              style={{ cursor: "not-allowed", marginTop: "15px" }}
                                              disabled
                                            >
                                              Meeting Not Started
                                            </button>
                                          );
                                        } else {
                                          return (
                                            <a
                                              href={meeting.hostRoomUrl}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <button className="btn btn-primary">
                                                Click me
                                              </button>
                                            </a>
                                          );
                                        }
                                      })()}
                                    </CardContent>
                                  </div>
                                ))
                              ) : (
                                <Typography variant="body1">
                                  No meetings found
                                </Typography>
                              )}
                            </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane
                            className="tab-content"
                            eventKey="Completed"
                          >
                            <div className="new-tab-content metting-comnplete">
                              {completedUserMeetings.map((complete, index) => {
                                return (
                                  <div>
                                    <CardContent className="completed-card">
                                      <Typography className="body-1">
                                        <h6>Name</h6>
                                        {": "}
                                        {complete.name}
                                      </Typography>
                                      <Typography className="body-2">
                                        <h6>Start time</h6>
                                        {": "}
                                        {new Date(
                                          new Date(
                                            complete.startDate
                                          ).getTime() -
                                            5.5 * 60 * 60 * 1000
                                        ).toLocaleString()}
                                      </Typography>
                                      <Typography className="body-2">
                                        <h6>End time</h6>
                                        {": "}
                                        {new Date(
                                          new Date(complete.endDate).getTime() -
                                            5.5 * 60 * 60 * 1000
                                        ).toLocaleString()}
                                      </Typography>
                                    </CardContent>
                                    <div className="metting-completed">
                                      <FaHandshakeSimple className="shaking-hands" />
                                      Metting Completed
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Tab.Pane>
 
                <Tab.Pane className="tab-content" eventKey="Webinars">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="Webinarsupcoming"
                  >
                    <Row>
                      <Col sm={12}>
                        <Nav variant="pills" className="flex-row">
                          <Nav.Item>
                            <Nav.Link eventKey="Webinarsupcoming">
                              <div className="inside-tabs">Upcoming</div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Completed">Completed</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane
                            className="tab-content"
                            eventKey="Webinarsupcoming"
                          >
                            <div className="new-tab-content">
                              {/* <img src={webinar} alt="booking" />
                            <h4>Create a Webinar</h4>
                            <p>
                            Address common questions by hosting a webinar for the masses.
                            </p>
                            <button className="book-btn">Add New</button> */}
                              <div className="meeting-cards-container">
                                {hostMeetings && hostMeetings.length > 0 ? (
                                  hostMeetings.map((meeting) => (
                                    <div
                                      key={meeting.meetingId}
                                      className={classes.card}
                                    >
                                      <CardContent className="upcoming-card">
                                        <div className="Card-title">
                                          Meeting Details
                                        </div>
                                        <Typography className="body1">
                                          <h6>Meeting ID</h6>
                                          {": "}
                                          {meeting.meetingId}
                                        </Typography>
                                        <Typography className="body2">
                                          <h6>Room Name</h6>
                                          {": "}
                                          {meeting.name}
                                        </Typography>
                                        <Typography className="body2">
                                          <h6>Start time</h6>
                                          {": "}
                                          {new Date(
                                            new Date(
                                              meeting.startDate
                                            ).getTime() -
                                              5.5 * 60 * 60 * 1000
                                          ).toLocaleString()}
                                        </Typography>
 
                                        <div className="card-buttons">
                                          {(() => {
                                            const startTime = new Date(
                                              new Date(
                                                meeting.startDate
                                              ).getTime() -
                                                5.5 * 60 * 60 * 1000
                                            ).getTime();
                                            const endTime = new Date(
                                              new Date(
                                                meeting.endDate
                                              ).getTime() -
                                                5.5 * 60 * 60 * 1000
                                            ).getTime();
                                            const currentTime =
                                              new Date().getTime();
 
                                            // eslint-disable-next-line no-lone-blocks
                                            {
                                              /* console.log(
                        "Start Time:",
                        new Date(startTime).toLocaleString()
                      );
                      console.log(
                        "End Time:",
                        new Date(endTime).toLocaleString()
                      );
                      console.log(
                        "Current Time:",
                        new Date(currentTime).toLocaleString()
                      ); */
                                            }
 
                                            if (endTime <= currentTime) {
                                              return (
                                                <b style={{ color: "red" }}>
                                                  Meeting Ended
                                                </b>
                                              );
                                            } else if (
                                              startTime > currentTime
                                            ) {
                                              return (
                                                <button
                                                  className="btn btn-primary meeting-btn"
                                                  style={{
                                                    cursor: "not-allowed",
                                                  }}
                                                  disabled
                                                >
                                                  Meeting Not Started
                                                </button>
                                              );
                                            } else {
                                              return (
                                                <a
                                                  href={meeting.hostRoomUrl}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  <button className="btn btn-primary">
                                                    Click me
                                                  </button>
                                                </a>
                                              );
                                            }
                                          })()}
 
                                          <button
                                            onClick={() =>
                                              deleteMeeting(meeting.book_id)
                                            }
                                            className="btn btn-danger meeting-delete"
                                          >
                                            Delete Meeting
                                          </button>
                                        </div>
                                      </CardContent>
                                    </div>
                                  ))
                                ) : (
                                  <Typography variant="body1">
                                    No meetings found
                                  </Typography>
                                )}
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane
                            className="tab-content"
                            eventKey="Completed"
                          >
                            <div className="new-tab-content metting-comnplete">
                              {completedMeetings.map((complete, index) => {
                                return (
                                  <div>
                                    <CardContent className="completed-card">
                                      <Typography className="body-1">
                                        <h6>Name</h6>
                                        {": "}
                                        {complete.name}
                                      </Typography>
                                      <Typography className="body-2">
                                        <h6>Start time</h6>
                                        {": "}
                                        {new Date(
                                          new Date(
                                            complete.startDate
                                          ).getTime() -
                                            5.5 * 60 * 60 * 1000
                                        ).toLocaleString()}
                                      </Typography>
                                      <Typography className="body-2">
                                        <h6>End time</h6>
                                        {": "}
                                        {new Date(
                                          new Date(complete.endDate).getTime() -
                                            5.5 * 60 * 60 * 1000
                                        ).toLocaleString()}
                                      </Typography>
                                    </CardContent>
                                    <div className="metting-completed">
                                      <FaHandshakeSimple className="shaking-hands" />
                                      Metting Completed
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};
 
export default MyBookings;