import React, { useState } from "react";
import "./Card.css";
import log from './assets/log.png'
// import { Link } from "react-router-dom";
// import { SlCalender } from "react-icons/sl";
import { LuPen } from "react-icons/lu";
import { TbTopologyStar3 } from "react-icons/tb";
 
 
const Cards = () => {
  const [isHidden, setIsHidden] = useState(true);
 
  const toggleHidden = () => {
    setIsHidden(!isHidden);
  };
 
  return (
    <div className="whole">
      <div className="bac">
        <p></p>
        <div className="lo">
          <p className="bo">
            <img src={log} alt="log" className="log" />
            <div className="tor">
              <p className="nto">
                Mentorship / Career<br></br>Guidance
              </p>
              <p className="nm">
                <p className="trik">$39</p>
              </p>
            </div>
            <div>
              <p className="vi">Video Meeting . 30mins</p>
            </div>
            <div className="thr">
              <p className="st">
                {" "}
                {/* <SlCalender className="con" /> */}
                <p className="feb">
                  St, 17Feb | 6:30 -7:00AM (GMT +05:30)
                  </p>
                  <LuPen className="pen" />
              </p>
            </div>
            <div>
              <p className="nae">
                <TbTopologyStar3 className="icon-color" />
                Your Name
              </p>
 
              <input
                type="text"
                name="groupName"
                class="your"
                placeholder="Enter your name"
              />
            </div>
            <div>
              <p className="nae">
                <TbTopologyStar3 className="icon-color" />
                Email
              </p>
 
              <input
                type="text"
                name="groupName"
                class="your"
                placeholder="Enter your email"
              />
            </div>
            <div>
              <p className="nae">
                <TbTopologyStar3 className="icon-color" />
                What is the call about?
              </p>
 
              <input type="text" name="groupName" class="your" placeholder="" />
            </div>
            <div>
              <p className="nae">Phone number</p>
 
              <input
                type="text"
                name="groupName"
                class="your"
                placeholder="+91"
              />
            </div>
 
            {/* <div>
              <p className="na">Discount Code</p>
 
              <input
                type="text"
                name="groupName"
                class="yo"
                placeholder="Enter discount code"
              /> */}
 
            {/* <div className="who">
                <p>
                  <input
                    type="text"
                    name="groupName"
                    class="whol"
                    placeholder=" Apply"
                  />
                </p>
              </div>
            </div> */}
 
            {/* <div className="iv">
              <p className="noo">Get session recording</p>
              <p className="nooo">
                $10 | Session recording to help <br></br> you retain your
                learnings
              </p>
              <p className="rem">Remove</p>
            </div> */}
 
            <div className="ivvv">
            <div>
              <p className="noo">You may also like</p>
              <button className="nn-hide" onClick={toggleHidden}>
                {isHidden ? 'Hide' : 'Show'}
              </button>
              </div>
              <div>
                {isHidden && (
                  <div className="ivi">
                    <p className="nooii">Resume Review</p>
                    <p className="noooi">Video Call | 30 mins |<strike>$59</strike></p>
                    <p className="roi">Add</p>
                  </div>
                )}
 
                {isHidden && (
                  <div>
                    <p className="nooii-1">Questions about Getting a job </p>
                    <p className="noooi-1">Text Query|<strike>$3</strike></p>
                    <p className="roi-1">Add</p>
                  </div>
                )}
              </div>
            </div>
            <div className="last">
              <p className="five"><strike className="tik">$59</strike>$49</p>
              <div className="paid">
                <p className="pay">Confirm and Pay</p>
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};
 
export default Cards;