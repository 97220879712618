import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Carousel.css";

// import arrowLeft from "./assets/arrow-left.svg";
// import arrowRight from "./assets/arrow-right.svg";
import { FaAnglesLeft } from "react-icons/fa6";
import { FaAnglesRight } from "react-icons/fa6";

function Carousel({ slides }) {
  return (
    <div className="carou-img">
    <Swiper
      modules={[EffectCoverflow, Navigation, Pagination]}
      navigation={{
        prevEl: ".button-prev",
        nextEl: ".button-next",
      }}
      pagination={{
        clickable: true,
      }}
      speed={1000}
      slidesPerView={"auto"}
      centeredSlides
      effect={"coverflow"}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
    >
      {slides.map((slide, index) => (
        <Link to={'/home'}>
        <SwiperSlide key={index} className="slide-inner">
          <img src={slide} alt="" />
        </SwiperSlide>
        </Link>
      ))}
      <div className="button-prev">
        {/* <img src={arrowLeft} alt="Left" /> */}
        <FaAnglesLeft/>
      </div>
      <div className="button-next">
        {/* <img src={arrowRight} alt="Right" /> */}
        <FaAnglesRight/>
      </div>
    </Swiper>
    </div>
  );
}

export default Carousel;
