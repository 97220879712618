import React, { useState } from "react";
import "./Resume2.css";
import Img1 from "./assets/2-1.png";
import Img2 from "./assets/2-2.png";
import Img3 from "./assets/2-3.png";
import Img7 from "./assets/2-7.png";
import Img8 from "./assets/2-8.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Portfolioform4 = () => {
  const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    titleDescp: "",
    image: "",
    experienceDescp: "",
    projectDescp: "",
    skills: "",
    consultationDescp: "",
    address: "",
    mobile: "",
    signUpDescp: "",
    email: "",
  });

  const handleImageChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // const originalFilename = file.name; // Get the original filename
      uploadImage(file, name);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const uploadImage = async (file, name) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      // formData.append('filename', filename);
      formData.append("userId", userId);

      const response = await axios.post(
        "https://api.booking.ashishphotography.co.in/port/uploadImage",
        formData
      );
      const { filename } = response.data; // Extract the filename from the response
      console.log(filename);
      console.log("Image uploaded successfully!");
      setFormData((prevData) => ({ ...prevData, [name]: filename }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const [step, setStep] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleContinue = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // You can submit the form data here or perform any other action
    // console.log(formData);

    const response = await fetch("https://api.booking.ashishphotography.co.in/api/portfolio4", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        formData,
        userId,
      }),
    });

    if (response.ok) {
      console.log("Data saved successfully.");
      // Reset form data after successful submission
      setFormData({
        title: "",
        titleDescp: "",
        image: "",
        experienceDescp: "",
        projectDescp: "",
        skills: "",
        consultationDescp: "",
        address: "",
        mobile: "",
        signUpDescp: "",
        email: "",
      });
      navigate("/pages");
    } else {
      console.error("Error saving data.");
    }
  };

  const getImageForStep = () => {
    switch (step) {
      case 1:
        return <img src={Img1} alt="Step 1" />;
      case 2:
        return <img src={Img2} alt="Step 2" />;
      case 3:
        return <img src={Img3} alt="Step 4" />;
      case 4:
        return <img src={Img7} alt="Step 8" />;
      case 5:
        return <img src={Img8} alt="Step 9" />;
      default:
        return null;
    }
  };

  return (
    <div className="multi-step-form-container">
      {getImageForStep()}
      <form onSubmit={handleSubmit} className="form">
        {step === 1 && (
          <div className="form-step">
            <label>
              2 Main Title:
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </label>
            <br />
            <label>
              3. paragraph:
              <input
                type="text"
                name="titleDescp"
                value={formData.titleDescp}
                onChange={handleChange}
                required
                style={{
                  padding: "25px",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  fontSize: "14px",
                  marginTop: "5px",
                }}
              />
            </label>
            <br />
            <label>
              4 Upload Image1
              <input type="file" name="image" onChange={handleImageChange} />
            </label>
            <br />
            {/* 5. <button type="submit">CONTACT US </button>
            <br /> */}
            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}

        {step === 2 && (
          <div className="form-step">
            <label>
              Experience Page <br />
              <br />
              8.Experience Descprition
              <textarea
                required
                name="experienceDescp"
                value={formData.experienceDescp}
                onChange={handleChange}
              ></textarea>
            </label>
            <br />
            <br />
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}

        {step === 3 && (
          <div className="form-step">
            <p>Projects </p>
            <br />
            <label>
              10.Project Name & Description:
              <input
                type="text"
                required
                name="projectDescp"
                value={formData.projectDescp}
                onChange={handleChange}
                style={{
                  padding: "25px",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  fontSize: "14px",
                  marginTop: "5px",
                }}
              />
            </label>
            <br />
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}
        {step === 4 && (
          <div className="form-step">
            <br />
            SKILLS PAGE <br />
            <br />
            23. Skills
            <input
              type="text"
              required
              name="skills"
              value={formData.skills}
              onChange={handleChange}
            />
            <br />
            <br />
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}

        {step === 5 && (
          <div className="form-step">
            <br />
            Consultant page <br />
            30.Consultation Description
            <input
              type="text"
              name="consultationDescp"
              value={formData.consultationDescp}
              onChange={handleChange}
              required
            />
            <br />
            31.Address
            <input
              type="text"
              required
              name="address"
              value={formData.address}
              onChange={handleChange}
            />{" "}
            <br />
            32.Phone number
            <input
              type="tel"
              required
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
            <br />
            33. sign me up descprition
            <input
              type="text"
              name="signUpDescp"
              required
              value={formData.signUpDescp}
              onChange={handleChange}
            />{" "}
            <br />
            <label>
            35. email
            <input required
              type="text"
              
              name="email"
              value={formData.email}
              onChange={handleChange}
            /></label>
            <br />
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="button" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default Portfolioform4;
