import React, { useState, useEffect } from "react";
import "./AdminBooking.css";
import Verticalbar from "./verticalbar";
import Page from "./Page";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import CompletedBookings from "./Completed";

const AdminBooking = () => {
  const [tab, setTab] = useState("Webinarsupcoming");
  const [UpcomingData, setUpcomingData] = useState([]);
  const [CompletedData, setCompletedData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);

  useEffect(() => {
    if (tab === "Webinarsupcoming") {
      fetchUpcomingData();
    } else if (tab === "Completed") {
      fetchCompletedData();
    }
  }, [tab]);

  const fetchUpcomingData = () => {
    fetch("https://api.booking.ashishphotography.co.in/api/upcoming")
      .then((response) => response.json())
      .then((data) => {
        setUpcomingData(data);
      })
      .catch((error) => console.error("Error fetching upcoming data:", error));
  };

  const fetchCompletedData = () => {
    fetch("https://api.booking.ashishphotography.co.in/api/completed")
      .then((response) => response.json())
      .then((data) => {
        setCompletedData(data);
      })
      .catch((error) => console.error("Error fetching completed data:", error));
  };

  const filteredData = (tab === "Webinarsupcoming" ? UpcomingData : CompletedData).filter((item) =>
    item.display_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.startDate.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.endDate.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.meetingId.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.roomUrl.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="d-flex">
      <Verticalbar />
      <div className="service-page" style={{ width: "100%", borderCollapse: "collapse" }}>
        <div className="header-container">
          <h4>BOOKINGS</h4>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {/* <FaSearch className="search-icon" /> */}
          </div>
        </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey={tab}>
          <Row>
            <Col sm={12}>
              <Nav variant="pills" className="flex-row  Coloring">
                <Nav.Item>
                  <Nav.Link
                    eventKey="Webinarsupcoming"
                    onSelect={() => setTab("Webinarsupcoming")}
                  >
                    Upcoming
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="Completed"
                    onSelect={() => setTab("Completed")}
                  >
                    Completed
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane className="tab-content" eventKey="Webinarsupcoming">
                  <div className="table-wrapper">
                    <div className="table-container">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Hostname</th>
                            <th>Start date</th>
                            <th>End date</th>
                            <th>Meeting id</th>
                            <th>User Name</th>
                            <th>User Mail</th>
                            <th>Room url</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.length > 0 ? (
                            filteredData.map((item) => (
                              <tr key={item.id}>
                                <td>{item.display_name}</td>
                                <td>{item.startDate}</td>
                                <td>{item.endDate}</td>
                                <td>{item.meetingId}</td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.roomUrl}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7" style={{ textAlign: "center" }}>
                                No results found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane className="tab-content" eventKey="Completed">
                  <CompletedBookings searchQuery={searchQuery} rowsPerPage={Infinity} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default AdminBooking;
