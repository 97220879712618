import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Verticalbar from "./verticalbar";
import './Admin.css';

import Graph1 from './Graph';
import Piechart from './Piechart';
import Page from "./Page";
import NotificationPopup from './NotificationPopup';
import { Dropdown } from 'react-bootstrap';
 
const AdminCreate = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [dateFilter, setDateFilter] = useState('All');
 
  useEffect(() => {
    fetchData();
  }, [currentPage, rowsPerPage, searchQuery, dateFilter]);
 
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.booking.ashishphotography.co.in/api/admin'); // Update URL as per your backend API endpoint
      let filteredData = response.data
        .filter(user => user.username.toLowerCase().includes(searchQuery.toLowerCase()));
 
      if (dateFilter !== 'All') {
        filteredData = filteredData.filter(user => filterByDate(user.created_at));
      }
 
      setTotalPages(Math.ceil(filteredData.length / rowsPerPage));
      const paginatedData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
      setUserData(paginatedData);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
 
  const filterByDate = (date) => {
    const now = new Date();
    const userDate = new Date(date);
    const timeDiff = now - userDate;
    const dayDiff = timeDiff / (1000 * 3600 * 24);
 
    if (dateFilter === '1 Week' && dayDiff <= 7) return true;
    if (dateFilter === '2 Weeks' && dayDiff <= 14) return true;
    if (dateFilter === '3 Weeks' && dayDiff <= 21) return true;
    return false;
  };
 
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
 
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
 
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
 
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
 
  const renderTableRows = () => {
    return userData.map(user => (
      <tr key={user.id}>
        <td>{user.username}</td>
        <td>{user.email}</td>
        <td>{user.created_at}</td>
      </tr>
    ));
  };
 
  return (
    <div>
      <div className='d-flex'>
        <Verticalbar />
        <div className='dashbord-main'>
          <div className='dashbord-section-1'>
            <h2>Super Admin</h2>
            <div className='dashbord-section-1-top'>
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
              <div className='notifications'>
                <NotificationPopup />
              </div>
            </div>
          </div>
          <div className='filter-section'>
            <h3>New Creators:</h3>
            <Dropdown onSelect={(eventKey) => setDateFilter(eventKey)}>
              <Dropdown.Toggle id="dropdown-basic" className='custom-dropdown-toggle'>
                Filter by Week: {dateFilter}
              </Dropdown.Toggle>
              <Dropdown.Menu className='custom-dropdown-menu'>
                <Dropdown.Item eventKey="All" className='custom-dropdown-item'>All</Dropdown.Item>
                <Dropdown.Item eventKey="1 Week" className='custom-dropdown-item'>1 Week</Dropdown.Item>
                <Dropdown.Item eventKey="2 Weeks" className='custom-dropdown-item'>2 Weeks</Dropdown.Item>
                <Dropdown.Item eventKey="3 Weeks" className='custom-dropdown-item'>3 Weeks</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='dashbord-section-2'>
            <table className='table' style={{ backgroundColor: 'black', color: 'white' }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Registered date</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr><td colSpan="3">Loading...</td></tr>
                ) : error ? (
                  <tr><td colSpan="3">Error: {error}</td></tr>
                ) : userData.length === 0 ? (
                  <tr><td colSpan="3">No data available</td></tr>
                ) : renderTableRows()}
              </tbody>
            </table>
          </div>
          <Page
            className="Pagi"
            currentPage={currentPage}
            totalPages={totalPages}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            rowsPerPage={rowsPerPage}
            handleRowsPerPageChange={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
          />
          <div className='dashbord-section-2-graphs'>
            <div>
              <Piechart />
            </div>
            <Graph1 />
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default AdminCreate;