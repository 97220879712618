import React, { useState, useEffect } from "react";
import "./AdminBooking.css";
import Verticalbar from "./verticalbar";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

const Creators = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate("/login");
          // localStorage.clear();
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);

  const [fullData, setFullData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setDisplayData(fullData);
  }, [fullData]);

  const fetchData = () => {
    fetch("https://api.booking.ashishphotography.co.in/api/getcreators")
      .then((response) => response.json())
      .then((data) => {
        setFullData(data);
      })
      .catch((error) => console.log(error.message));
  };

  const handleChange = (event) => {
    const inputValue = (event.target.value || "").toLowerCase(); // Ensure inputValue is not null
    setSearchQuery(inputValue);
    const filteredData = fullData.filter(
      (item) =>
        (item.username && item.username.toLowerCase().includes(inputValue)) ||
        (item.first_name &&
          item.first_name.toLowerCase().includes(inputValue)) ||
        (item.last_name && item.last_name.toLowerCase().includes(inputValue)) ||
        (item.category && item.category.toLowerCase().includes(inputValue)) ||
        (item.intro && item.intro.toLowerCase().includes(inputValue)) ||
        (item.page_link && item.page_link.toLowerCase().includes(inputValue))
    );
    setDisplayData(filteredData);
  };

  return (
    <div className="d-flex" style={{ width: "100%" }}>
      {isLoggedIn ? (
        <>
          <Verticalbar />
          <div
            className="service-page"
            style={{ width: "100%", borderCollapse: "collapse" }}
          >
            <div className="header-container">
              <h4>CREATOR</h4>
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="table-wrapper">
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Category</th>
                      <th>Intro</th>
                      <th>Link</th>
                      {/* <th>Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {displayData.map((item) => (
                      <tr key={item.id}>
                        <td>{item.username}</td>
                        <td>{item.first_name}</td>
                        <td>{item.last_name}</td>
                        <td>{item.category}</td>
                        <td>{item.intro}</td>
                        <td>
                          <a
                            href={item.page_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.page_link}
                          </a>
                        </td>
                        {/* <td>
                         
                          <DeleteIcon style={{ cursor: "pointer" }} />
                          <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => handleEdit(item.id)}
                          />
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default Creators;

