import React, { useState, useEffect } from "react";
import VerticalBar from "./verticalbar";
import "./AdminProfile.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
const Profile = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    fullName: "",
    phoneNumber: "",
    email: "",
    image: "",
  });
  const [employees, setEmployees] = useState([]);

  const [logoutStatus, setLogoutStatus] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate("/login");
          // localStorage.clear();
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = () => {
    fetch("https://api.booking.ashishphotography.co.in/api/Profile")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch employees");
        }
        return response.json();
      })
      .then((data) => {
        setEmployees(data);
        if (data.length > 0) {
          const firstEmployee = data[0];
          setFormData({
            firstName: firstEmployee.firstName,
            lastName: firstEmployee.lastName,
            fullName: `${firstEmployee.firstName} ${firstEmployee.lastName}`,
            phoneNumber: firstEmployee.phoneNumber,
            email: firstEmployee.email,

            image: firstEmployee.image,
          });
        }
      })
      .catch((error) =>
        console.error("Error fetching employees:", error.message)
      );
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  // const handleLogout = () => {
  //   // Implement logout functionality here
  // };

  const handleSave = () => {
    fetch(`http://localhost3002/api/Profile/update/${employees[0].admin_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update profile");
        }
        console.log("Profile updated successfully");
        fetchEmployees();
      })
      .catch((error) =>
        console.error("Error updating profile:", error.message)
      );
  };
  const [uploadImage, setUplaodImage] = useState();

  const handleImageUpload = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("photo", uploadImage);

      await axios.put(
        `https://api.booking.ashishphotography.co.in/updateadminImage/${employees[0].admin_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Image updated successfully");
      // fetchData();
    } catch (error) {
      console.error("Error updating image:", error);
    }
  };
  return (
    <div className="profile-details-container">
      <div className="profile-container">
        <VerticalBar />
        <div className="profile-content">
          <h4 className="profile-title">Profile:</h4>

          <div className="profile-image-container">
            <div className="selected-image">
              {/* {selectedImage && (
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Selected"
                  style={{ width: '100%', height: 'auto' }}
                />
              )} */}
              <img
                src={`
                  https://api.booking.ashishphotography.co.in/uploads/${formData.image}`}
                alt="Selected"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="image-upload">
              <label htmlFor="imageUpload">Choose an image:</label>
              <input
                type="file"
                id="imageUpload"
                accept="image/*"
                name="photo"
                onChange={(e) => setUplaodImage(e.target.files[0])}
              />
              <button onClick={handleImageUpload}>Add image</button>
            </div>
          </div>

          <div className="form-container">
            <div className="name-fields">
              <div className="form-group">
                <label htmlFor="firstName">First Name:</label>
                <input
                  type="text"
                  id="fullName-super"
                  name="firstName"
                  placeholder="Enter your first name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name:</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter your last name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <label htmlFor="adminfullName">Full Name:</label>
            <input
              type="text"
              id="adminfullName"
              name="fullName"
              placeholder="Enter your full name"
              value={formData.fullName}
              onChange={handleInputChange}
            />
            <label htmlFor="phoneNumber">Phone Number:</label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Enter your phone number"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>

          <button className="save-button" onClick={handleSave}>
            Save
          </button>

          {/* <button className="logout-button" onClick={handleLogout}>
            Logout
          </button> */}
        </div>
      </div>

      <div className="user-details-image">
        <img
          src="https://dy9k9gipgfk4q.cloudfront.net/upload/blog/original/16287698941992848192-.jpeg"
          alt="User details"
        />
        <p>
          Explore Your profile and discover a life crafted with intention and
          joy
        </p>
      </div>
    </div>
  );
};

export default Profile;
