import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./verticalbar.css";
import { FaBars, FaHome } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import VerticalbarMenu from "./verticalbarMenu"; // Corrected import
import { LuPhoneCall } from "react-icons/lu";
import { GrBusinessService } from "react-icons/gr";
import { MdOutlinePayment } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { FaCircleUser } from "react-icons/fa6";
import { BsPersonSquare } from "react-icons/bs";
import {  AiOutlineFieldTime } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { HiCurrencyRupee } from "react-icons/hi";

const routes = [
  {
    path: "/admin",
    name: "Home(Dashboard)",
    icon: <FaHome />,
  },
  {
    path: "/AdminBooking",
    name: "Bookings",
    icon: <LuPhoneCall />,
  },
  {
    path: "/AdminManagement",
    name: "User Management",
    icon: <BsPersonSquare />,
  },

  {
    path: "/adminservices",
    name: "Services",
    icon: <GrBusinessService />,
  },
 
  {
    path: "/NewUsers",
    name: "Creators",
    icon: <MdOutlinePayment />,
  },
  {
    path: "/adminprofile",
    name: "Profile",
    icon: <FaCircleUser />,
  },
  // {
  //   path: "/AdminPortfolio",
  //   name: "Portfolio",
  //   icon: <AiOutlineDeploymentUnit />,
  // },
  {
    path: "/TransactionHistory",
    name: "TransactionHistory",
    icon: <HiCurrencyRupee />,
  },
  {
    path: "/Loghistory",
    name: "loghistory",
    icon: <AiOutlineFieldTime />,
  },
];

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [logoutStatus, setLogoutStatus] = useState("");

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      Axios.get("https://api.booking.ashishphotography.co.in/logout", { withCredentials: true }).then(
        (response) => {
          if (response.data.loggedOut) {
            setLogoutStatus("Logged out successfully");
            navigate("/");
          } else {
            setLogoutStatus("Logout failed");
          }
        }
      );
    }
  };

  return (
    <>
      <div className="main-bar">
        <motion.div
          animate={{
            width: isOpen ? "200px" : "45px",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  <h3 className="super-admin-Title">Super Admin</h3>
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars">
              {isOpen ? (
                <ImCross style={{ cursor: "pointer" }} onClick={toggle} />
              ) : (
                <FaBars style={{ cursor: "pointer" }} onClick={toggle} />
              )}
            </div>
          </div>

          <section className="routes">
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <VerticalbarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                    key={index}
                  />
                );
              }

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="m-active"
                  style={{ textDecoration: "none" }}
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
          <NavLink
            onClick={handleLogout}
            className="link"
            activeClassName="m-active"
            style={{ textDecoration: "none" }}
          >
            <div className="icon">
              <BiLogOut />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="link_text"
                >
                  Logout
                </motion.div>
              )}
            </AnimatePresence>
          </NavLink>
        </motion.div>

        <main className={isOpen ? "open" : "closed"}>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
