import React, { useState } from "react";
import {  useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Creatorreviews = () => {
  const { id } = useParams();
  const c_id = id;

  const storedUserId = localStorage.getItem("userId");
  const [userId, setUserId] = useState(
    storedUserId ? parseInt(storedUserId) : null
  );

  const [review, setReview] = useState("");

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const handleSubmitReview = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://api.booking.ashishphotography.co.in/creators/saveReview", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          c_id: c_id,
          review: review,
        }),
      });

      if (response.ok) {
        // Review saved successfully
        console.log("Review saved successfully");
        setReview("");
        toast.success("Review saved successfully");
        // You can redirect or perform any other action here
      } else {
        // Error saving review
        console.error("Failed to save review");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <h3 className="mb-4 text-center">Review</h3>
      <form
        onSubmit={handleSubmitReview}
        className="d-flex flex-column justify-content-center align-items-center mt-5"
        style={{ width: "100%" }}
      >
        <textarea
          required
          cols="12"
          rows="6"
          style={{ width: "75%" }}
          value={review}
          onChange={handleReviewChange}
        ></textarea>
        <button type="submit" className="btn btn-dark m-4">
          Submit
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Creatorreviews;
