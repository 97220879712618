import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
import "./Appointment.css";
import { Link, useParams,useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import img from './assets/yatayati.jpg';
import { v4 as uuidv4 } from 'uuid';


const useStyles = makeStyles({
  card: {
    marginBottom: "10px",
  },
});

export const Appointment = () => {
  const classes = useStyles();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [duration, setDuration] = useState("");
  const [datetime, setDatetime] = useState("");
  const [message, setMessage] = useState("");


  const location = useLocation();
  const { serviceId } = location.state;

  console.log(serviceId, "serviceid");

  const { c_id } = useParams();
  // console.log(c_id);

  const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;

  const navigate = useNavigate();

  useEffect(() => {
    axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          // setIsLoggedIn(true);
        } else {
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);


  const [service, setService] = useState(null);
// console.log(service.price)
const [price,setPrice] = useState('');
// console.log(price*100)


//fetch service adn price
  useEffect(() => {
    if(serviceId){
      const fetchService = async () => {
          try {
              const response = await axios.get(`https://api.booking.ashishphotography.co.in/api/serviceprice/${serviceId}`);
              setService(response.data);
              setPrice(response.data.price)
          } catch (error) {
              console.error('Error fetching the service:', error);
          }
      };

      fetchService();
    }
  }, [serviceId]);


  const popupClass = message.includes("successfully") ? "success" : "error";

  //id based on c_id
  const [id, setId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await fetch(`https://api.booking.ashishphotography.co.in/getuserId/${c_id}`);
        const data = await response.json();
        if (response.ok) {
          setId(data.id);
        } else {
          setError(data.error);
        }
      } catch (error) {
        console.error("Error fetching user ID:", error);
        setError("Failed to fetch user ID");
      }
    };

    fetchUserId();
  }, [c_id]);
  

  // console.log(id);

  ///Slots code//////
  const [timeslotsavailableitly, settimeslotsavailableitly] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDateString, setSelectedDateString] = useState("");
  const [disabledDates, setDisabledDates] = useState([]);
  const [bookedTimeSlots, setBookedTimeSlots] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [time, setTime] = useState("");
  const [selectedDaySlots, setSelectedDaySlots] = useState([]);
  const [disabledSlots, setDisabledSlots] = useState([]);
  const [allSlotsFilledForSelectedDate, setAllSlotsFilledForSelectedDate] =
    useState(false);

  const isDateDisabled = (date) =>
    disabledDates.includes(date) ||
    (allSlotsFilledForSelectedDate && date === selectedDateString);

  const formattedMinDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const handleTimeButtonClick = (selectedTime) => {
    setSelectedSlot(selectedTime);

    const selectedDateTime = `${selectedDateString} ${selectedTime}`;
    // console.log(selectedTime,"selectedTime")
    const splitTime = selectedTime.split(" - ");
    const selectedTimeString = splitTime[0];

    // Split the time range string into start and end times
    const [startTime, endTime] = selectedTime.split(" - ");

    // Parse the time strings into Date objects
    const startDate = new Date(`01/01/2000 ${startTime}`);
    const endDate = new Date(`01/01/2000 ${endTime}`);

    // Calculate the difference in minutes
    const durationInMinutes = (endDate - startDate) / (1000 * 60);

    console.log("Duration in minutes:", durationInMinutes);
    setDuration(durationInMinutes);

    const [time, meridiem] = selectedTimeString.split(" ");
    const [hours, minutes] = time.split(":").map(Number);

    // Parsing the date string
    const [year, month, day] = selectedDateString.split("-").map(Number);

    // Adjusting hours if it's PM
    const adjustedHours = (hours % 12) + (meridiem === "PM" ? 12 : 0);

    // Creating a new Date object
    const selectedDate = new Date(year, month - 1, day, adjustedHours, minutes);

    // Formatting the date to the desired format
    const formattedDate =
      selectedDate.getFullYear() +
      "-" +
      String(selectedDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(selectedDate.getDate()).padStart(2, "0") +
      "T" +
      String(selectedDate.getHours()).padStart(2, "0") +
      ":" +
      String(selectedDate.getMinutes()).padStart(2, "0");

    // console.log(formattedDate);
    setDatetime(formattedDate);

    if (
      disabledDates.includes(selectedDateString) ||
      bookedTimeSlots.includes(selectedDateTime)
    ) {
      setErrorMessage(
        "This time slot is already booked. Please choose another time."
      );
      return;
    }

    setTime(selectedTime);
    setErrorMessage("");

    const updatedBookedTimeSlots = [...bookedTimeSlots, selectedDateTime];
    setBookedTimeSlots(updatedBookedTimeSlots);

    // Disable the selected slot
    const updatedSlots = selectedDaySlots.map((slot) => {
      const slotTime = `${selectedDateString} ${slot.value}`;
      if (slotTime === selectedDateTime) {
        return {
          ...slot,
          isDisabled: true,
        };
      }
      return slot;
    });

    setSelectedDaySlots(updatedSlots);

    // Check if all slots for the selected date are booked
    const isAllSlotsForSelectedDateFilled = updatedSlots.every(
      (slot) => slot.isDisabled
    );

    // If all slots for the selected date are filled, disable the date
    if (
      isAllSlotsForSelectedDateFilled &&
      !disabledDates.includes(selectedDateString)
    ) {
      setDisabledDates((prevDisabledDates) => [
        ...prevDisabledDates,
        selectedDateString,
      ]);
    }

    setDisabledSlots([...disabledSlots, selectedTime]);
  };

  const availableSlotsForSelectedDay = selectedDaySlots.map((slot) => {
    const slotTime = `${slot.value}`;
    const isDisabled =
      disabledSlots.includes(slotTime) ||
      bookedTimeSlots.includes(`${selectedDateString} ${slotTime}`);

    return {
      value: slotTime,
      label: slotTime,
      isDisabled: isDisabled,
    };
  });

  const handleDateChange = async (event) => {
    const selectedDate = event.target.value;
    setSelectedDateString(selectedDate);
    setTime("");
    setErrorMessage("");

    try {
      const response = await axios.get(
        `https://api.booking.ashishphotography.co.in/slots?date=${selectedDate}&id=${id}&c_id=${c_id}`
      );

      // console.log("Response Data from Backend:", response.data);

      if (
        Array.isArray(response.data.availableSlots) &&
        response.data.availableSlots.length > 0
      ) {
        const availableSlots = response.data.availableSlots.map((slot) => ({
          value: slot,
          label: slot,
          isDisabled:
            disabledSlots.includes(`${selectedDate} ${slot}`) ||
            bookedTimeSlots.includes(`${selectedDate} ${slot}`),
        }));
        setSelectedDaySlots(availableSlots);
        settimeslotsavailableitly(true);
      } else {
        setSelectedDaySlots([]);
        settimeslotsavailableitly(false);
        setErrorMessage("No available slots for the selected date.");
      }
    } catch (error) {
      console.error(
        "Error fetching available slots for the selected date:",
        error
      );
      setErrorMessage("Error fetching available slots. Please try again.");
      setSelectedDaySlots([]);
    }
  };


//handleSubmit
const handleSubmit = async (event) => {
  event.preventDefault();

  if (selectedSlot===null){
    toast.error("plaese select a slot")
    return;
  }

  const amount = price*100;
  const currency = "INR";
  // const receiptId = "qwsaq1";
  const receiptId = uuidv4();

  try {
    // Start payment process
    const paymentResponse = await fetch("https://api.booking.ashishphotography.co.in/order", {
      method: "POST",
      body: JSON.stringify({
        amount,
        currency,
        receipt: receiptId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const order = await paymentResponse.json();
    console.log("Order:", order);

    var options = {
      key: "rzp_live_2EWSvUx7aMmKbv", // Enter the Key ID generated from the Dashboard
      amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency,
      name: "Yatayati", // your business name
      description: "Test Transaction",
      image: img,
      order_id: order.id, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        const body = {
          razorpay_order_id: order.id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
          receiptId,
          serviceId, // provide your service id
          amount,
          c_id,
          id: userId,
        };

        try {
          const validateRes = await fetch("https://api.booking.ashishphotography.co.in/order/validate", {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          });
          const jsonRes = await validateRes.json();
          console.log("Validation Response:", jsonRes);

          if (jsonRes.success) {
            // Proceed with booking the meeting
            const meetingResponse = await axios.post("https://api.booking.ashishphotography.co.in/meetings", {
              endDate: datetime,
              duration,
              name,
              email,
              c_id,
              userId,
              selectedSlot,
              selectedDateString,
            });

            if (meetingResponse.data.success) {
              setMessage("Meeting booked successfully");
              // fetchMeetings(); // Refresh meetings list after booking
              setname("");
              setemail("");
              setDatetime("");
              setDuration("");
              setSelectedDateString("");
              setMessage("");
              setSelectedSlot(null);
              setSelectedDaySlots([]);
              toast.success("Meeting booked successfully", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => {
                  // Execute window.history.back() after toast is closed
                 
                  navigate(-1)
                },
              });
            } else {
              setMessage(meetingResponse.data.message || "Error booking meeting");
            }
          } else {
            setMessage("Transaction validation failed");
          }
        } catch (error) {
          console.error("Error validating transaction:", error);
          setMessage("Error validating transaction");
        }
      },
      prefill: {
        name: name, // your customer's name
        email: email,
        contact: "7794094644", // Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
      setMessage("Transaction failed");
    });
    rzp1.open();
  } catch (error) {
    console.error("Error initiating payment:", error.message);
    setMessage("Error initiating payment");
  }
};


  return (
    <div className="bg-appoint">
      <ToastContainer />
      <div className={`popup ${popupClass} text-center bg-white mb-3`}>
        {message}
      </div>
     

      <div className=" d-flex flex-row justify-content-center align-items-center">
        <form className="unique-form" onSubmit={handleSubmit}>
          <h3 className="text-center">Book Appointment</h3>
          <div className="unique-flex-column">
            <label>Name</label>
          </div>
          <div className="unique-inputForm">
            <input
              type="text"
              className="unique-input"
              value={name}
              required
              onChange={(e) => setname(e.target.value)}
              placeholder="Enter your Name"
            />
          </div>
          <div className="unique-flex-column">
            <label>Email</label>
          </div>
          <div className="unique-inputForm">
            <input
              type="email"
              className="unique-input"
              value={email}
              required
              onChange={(e) => setemail(e.target.value)}
              placeholder="Enter your Email"
            />
          </div>

          <div className="unique-flex-column">
            <label>Date & Time</label>
          </div>
          <div className="unique-inputForm">
            <input
              className="unique-input"
              required
              type="date"
              id="datepicker"
              placeholder="Date"
              value={selectedDateString}
              onChange={handleDateChange}
              min={formattedMinDate()}
              style={{
                color: selectedDateString ? "black" : "gray",
              }}
              disabled={
                disabledDates.length > 0 && isDateDisabled(selectedDateString)
              }
            />
          </div>

          <div>
            {timeslotsavailableitly
              ? selectedDaySlots.length > 0 && (
                  <div>
                    {availableSlotsForSelectedDay.map((slot) => (
                      <>
                        <div
                          key={slot.value}
                          className={`time-slot-button ${
                            selectedSlot === slot.value ? "selected" : ""
                          }`}
                          onClick={() => handleTimeButtonClick(slot.value)}
                          disabled={slot.isDisabled}
                        >
                          {slot.label}
                        </div>
                      </>
                    ))}
                  </div>
                )
              : <div className="text-center text-danger mt-2"><b>Slots not Available</b></div>}
          </div>

          <button type="submit" className="unique-button-submit">
            Book
          </button>
        </form>
      </div>
    </div>
  );
};
