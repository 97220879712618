import React from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./Services.css";
const Page = ({
  currentPage,
  totalPages,
  handlePrevPage,
  handleNextPage,
  rowsPerPage,
  handleRowsPerPageChange,
}) => {
  return (
    <div className="pagi">
      <Button
        disabled={currentPage === 1}
        onClick={handlePrevPage}
        style={{ marginRight: "8px", color: "black" }}
      >
        <NavigateBeforeIcon />
      </Button>
      <span style={{ margin: "0 8px", fontSize: "14px", color: "black" }}>
        Page {currentPage} of {totalPages}
      </span>
      <Button
        disabled={currentPage === totalPages}
        onClick={handleNextPage}
        style={{ marginRight: "8px", color: "black" }}
      >
        <NavigateNextIcon />
      </Button>
      <TextField
        select
        value={rowsPerPage}
        onChange={handleRowsPerPageChange}
        variant="outlined"
        style={{
          width: "3.5rem",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <MenuItem value={4}>4</MenuItem>
        <MenuItem value={8}>8</MenuItem>
        <MenuItem value={12}>12</MenuItem>
        {/* Add more options as needed */}
      </TextField>
    </div>
  );
};

export default Page;
