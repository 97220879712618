import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto"; // Required for auto-registering charts
import "./Graph.css";
const Graph = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(graphData);
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const fetchYears = async () => {
    try {
      const response = await fetch("https://api.booking.ashishphotography.co.in/api/years");
      if (!response.ok) {
        throw new Error("Failed to fetch years");
      }
      const data = await response.json();
      setYears(data);
      if (data.length > 0) {
        setSelectedYear(data[0]); // Set the default year to the first value
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching years:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchGraphData = async () => {
      if (!selectedYear) return;
      try {
        setLoading(true);
        const response = await fetch(`
https://api.booking.ashishphotography.co.in/members/monthly?year=${selectedYear}`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setGraphData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching graph data:", error);
        setLoading(false);
      }
    };
    fetchGraphData();
  }, [selectedYear]);

  useEffect(() => {
    fetchYears();
  }, []);

  const chartData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: `Values for ${selectedYear}`,
        data: graphData.map((d) => d.member_count),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  // const chartOptions = {
  //   maintainAspectRatio: false,
  //   scales: {
  //     x: { beginAtZero: true },
  //     y: { beginAtZero: true }
  //   }
  // };
  const maxYValue = Math.max(...graphData.map((d) => d.member_count));

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: false,
        ticks: {
          stepSize: 1,

          min: 1,

          max: Math.max(2, maxYValue + 1),
        },
      },
    },
  };

  return (
    <div>
      <div className="graph-container">
        <select id="startYear" value={selectedYear} onChange={handleYearChange}>
          <option value="">Select Year</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <Bar data={chartData} options={chartOptions} />
        )}
      </div>
    </div>
  );
};

export default Graph;
