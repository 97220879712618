import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import './Webinar.css';

import Tab from "react-bootstrap/Tab";

import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { Button, Modal } from "react-bootstrap";

import SideBar from "./Sidebar";

import { BiCalendarHeart } from "react-icons/bi";


import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
// import SplitButton from 'react-bootstrap/SplitButton';
import { ButtonGroup } from 'react-bootstrap';
import { TiInfo } from "react-icons/ti";
import { BiArrowBack } from "react-icons/bi"
import { PiTelevisionFill } from "react-icons/pi";
const Services = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get('https://api.booking.ashishphotography.co.in/home', { withCredentials: true })
      .then((response) => {
        if (!response.data.loggedIn) {
          navigate('/login');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/login');
      });
  }, [navigate]);

  const [selectedDays, setSelectedDays] = useState([]);
 
  const handleDayClick = (day) => {
    // Toggle the selection of the clicked day
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((selectedDay) => selectedDay !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  return (
    <div className="d-flex">
      <SideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
      <div className="pageContainer webinar-service">
        <div className="p-30">
          <div className='title'>
          <Link to={"/services"}>
          <div id="backarrow">
                <BiArrowBack />
        </div>
        </Link>
        <h1 className="page-title">
          
          What are you creating today?</h1>
          </div>
         
          <div className="tabs-container">
            <Tab.Container id="left-tabs-example" defaultActiveKey="call-1">
              <Tab.Content className="serviceDivContainer">
                <div className="flexJustify">
                  <Nav className="flex-row nav-tabs boxs">
                    <Nav.Item>
                      <Nav.Link eventKey="call-1">
                        <div className='call-Box'>
                          <BiCalendarHeart id='Heart' />
                          <h5>1:1 Call</h5>
                          <p>Conduct 1:1 video sessions</p>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Webinar-1">
                        <div className='call-Box'>
                        <PiTelevisionFill   id='Heart' />
                          <h5>Webinar</h5>
                          <p>Host one time or recurring group sessions</p>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <Tab.Pane eventKey="call-1">
                  <div className="flexJustify call-1">
                    <form>
                      <div className="call-service">
                        <label>Title</label>
                        <input type="text" aria-required="true" placeholder="Name of Service" />
                      </div>
                      <div className="call-Duration">
                        <label>Duration (mins)</label>
                        <input type="number"  aria-required="true" placeholder="" min="0" max="60" />
                      </div>
                      <div className="call-Amount">
                        <label>Amount (₹)</label>
                        <div className='money'>
                          <div className='Rupe-sym'>
                            ₹
                          </div>
                          <input type="number"  aria-required="true" placeholder="" min="0" />
                        </div>
                      </div>
                      <Link to={"/editservice"}>
                    <Button id='Customize'>Next: Customize</Button>
                    </Link>
                    </form>
                  </div>
                 
                </Tab.Pane>

                <Tab.Pane eventKey="Webinar-1" >
                  <div className="flexJustify Webinar-1" >
                    <form>
                      <div className="call-service">
                        <label>Title</label>
                        <input type="text"  aria-required="true" placeholder="Name of Service" />
                      </div>
                      <div className="call-Duration">
                        <label>Duration (mins)</label>
                        <input type="number"  aria-required="true" placeholder="" min="0" max="60" />
                      </div>
                      <div className="webinar-session">
                        <label>Session</label>
                        <div className='Session'>
                          <div className='Session-1'>
                            <input type="date"  aria-required="true" placeholder="Select Date" />
                          </div>
                          <div className='Session-2'>
                            <input type="time" aria-required="true" placeholder="select Time" />
                          </div>
                        </div>
                      </div>
                      <Button id='multiple-sessions' onClick={() => setShowModal(true)}>
                        +Create multiple sessions
                      </Button>
                      <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        centered
                      >
                         <div className='webinar-popup'>
                        <Modal.Header closeButton>
                          <Modal.Title>Create Multiple Sessions</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                         
                        <div className="webinar-First-session">
                        <label>First Session</label>
                        <div className='First-Session'>
                          <div className='First-Session-1'>
                            <input type="date"  aria-required="true" placeholder="Select Date" />
                          </div>
                          <div className='First-Session-2'>
                            <input type="time"  aria-required="true" placeholder="select Time" />
                          </div>
                        </div>
                      </div>
                      <div className="week">
                      <label>Repeats Every </label>
                      <div className='weekdays'>
                     
                            {['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'].map((day) => (
                        <button
                            key={day}
                            className={`day-button ${selectedDays.includes(day) ? 'selected' : ''}`}
                            onClick={() => handleDayClick(day)}
                        >
                        {day}
                        </button>
                         ))}
                      </div>
                      </div>
                      <div className="Session-3">
                        <label>Total Sessions</label>
                        <input type="number"  aria-required="true" placeholder=""min="0"   />
                      </div>
                      <div className="Total-Sessions">
                        <label>What are we calling the sessions?  <TiInfo /></label>
                        <div className="mb-2">
                                {['up'].map(
                                  (direction) => (
                                    <DropdownButton
                                      as={ButtonGroup}
                                      key={direction}
                                      className='dropup'
                                      id={`dropdown-button-drop-${direction}`}
                                      drop={direction}
                                      variant="white"
                                      title={"Session name" + ' '}
                                    >
                                      <Dropdown.Item eventKey="1">Bootcamp</Dropdown.Item>
                                      <Dropdown.Item eventKey="2">Cohort</Dropdown.Item>
                                      <Dropdown.Item eventKey="3">webinar</Dropdown.Item>
                                      <Dropdown.Item eventKey="4">Weekly hangout</Dropdown.Item>
                                      <Dropdown.Item eventKey="5">Masterclass</Dropdown.Item>
                                    </DropdownButton>
                                  ),
                                )}
                              </div>
                                    <button className='Create-add'>Add sessions</button>
                                    <hr class="hr-line"></hr>
                                    <p>If you wanted to create just a single session click <a href='#here'>here</a></p>
                      </div>
                    
                        </Modal.Body>
                        </div>
                      </Modal>
                      <div className="call-Amount">
                        <label>Amount (₹)</label>
                        <div className='money'>
                          <div className='Rupe-sym'>
                            ₹
                          </div>
                          <input type="number"  aria-required="true" placeholder="" min="0" />
                        </div>
                      </div>
                      <div className='hosting'>
                        <label>Hosting Options</label>
                        <button>
                          +Add meetings client
                        </button>
                      </div>
                      <Button id='Customize-1'>Next: Customize</Button>
                    </form>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
