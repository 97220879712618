import React, { useState, useEffect } from "react";
import "./Cards.css";
import { Link, useParams } from "react-router-dom";
import { SlCalender } from "react-icons/sl";
import Axios from "axios";
import Aos from "aos";
import Comment from './Comment'

function App() {
  const [cardDetails, setCardDetails] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const { id } = useParams();
  const c_id = id;

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  useEffect(() => {
    if (id){
    let apiUrl = `https://api.booking.ashishphotography.co.in/api/servicesbyuserId/${id}`;
    if (selectedServiceType) {
      apiUrl += `?serviceType=${selectedServiceType}`;
    }

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Fetched data:", data); // Log fetched data for debugging
        // Ensure that data is always an array
        if (Array.isArray(data)) {
          setCardDetails(data);
        } else {
          // If data is an object, convert it to an array
          setCardDetails(Object.values(data));
        }
      })
      .catch((error) => console.error("Error fetching card data:", error));
    }
  }, [selectedServiceType, id]);

  const renderColumns = () => {
    const columns = [[], []]; // Array to hold two columns

    // Split card details into two arrays for each column
    cardDetails.forEach((card, index) => {
      columns[index % 2].push(
        <div key={card.id} className="Cards">
          <h1 className="DDD">{card.serviceName}</h1>
          <div className="SubCard">
            <p className="time">{card.timeDuration}</p>
            <Link to="/bookingform">
              <button className="GGG">
                <del className="nu">{card.oldPrice}</del> {card.newPrice}
              </button>
            </Link>
            {/* Add other elements as needed */}
          </div>
        </div>
      );
    });

    // Customize classNames for columns
    const columnClassNames = ["column-1", "column-2"];

    return columns.map((column, index) => (
      <div key={index} className={columnClassNames[index]}>
        {column}
      </div>
    ));
  };
  const [alertMessage, setAlertMessage] = useState({ text: "", variant: "" });
  const [servicesData, setServicesData] = useState([]);

  const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;

  useEffect(() => {
    if(id){
    fetchServices();
  }
  }, [id]);

  const fetchServices = () => {
    Axios.get(`https://api.booking.ashishphotography.co.in/services/${id}`)
      .then((response) => {
        console.log("Response data:", response.data);
        setServicesData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
        setAlertMessage({ text: "Error fetching services", variant: "danger" });
      });
  };

  return (
    <div className="MainCards">
      <div className="kar">
        <h1 className="text-center p-3">Services</h1>
      </div>

      <div className="vinnu-1">
      
          {servicesData.map((service) => {
            let s_id = service.service_id;
            console.log(s_id);
            return (
              <div className="Cards" data-aos="fade-up" key={service.service_id}>
                <h1 className="DDD">{service.serviceName}</h1>
                <h6 className="para">{service.description}</h6>
                <div className="SubCard">
                  <SlCalender className="con" />
                  <div className="ss">
                    <p className="EEE">Duration: {service.duration} mins</p>
                    <p className="FFF">Service Type: {service.serviceType}</p>
                  </div>
                </div>
                {/* <Link to={`/${service.service_id}/${c_id}`}> */}
                <Link to={`/appointment/${c_id}`} state={{ serviceId: service.service_id }}>
                  <button className="GGG">
                    <span>Book Now</span>
                    <span className="price">Price: {service.price}</span>
                  </button>
                </Link>
              </div>
            );
          })}

      </div>
      <div>
        <Comment/>
      </div>
      <div className="columns">{renderColumns()}</div>

      {/* <Link to={`/comment/${id}`}>
        <button className="btn btn-dark p-2 m-3">Comment</button>
      </Link> */}
    </div>
  );
}
export default App;
