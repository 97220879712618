import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './forgetpassword.css';
import { FaLock } from "react-icons/fa";
import { MdMarkEmailRead } from "react-icons/md";
import { FaKeyboard } from "react-icons/fa";
 
export const Forgetpassword = () => {
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [buttonText, setButtonText] = useState('Send Mail');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSendingMail, setIsSendingMail] = useState(false);
  const navigate = useNavigate();
 
  const handleSendMail = async () => {
    try {
      setIsSendingMail(true);
      const response = await fetch('https://api.booking.ashishphotography.co.in/sendOTP', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
 
      const data = await response.json();
      if (response.ok) {
        if (data.success) {
          setShowOTPInput(true);
          setButtonText('Enter OTP');
        } else {
          alert('Error sending OTP. Please try again.');
        }
      } else if (response.status === 400) {
        alert('Email Address Does not exist in Database');
      } else {
        alert('An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsSendingMail(false);
    }
  };
 
  const handleVerifyOTP = async () => {
    try {
      const response = await fetch('https://api.booking.ashishphotography.co.in/verifyOTP', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });
 
      const data = await response.json();
      if (data.success) {
        setShowPasswordFields(true);
        setShowOTPInput(false);
        setButtonText('Reset Password');
      } else {
        alert('OTP not matched. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('Error verifying OTP. Please try again.');
    }
  };
 
  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      alert('New Password and Confirm Password do not match.');
      return;
    }
 
    try {
      const response = await fetch('https://api.booking.ashishphotography.co.in/updatePassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, newPassword }),
      });
 
      const data = await response.json();
      if (data.success) {
        alert('Password updated successfully!');
        navigate('/login');
      } else {
        alert('Error updating password. Please try again.');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      alert('Error updating password. Please try again.');
    }
  };
 
  return (
    <div className='forget'>
      <div className='login__form'>
        <Link to='/'><p>
        <h3>Forget Password</h3></p></Link>
       
        <div className='forgetpass'>
          {!showPasswordFields && (
            <>
              {/* <input
                placeholder='Enter your Email id'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={showOTPInput}
                className="login__input"
              /> */}
               <div className="login__box">
            <input placeholder='Enter your Email id' value={email} onChange={(e) => setEmail(e.target.value)}
                disabled={showOTPInput}required className="login__input" />
            <MdMarkEmailRead />
          </div>
              {showOTPInput && (
                // <input
                //   placeholder='Enter OTP'
                //   value={otp}
                //   onChange={(e) => setOtp(e.target.value)}
                //   disabled={showPasswordFields}
                //   className='login__box'
                // />
                <div className="login__box">
                <input placeholder='Enter OTP' value={otp}  onChange={(e) => setOtp(e.target.value)}
                    disabled={showPasswordFields} required className="login__input" />
                 {/* <FaLock className='otp-icon' /> */}
                 <FaKeyboard />
              </div>
              )}
            </>
          )}
          {showPasswordFields && (
            <>
              {/* <input
                type='password'
                placeholder='New Password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className='login__box'
              /> */}
               <div className="login__box">
                <input placeholder='New Password' type='password' value={newPassword}  onChange={(e) => setNewPassword(e.target.value)}
                    required className="login__input" />
                 <FaLock className='otp-icon' />
                 {/* <FaKeyboard /> */}
              </div>
              {/* <input
                type='password'
                placeholder='Confirm Password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className='login__box'
              /> */}
               <div className="login__box">
                <input placeholder='Confirm Password' type='password'  value={confirmPassword}  onChange={(e) => setConfirmPassword(e.target.value)}
                    required className="login__input" />
                 <FaLock className='otp-icon' />
                 {/* <FaKeyboard /> */}
              </div>
            </>
          )}
          <div className='forget-container'>
            <button
              className='forget-enter'
              onClick={showPasswordFields ? handleResetPassword : (showOTPInput ? handleVerifyOTP : handleSendMail)}
              disabled={isSendingMail}
            >
              {isSendingMail ? 'Sending Mail...' : buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};