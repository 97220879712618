import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import "./Pages.css";
import preview from "./assets/preview.png";
import { Link } from "react-router-dom";
import Aos from "aos";
import Img1 from "./assets/2-1.png";
import Img2 from "./assets/portimg3.png";
import SideBar from "./Sidebar";

const Pages = () => {
  const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState("");
  const [availablePortfolios, setAvailablePortfolios] = useState([]);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          //   setIsLoggedIn(true);
        } else {
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);

  useEffect(() => {
    if (userId) {
      Axios.get(`https://api.booking.ashishphotography.co.in/getPortfolios/${userId}`)
        .then((response) => {
          setAvailablePortfolios(response.data.portfolios);
        })
        .catch((error) => {
          console.error("Error fetching portfolios:", error);
        });
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        try {
          const response = await Axios.get(
            `https://api.booking.ashishphotography.co.in/creators/${userId}`
          ); // Assuming the endpoint for a specific creator
          const data = response.data;
          setSelectedPortfolio(data.portfolio);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [userId]);

  const handlePortfolioChange = (event) => {
    const newPortfolio = event.target.value;
    setSelectedPortfolio(newPortfolio);

    Axios.post("https://api.booking.ashishphotography.co.in/updatedefaultPortfolio", {
      userId,
      portfolio: newPortfolio,
    })
      .then((response) => {
        console.log("Portfolio updated successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error updating portfolio:", error);
      });
  };

  return (
    <div className="d-flex">
      <SideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />

      <div className="pages">
        <h1>Pages</h1>
        <hr style={{ margin: "15px 0px" }} />
        <h3>Website Previews</h3>
        <p>Fill the forms to select the portfolio</p>
        <select value={selectedPortfolio} onChange={handlePortfolioChange}>
          <option value="default portfolio">default portfolio</option>
          {availablePortfolios.includes("portfolio1") && (
            <option value="portfolio1">portfolio1</option>
          )}
          {availablePortfolios.includes("portfolio2") && (
            <option value="portfolio2">portfolio2</option>
          )}
          {availablePortfolios.includes("portfolio3") && (
            <option value="portfolio3">portfolio3</option>
          )}
        </select>
        <div className="preview-container mt-5">
          <div data-aos="fade-up" className="preview-box">
            <img src={preview} className="preview-img" alt="preview" />
            <div className="links">
              <Link
                className="view"
                to={
                  "https://nicepage.com/html-templates/preview/professional-app-developer-5562133?device=desktop"
                }
                target="_blank"
              >
                View
              </Link>
              <Link className="usethis" to={"/main"}>
                Use This
              </Link>
            </div>
          </div>
          <div data-aos="fade-up" className="preview-box">
            <img src={Img2} className="preview-img" alt="preview" />
            <div className="links">
              <Link className="view" to={"/resume"}>
                View
              </Link>
              <Link className="usethis" to={"/preview3"}>
                Use This
              </Link>
            </div>
          </div>
          <div data-aos="fade-up" className="preview-box">
            <img src={Img1} className="preview-img" alt="preview" />
            <div className="links">
              <Link className="view" to={"https://yatayatiportfoliotemplate2.netlify.app/"} target="_blank">
                View 
              </Link>
              <Link className="usethis" to={"/preview4"}>
                Use This
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pages;
