import React, { useState, useEffect } from "react";
import "./AdminService.css";
import Verticalbar from "./verticalbar";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { FaSearch } from "react-icons/fa";
 
const Loghistory = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
 
  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);
 
  const [loginHistory, setLoginHistory] = useState([]);
  const [sortOrder, setSortOrder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredLoginHistory, setFilteredLoginHistory] = useState([]);
 
  const fetchLoginHistory = async (order) => {
    try {
      const response = await Axios.get("https://api.booking.ashishphotography.co.in/api/login_history", {
        params: { sort: order }
      });
      setLoginHistory(response.data);
    } catch (error) {
      console.error("Error fetching login history: ", error);
    }
  };
 
  useEffect(() => {
    fetchLoginHistory(sortOrder);
  }, [sortOrder]);
 
  useEffect(() => {
    // Filter login history based on search query
    const filteredHistory = loginHistory.filter((item) =>
      Object.values(item).some((value) =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredLoginHistory(filteredHistory);
  }, [loginHistory, searchQuery]);
 
  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };
 
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
 
  return (
    <div className="d-flex">
      <Verticalbar />
      <div className="service-page" style={{ width: "100%", borderCollapse: "collapse" }}>
        <div className="header-container">
          <h4>Login History</h4>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <FaSearch className="search-icon" />
          </div>
        </div>
        <div className="duration-sort-container">
          <select onChange={handleSortChange}>
            <option value="">Sort by Duration</option>
            <option value="asc">Low to High</option>
            <option value="desc">High to Low</option>
          </select>
        </div>
        <div className="table-wrapper">
          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Login Time</th>
                  <th>Logout Time</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                {filteredLoginHistory.length > 0 ? (
                  filteredLoginHistory.map((item, index) => (
                    <tr key={index}>
                      <td>{item.username}</td>
                      <td>{item.login_time}</td>
                      <td>{item.logout_time}</td>
                      <td>{item.duration}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No result found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Loghistory;