
import React, { useState, useEffect } from "react";

const CompletedBookings = ({ searchQuery }) => {
  const [completedData, setCompletedData] = useState([]);

  useEffect(() => {
    fetchCompletedData();
  }, []);

  const fetchCompletedData = () => {
    fetch("https://api.booking.ashishphotography.co.in/api/completed")
      .then((response) => response.json())
      .then((data) => {
        setCompletedData(data);
      })
      .catch((error) => console.error("Error fetching completed data:", error));
  };

  const filteredData = completedData.filter((item) =>
  //   item.display_name.toLowerCase().includes(searchQuery.toLowerCase())
  //  ||
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
   
  );

  return (
    <div>
      {filteredData.length === 0 ? (
        <p>No results found.</p>
      ) : (
        <div className="table-wrapper">
          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th>Hostname</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Meeting id</th>
                  <th>User Name</th>
                  <th>Room url</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.display_name}</td>
                    <td>{item.startDate}</td>
                    <td>{item.endDate}</td>
                    <td>{item.meetingId}</td>
                    <td>{item.name}</td>
                    <td>{item.roomUrl}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompletedBookings;

