import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import pendingCOm from "../images//total-earnings.png";
import totearning from "../images/lifetime-earning.png";
import {
  AiOutlineExclamationCircle,
  AiOutlineInbox,
  AiOutlineAppstore,
} from "react-icons/ai";
import Table from "react-bootstrap/Table";
import "./payment.css";
import SideBar from "./Sidebar";

const Payments = () => {
  const [key, setKey] = useState("profile"); // Default active key set to 'profile'

  const renderTooltipBalance = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Earnings after deducting 7% Topmate fees
    </Tooltip>
  );

  const renderTooltipPendingCom = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Amount from your upcoming calls will move to balance once the call gets
      completed
    </Tooltip>
  );

  const renderTooltiplifetime = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      All-time earnings of all your completed calls and queries
    </Tooltip>
  );

  const renderTooltiptotearnings = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Total amount of all the completed calls and priority DM
    </Tooltip>
  );

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          // setIsLoggedIn(true);
        } else {
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/login");
      });
  }, [navigate]);

  const [c_id, setCId] = useState("");

  const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;
  // console.log(userId);

  useEffect(() => {
    axios
      .get(`https://api.booking.ashishphotography.co.in/getCID/${userId}`)
      .then((response) => {
        // Handle success
        const { data } = response;
        const fetchedCId = data.c_id;
        setCId(fetchedCId);
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching c_id:", error);
        // Handle errors here
      });
  }, [userId]);
  // console.log(c_id);

  const [data, setData] = useState([]);
  // console.log(data);

  useEffect(() => {
    if (c_id) {
      const fetchTransactions = async () => {
        try {
          const response = await axios.get(
            `https://api.booking.ashishphotography.co.in/api/transaction/${c_id}`
          );
          setData(response.data);
        } catch (err) {}
      };

      fetchTransactions();
    }
  }, [c_id]);

  const [earnings, setEarnings] = useState(0);
  
  // useEffect(() => {
  //   if (c_id) {
  //     const fetchTotalAmount = async () => {
  //       try {
  //         const response = await axios.get(
  //           `https://api.booking.ashishphotography.co.in/api/total-amount/${c_id}`
  //         );
  //         setEarnings(response.data.totalAmount);
  //       } catch (error) {
  //         console.error("Error fetching total amount:", error);
  //       }
  //     };
  //     fetchTotalAmount();
  //   }
  // }, [c_id]);

  useEffect(() => {
    if (c_id) {
      const fetchTotalAmount = async () => {
        try {
          const response = await axios.get(
            `https://api.booking.ashishphotography.co.in/api/total-amount/${c_id}`
          );
          // Divide the amount by 100 before setting it as earnings
          const earnings = response.data.totalAmount / 100;
          setEarnings(earnings);
        } catch (error) {
          console.error("Error fetching total amount:", error);
        }
      };
      fetchTotalAmount();
    }
  }, [c_id]);
  

  return (
    <div className="d-flex">
      <SideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
      <div className="pageContainer">
        <div className="pageHeader">
          <h1 className="page-title">Payments</h1>
        </div>
        <hr className="hrLine"></hr>
        <div className="paymentContainerDiv">
          <Row>
            {/* <Col sm={4}>
              <div className="dashCardBox color2">
                <img src={pendingCOm} alt="paymentDash" />
                <div className="dFlex">
                  <p>Pending Completion</p>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipPendingCom}
                  >
                    <Button variant="link" size="sm" className="imgBtn"><AiOutlineExclamationCircle /></Button>
                  </OverlayTrigger>
                </div>
                <h4>100</h4>
              </div>
            </Col> */}
            <Col sm={4}>
              <div className="dashCardBox color3">
                <img src={totearning} alt="paymentDash" />
                <div className="dFlex">
                  <p>Lifetime earnings</p>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltiplifetime}
                  >
                    <Button variant="link" size="sm" className="imgBtn">
                      <AiOutlineExclamationCircle />
                    </Button>
                  </OverlayTrigger>
                </div>
                <h4>Rs.{earnings}</h4>
              </div>
            </Col>
          </Row>

          <div className="tabs-container tabsStyle2 mt-5">
            <Tabs
              activeKey={key}
              onSelect={(k) => setKey(k)}
              id="controlled-tab-example"
              className="mb-3"
            >
              <Tab className="bg-white" eventKey="profile" title="Transactions">
                <div className="transacionDetailsTabs mt-4">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 bg-white"
                  >
                    <Tab
                      eventKey="profile"
                      title={
                        <>
                          <AiOutlineAppstore /> Service bookings
                        </>
                      }
                    >
                      <Table>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Service</th>
                            <th>Booked by</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data && data.length > 0 ? (
                            data.map((item) => (
                              <tr key={item.t_id}>
                                {/* <td>{item.Transaction}</td> */}
                                <td>{item.payment_time}</td>
                                <td>{item.serviceName}</td>
                                <td>{item.username}</td>
                                {/* <td>{item.amount}</td> */}
                                <td>Rs. {(item.amount / 100).toFixed(2)}</td>
                              </tr>
                            ))
                          ) : (
                            <tr className="noDataTbl">
                              <td colSpan="7" className="text-center">
                                <AiOutlineInbox />
                                <p>No data</p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
