import React, { useState, useEffect } from "react";
import './Adminlogin.css';
import { Link, useNavigate } from "react-router-dom";
import loginBg from './assets/login-bg.png'; // Corrected import statement
import Axios from 'axios';
import { toast } from 'react-toastify';
import { FaUser, FaLock } from "react-icons/fa";
 
const Adminlogin = () => {

    const [loginusername, setloginusername] = useState('');
    const [loginpassword, setloginpassword] = useState('');
    const navigate = useNavigate();
    const [loginstatus, setloginstatus] = useState('');
    const [token, setToken] = useState(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
 

  useEffect(() => {
    Axios.get("https://api.booking.ashishphotography.co.in/home", { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
          navigate("/");
        } else {
          // navigate("/login");
          // localStorage.clear();
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
        navigate("/adminlogin");
      });
  }, [navigate]);

    const handlelogin = (event) => {
        event.preventDefault();


    if (loginusername !== 'admin') {
        toast.error("Admin with that username does not exist.");
        setloginusername('');
            setloginpassword('');
        return;
      }

        
        const trimmedLoginPassword = loginpassword.trim();
        Axios.post('https://api.booking.ashishphotography.co.in/login', {
            username: loginusername,
            password: trimmedLoginPassword
        }, { withCredentials: true }).then((response) => {
            if (response.data.token) {
                setToken(response.data.token);
                const user = response.data.user;
                console.log(user.role);
                localStorage.setItem('userId', user.id);
                localStorage.setItem('role', user.role);
                if (user) {
                    setloginstatus(`Welcome ${user.username}`);
                    if (user.role === "admin") {
                        navigate(`/admin`);
                    } else {
                        navigate(`/home`);
                    }
                } else {
                    setloginstatus("User data not available in the token.");
                }
            } else {
                setloginstatus(response.data.message);
            }
        }).catch((error) => {
            console.error("Error logging in:", error);
            toast.error("Incorrect username or password.");
        });
    };

    useEffect(() => {
        if (token) {
            Axios.get('https://api.booking.ashishphotography.co.in/login', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((response) => {
                if (response.data.loggedIn === true) {
                    setloginstatus(`Welcome, ${response.data.user.name}`);
                }
            });
        }
    }, [token]);

  return (
    <div className="login-page">
      <img src={loginBg} alt="background" className="login__bg" /> {/* Use the imported image variable */}
 
      <form action="" onSubmit={handlelogin} className="login__form">
        <h1 className="login__title">Admin Login</h1>
 
        <div className="login__inputs">
          <div className="login__box">
            <input type="text" placeholder="Username"  onChange={(e) => setloginusername(e.target.value)} required  className="login__input" />
            <FaUser/>
          </div>
 
          <div className="login__box">
            <input type="password" placeholder="Password" onChange={(e) => setloginpassword(e.target.value)} required className="login__input" />
            <FaLock/>
          </div>
        </div>
 
        <div className="login__check">
        {/* <a href="/SignupPage" className="login__forgot">Sign Up</a> */}
          <Link to='/forgetpassword' style={{cursor:"pointer"}} className="login__forgot">Forgot Password?</Link>
        </div>
 
        <button type="submit" className="login__button">Login</button>
 
        {/* <div className="login__register">
          Don't have an account?  <Link to={'/signup'} style={{cursor:"pointer"}}>Register</Link>
        </div> */}
      </form>
    </div>
  );
};
 
export default Adminlogin;