import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { Login } from "./Components/login";
import { Home } from "./Components/home";
import { Forgetpassword } from "./Components/forgetpassword";
import Creators from "./Components/Creators";
import Scientists from "./Components/Scientist";
import Software from "./Components/Software";
import Landingpage from "./Components/Landingpage";
import MyBookings from "./Components/MyBookings";
import Pages from "./Components/Pages";
import Reviews from "./Components/Reviews";
import Services from "./Components/Services";
import Payments from "./Components/Payments";
import Profile from "./Components/Profile";
import Resumeview from './Components/Resumeview'

import Resumepage from "./Components/Resumepage";
import BookingForm from "./Components/bookingform";
import Card from "./Components/Card";
import Subscribe from "./Components/Subscribe";
import Cards from "./Components/Cards";
import Schedule from "./Schedule";
import Calender from "../src/calender";

import Webinar from "./Components/Webinar";

import Portfolioform1 from "./Components/Portfolioform1";
import Portfolioform2 from "./Components/Portfolioform2";
import Portfolioform3 from "./Components/portfolioform3";
import Portfolioform4 from "./Components/Portfolioform4";
import Portfolio from "./Components/Portfolio";
import Portfolio1 from "./Components/Portfolio1";
import Portfolio2 from "./Components/Portfolio2";
import Portfolio3 from "./Components/Portfolio3";
import Portfolio4 from "./Components/Portfolio4";
import Creatorreviews from "./Components/Creatorreviews";
import Comment from "./Components/Comment";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Appointment } from "./Components/Appointment";

import { MdClose } from "react-icons/md";

import chatimg from './Components/assets/chatbot.jpg';

// super-admin

import Admin from "./Components/SuperAdmin/Admin";
import AdminBooking from "./Components/SuperAdmin/AdminBooking";
import AdminServices from "./Components/SuperAdmin/AdminService";
import AdminProfile from "./Components/SuperAdmin/AdminProfile";
import NewUsers from "./Components/SuperAdmin/NewUsers";
import AdminManagement from "./Components/SuperAdmin/AdminManagement";
import AdminPortfolio from "./Components/SuperAdmin/AdminPortfolio";
import Loghistory from "./Components/SuperAdmin/Loghistory";

import Defaultresume from "./Components/Defaultresume";
import Transactionhistory from "./Components/SuperAdmin/Transactionhistory";
import Loginpage from "./Components/Loginpage";
import SignupForm from "./Components/SignupForm";
import Adminlogin from "./Components/Adminlogin";

function App() {
  const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;
  console.log(userId);
  const [role, setRole] = useState("");
  console.log(role);

  console.log(userId);
  useEffect(() => {
    const fetchData = async () => { 
      try {
        if (userId) {
          const response = await axios.get(
            `https://api.booking.ashishphotography.co.in/user/${userId}`
          ); // Assuming the endpoint for a specific creator
          const data = response.data;
          setRole(data.role);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    renderRoutes();
  });


  const renderRoutes = () => {
    if (role === "admin") {
      return (
        <>
          <Route path="/admin" element={<Admin />} />
          <Route path="/AdminBooking" element={<AdminBooking />} />
          <Route path="/adminservices" element={<AdminServices />} />
          <Route path="/adminprofile" element={<AdminProfile />} />
          <Route path="/NewUsers" element={<NewUsers />} />
          <Route path="/AdminManagement" element={<AdminManagement />} />
          <Route path="/AdminPortfolio" element={<AdminPortfolio />} />
          <Route path="/portfolio1" element={<Portfolio1 />} />
          <Route path="/portfolio2" element={<Portfolio2 />} />
          <Route path="/portfolio3" element={<Portfolio3 />} />
          <Route path="/portfolio4" element={<Portfolio4 />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgetpassword" element={<Forgetpassword />} />
          <Route path="/" element={<Landingpage />} />
          <Route path="/creators" element={<Creators />} />
          <Route path="/scientists" element={<Scientists />} />
          <Route path="/software" element={<Software />} />
          <Route path="/Loghistory" element={<Loghistory />} />
          <Route path="/TransactionHistory" element={<Transactionhistory />} />
        </>
      );
    } else if (role === "user") {
      return (
        <>
          {/* Routes for user */}
          <Route path="/" element={<Landingpage />} />
          <Route path="/creators" element={<Creators />} />
          <Route path="/scientists" element={<Scientists />} />
          <Route path="/software" element={<Software />} />
          <Route path="/creatorreviews/:id" element={<Creatorreviews />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/mybookings" element={<MyBookings />} />
          <Route path="/pages" element={<Pages />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/services" element={<Services />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/webinar" element={<Webinar />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/main" element={<Portfolioform1 />} />
          <Route path="/preview2" element={<Portfolioform2 />} />
          <Route path="/preview3" element={<Portfolioform3 />} />
          <Route path="/preview4" element={<Portfolioform4 />} />

          <Route path="/resume" element={<Resumeview />} />
          <Route path="/resume/:id" element={<Resumepage />} />
          <Route path="/serivcesbyid/:id" element={<Cards />} />
          <Route path="/bookingform" element={<BookingForm />} />
          <Route path="/Card" element={<Card />} />
          <Route path="/Subscribe" element={<Subscribe />} />
          <Route path="/portfolio" element={<Portfolio />} />

          <Route path="/login" element={<Login />} />
          <Route path="/forgetpassword" element={<Forgetpassword />} />
          <Route path="/Schedule" element={<Schedule />} />
          <Route path="/calender" element={<Calender />} />

          <Route
            path="/bookingform/:serviceId/:serviceName"
            component={BookingForm}
          />
          <Route path="/appointment/:c_id" element={<Appointment />} />
          <Route path="/comment/:id" element={<Comment />} />
        </>
      );
    }
  };



  //// chatbot ////

  const initialMessage = { text: 'Hi! Welcome to Creative Thoughts. How can I help you today?', isBot: true };
  const initialSuggestions = [
    { label: 'Service Information', value: 'service information' },
    { label: 'Contact Support', value: 'contact support' },
    { label: 'Payment Issues', value: 'payment issues' },
  ];
  
  const handleBotResponse = (inputText) => {
    let response = '';
    let newSuggestions = [];
  
    switch (inputText.toLowerCase()) {
      case 'hello':
      case 'hi':
        response = 'Hello! How can I assist you today?';
        newSuggestions = initialSuggestions;
        break;
      case 'service information':
        response = 'Sure, which type of service are you interested in?';
        newSuggestions = [
          { label: '1:1 Call', value: '1:1 call' },
          { label: 'Webinar', value: 'webinar' },
        ];
        break;
      case '1:1 call':
        response = 'Great! What specific details do you want to know about 1:1 calls?';
        newSuggestions = [
          { label: 'Price', value: 'price' },
          { label: 'How to Book', value: 'how to book 1:1 call' },
          { label: 'Upcoming 1:1 Calls', value: 'upcoming 1:1 calls' },
          { label: 'Cancellation', value: 'cancellation 1:1 call' },
        ];
        break;
      case 'webinar':
        response = 'Great! What specific details do you want to know about webinars?';
        newSuggestions = [
          { label: 'Price', value: 'price' },
          { label: 'How to Book', value: 'how to book webinar' },
          { label: 'Upcoming Webinars', value: 'upcoming webinars' },
          { label: 'Cancellation', value: 'cancellation webinar' },
        ];
        break;
      case 'price':
        response = 'Our pricing varies based on the creator and the service. Please check individual listings for details.';
        newSuggestions = [
          { label: 'Anything else', value: 'anything else' },
          { label: 'End Chat', value: 'end chat' },
        ];
        break;
      case 'how to book 1:1 call':
        response = 'To book a 1:1 call, go to the creator\'s profile, check the services, select an available time slot, and complete the payment.';
        newSuggestions = [
          { label: 'Anything else', value: 'anything else' },
          { label: 'End Chat', value: 'end chat' },
        ];
        break;
      case 'upcoming 1:1 calls':
        response = 'You can find upcoming 1:1 calls you have booked with a creator in the "My Bookings" section of your dashboard.';
        newSuggestions = [
          { label: 'Anything else', value: 'anything else' },
          { label: 'End Chat', value: 'end chat' },
        ];
        break;
      case 'cancellation 1:1 call':
        response = 'Cancellation policy is not available. However, if the creator cancels your booking, the amount will be reflected in your account within 2-3 business days.';
        newSuggestions = [
          { label: 'Anything else', value: 'anything else' },
          { label: 'End Chat', value: 'end chat' },
        ];
        break;
      case 'how to book webinar':
        response = 'To book a webinar, go to the "Events" section, select the webinar you are interested in, and complete the registration process.';
        newSuggestions = [
          { label: 'Anything else', value: 'anything else' },
          { label: 'End Chat', value: 'end chat' },
        ];
        break;
      case 'upcoming webinars':
        response = 'You can find upcoming webinars you have booked with a creator in the "My Bookings" section of your dashboard.';
        newSuggestions = [
          { label: 'Anything else', value: 'anything else' },
          { label: 'End Chat', value: 'end chat' },
        ];
        break;
      case 'cancellation webinar':
        response = 'Cancellation policy is not available. However, if the creator cancels your booking, the amount will be reflected in your account within 2-3 business days.';
        newSuggestions = [
          { label: 'Anything else', value: 'anything else' },
          { label: 'End Chat', value: 'end chat' },
        ];
        break;
      case 'payment issues':
        response = 'I can help with payment issues. What exactly is the problem?';
        newSuggestions = [
          { label: 'Failed Booking', value: 'failed booking' },
          { label: 'Refund Status', value: 'refund status' },
        ];
        break;
      case 'failed booking':
        response = 'Don\'t worry about your money. If the service booking failed, the amount will reflect in your account within 2 working days. For more queries, contact us at creativethoughts4545@gmail.com.';
        newSuggestions = [
          { label: 'Anything else', value: 'anything else' },
          { label: 'End Chat', value: 'end chat' },
        ];
        break;
      case 'refund status':
        response = 'Refunds are usually processed within 2-5 business days. If you have any further questions, please write to us at creativethoughts4545@gmail.com.';
        newSuggestions = [
          { label: 'Anything else', value: 'anything else' },
          { label: 'End Chat', value: 'end chat' },
        ];
        break;
      case 'contact support':
        response = 'For any queries, please write to us at creativethoughts4545@gmail.com.';
        newSuggestions = [
          { label: 'Anything else', value: 'anything else' },
          { label: 'End Chat', value: 'end chat' },
        ];
        break;
      case 'end chat':
        response = 'Thank you for chatting with us. Have a great day!';
        newSuggestions = [{ label: 'New Chat', value: 'new chat' }];
        break;
      case 'new chat':
        setMessages([initialMessage]);
        newSuggestions = initialSuggestions;
        setShowSuggestions(true);
        response = initialMessage.text;
        break;
      default:
        response = "I apologize; that was not clear to me. Please use the suggestions.";
        newSuggestions = initialSuggestions;
        break;
    }
  
    setTimeout(() => {
      setMessages((prevMessages) => [...prevMessages, { text: response, isBot: true }]);
      setDynamicSuggestions(newSuggestions);
      setShowSuggestions(true);
      setIsTyping(false);
    }, 1000);
  };
  
  

  const [messages, setMessages] = useState([initialMessage]);
  const [input, setInput] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [dynamicSuggestions, setDynamicSuggestions] = useState([]);
  const [chatbotVisible, setChatbotVisible] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);


  useEffect(() => {
    setDynamicSuggestions(initialSuggestions);
  }, []);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim() === '') return;

    setMessages((prevMessages) => [...prevMessages, { text: input, isBot: false }]);
    setInput('');

    setIsTyping(true);
    setShowSuggestions(false); // Hide suggestions immediately after submitting
    setTimeout(() => {
      handleBotResponse(input.toLowerCase());
    }, 1000);
  };


  const handleSuggestionClick = (suggestion) => {
    setMessages((prevMessages) => [...prevMessages, { text: suggestion, isBot: false }]);
    setIsTyping(true);
    setShowSuggestions(false); // Hide suggestions immediately after clicking
    setTimeout(() => {
      handleBotResponse(suggestion.toLowerCase());
    }, 1000);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setChatbotVisible(true);
    }, 5000);

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);




  return (
    <div className="app-container">
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="/creators" element={<Creators />} />
          <Route path="/scientists" element={<Scientists />} />
          <Route path="/software" element={<Software />} />
          <Route path="/login" element={<Loginpage />} />
          <Route path="/adminlogin" element={<Adminlogin />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/forgetpassword" element={<Forgetpassword />} />
          <Route path="/:username/:id" element={<Defaultresume />} />
          <Route path="/serivcesbyid/:id" element={<Cards />} />
          <Route path="/bookingform" element={<BookingForm />} />
          {renderRoutes()}
        </Routes>
      </BrowserRouter>
      {!chatbotVisible && (
        <button className="toggle-chatbot" onClick={() => setChatbotVisible(true)}>
          <img src={chatimg} alt="chatimg" className="chatimg"/>
        </button>
      )}
      <div className={`support-chat-container ${chatbotVisible ? 'visible' : 'hidden'}`}>
        <div className="support-chat">
        <div className="d-flex align-item-center justify-content-between pt-2 pb-2 bg-primary">
        {/* <p className="text-white pt-1" style={{paddingLeft:'6px'}}><b>Chatbot</b></p> */}
        <img style={{marginLeft:'6px'}} src={chatimg} alt="chatimg" className="chatimg"/>
        <p style={{fontSize:'20px'}} onClick={() => setChatbotVisible(!chatbotVisible)} className='closebtn'><MdClose/></p>

        </div>
          
          <div className="chat-messages" ref={chatContainerRef}>
            {messages.map((msg, index) => (
              <div key={index} className={msg.isBot ? 'bot-message' : 'user-message'}>
                {msg.text}
              </div>
            ))}
            {isTyping && <div className="bot-message typing">Bot is typing...</div>}
          </div>
          {showSuggestions && (
            <div className="suggestion-buttons">
              {dynamicSuggestions.map((button, index) => (
                <button key={index} onClick={() => handleSuggestionClick(button.value)}>
                  {button.label}
                </button>
              ))}
            </div>
          )}
          <form onSubmit={handleSubmit} className="chat-input">
            <input
              type="text"
              value={input}
              onChange={handleInputChange}
              placeholder="Type your message..."
            />
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default App;
