import React, { useState, useEffect } from "react";
import { Tab, Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./profile.css";
import SideBar from "./Sidebar";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import axios from "axios";
 
const Profile = () => {
  const storedUserId = localStorage.getItem("userId");
  const userId = storedUserId ? parseInt(storedUserId) : null;

  const [uname, setuName] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.booking.ashishphotography.co.in/user/${userId}`
        ); // Assuming the endpoint for a specific creator
        const data = response.data;
        setuName(data.username);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);
 
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingMobile, setIsEditingMobile] = useState(false);

  // const [showPassword, setShowPassword] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
 
  const [logoutStatus, setLogoutStatus] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
 
  useEffect(() => {
    Axios.get('https://api.booking.ashishphotography.co.in/home', { withCredentials: true })
      .then((response) => {
        if (response.data.loggedIn) {
          setIsLoggedIn(true);
        } else {
          navigate('/login');
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        navigate('/login');
      });
  }, [navigate]);
 
  const handleLogout = () => {
    Axios.get("https://api.booking.ashishphotography.co.in/logout", { withCredentials: true }).then(
      (response) => {
        if (response.data.loggedOut) {
          setLogoutStatus("Logged out successfully");
          navigate("/");
        } else {
          setLogoutStatus("Logout failed");
        }
      }
    );
  };
 
//   const handleImageChange = (event) => {
//     setImage(event.target.files[0]);
//     const file = event.target.files[0];
 
//     if (file) {
//       const reader = new FileReader();
 
//       reader.onloadend = () => {
//         setImage(reader.result);
//       };
 
//       reader.readAsDataURL(file);
//     }
//   };
 
  const handleEditEmailBox = () => {
    setIsEditing(true);
  };
 
  const handleCancelEdit = () => {
    setIsEditing(false);
  };
  const handleEditMobileBox = () => {
    setIsEditingMobile(true);
  };
 
  const handleCancelEdit01 = () => {
    setIsEditingMobile(false);
  };
 

  // const [page_link, setPage_link] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [display_name, setDisplay_name] = useState("");
  const [intro, setIntro] = useState("");
  const [about, setAbout] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
 
  const [creatorData, setCreatorData] = useState({
    page_link: "",
    first_name: "",
    last_name: "",
    display_name: "",
    category:"",
    about: "",
    intro: "",
  });
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCreatorData((prevData) => ({ ...prevData, [name]: value }));
  };
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.booking.ashishphotography.co.in/creators/${userId}`
        ); // Assuming the endpoint for a specific creator
        const data = response.data
        // setPage_link(data.page_link);
        setFirst_name(data.first_name);
        setLast_name(data.last_name);
        setDisplay_name(data.display_name);
        setCategory(data.category);
        setIntro(data.intro);
        setAbout(data.about);
        // setImage(data.image)
        setCreatorData(response.data);
       
 
       
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
 
    fetchData();
    navigate("/profile");
  }, [userId]);


    const fetchimgData = async () => {
      try {
        const response = await axios.get(
          `https://api.booking.ashishphotography.co.in/creatorsimage/${userId}`
        ); // Assuming the endpoint for a specific creator
        const data = response.data
        setImage(data.image)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    useEffect(() => {
    fetchimgData(); 
  });
 
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState();
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.booking.ashishphotography.co.in/user/${userId}`
        ); // Assuming the endpoint for a specific creator
        const data = response.data
       setMobile(data.phonenumber)
       setEmail(data.email)
 
       
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
 
    fetchData();
  }, [userId]);
 
 
 
 
  const handleSave = async (event) => {
    event.preventDefault();
 
    try {
      const response = await axios.put(
        `https://api.booking.ashishphotography.co.in/update/${userId}`,
        {
          first_name,
          last_name,
          display_name,
          category,
          intro,
          about,
        }
      );
 
      console.log(response.data);
      // Handle success or display a message to the user
    } catch (error) {
      console.error("Error updating creator data:", error);
      // Handle error or display an error message to the user
    }
  };
 
const [uploadImage, setUplaodImage] = useState();
 
  const handleImageUpload = async (e) =>{
    e.preventDefault();
    try {
        const formData = new FormData();
     
        formData.append('photo', uploadImage);
 
        await axios.put(`https://api.booking.ashishphotography.co.in/updateImage/${userId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setUplaodImage(null)
        // console.log('Data updated successfully');
        fetchimgData();
      } catch (error) {
        console.error('Error updating data:', error);
      }
  }
 
  const [phonenumber, setPhonenumber] = useState();
  const [inputEmail, setInputEmail] = useState();
  //Edit Mobile Number
  const handleSaveNumber = async () => {
    setIsEditingMobile(false);
    try {
        const response = await axios.put(
          `https://api.booking.ashishphotography.co.in/updateMobile/${userId}`,
          {
            phonenumber
          },
          setMobile(phonenumber)
        );
 
        console.log(response.data);
        // Handle success or display a message to the user
      } catch (error) {
        console.error("Error updating creator data:", error);
        // Handle error or display an error message to the user
      }
   
  }
 
    const handleSaveEmail = async () => {
    setIsEditing(false);
    try {
        const response = await axios.put(
          `https://api.booking.ashishphotography.co.in/updateEmail/${userId}`,
          {
            inputEmail
          },
          setEmail(inputEmail)
        );
 
        console.log(response.data);
        // Handle success or display a message to the user
      } catch (error) {
        console.error("Error updating creator data:", error);
        // Handle error or display an error message to the user
      }
  }
 
  const handleDropdownChange = (event) => {
    setCategory(event.target.value);
  };
 
  const dropdownValues = [
    'Data',
    'Creators',
    'Software',
   
  ];
 
  return (
    <div className="d-flex">
      <SideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
 
      <div className="pageContainer-4">
        <div className="p-30">
          <h1 className="page-title">Profile</h1>
          <div className="tabs-container">
            <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
              <div className="flexJustify">
                <Nav className="flex-row nav-tabs">
                  <Nav.Item className="m-2">
                    <Nav.Link eventKey="profile">Profile</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item className='m-2'>
                                    <Nav.Link eventKey="settings">Settings</Nav.Link>
                                </Nav.Item> */}
                  <Nav.Item className="m-2">
                    <Nav.Link eventKey="account">Account</Nav.Link>
                  </Nav.Item>
                </Nav>
                {/* <div className="btnDiv">
                  <Link>
                    <Button variant="dark" className="btnRight">
                      Save & change
                    </Button>
                  </Link>
                </div> */}
              </div>
              <Tab.Content className="profilecontent">
                <Tab.Pane eventKey="profile">
                  <div className="profile-main">
                  <div className="profile-top" style={{ display: "flex", }}>
                      <div  className="profile-photo">
                        {image ? (
                          <img
                            src={`https://api.booking.ashishphotography.co.in/uploads/${image}`}
                            alt="Uploaded"
                            style={{ width: "140px", height: "170px" }}
                          />
                        ) : (
                          <p>No image uploaded</p>
                        )}
                      </div>
                      <div className="photo-dic">
                        <label htmlFor="fileInput">Upload Image:</label>
                        <input
                          type="file"
                          id="fileInput"
                          name="photo"
                          onChange={(e)=>setUplaodImage(e.target.files[0])}
                        />
 
                       <button onClick={handleImageUpload}>Add Image</button>
 
                      </div>
                  </div>
                    <div className="frm">
                      <form onSubmit={handleSave}>
                      {/* <form> */}
                     
                        <label className="top">Your Creative Thoughts Username</label>{" "}
                        <br />
                        <div className="matebox">
                          <div className="sidebox">Username</div>
                          <input
                            name="page_link"
                            value={uname}
                            type="text"
                            id="username"
                            aria-required="true"
                            placeholder="Username" readOnly
                          />
                          {/* <BsArrowUpRightSquareFill id="upmark" /> */}
                        </div>
                        <div className="namebox">
                          <div className="edit1">
                            <label className="firstName">First Name </label>{" "}
                            <br />
                            <input
                              type="text"
                              name="first_name"
                              value={first_name}
                              onChange={(e) => setFirst_name(e.target.value)}
                              id="firstNamebox"
                              placeholder="First Name"
                            />
                          </div>
                          <div className="edit">
                            <label className="lastName">Last Name </label>{" "}
                            <br />
                            <input
                              type="text"
                              name="last_name"
                              value={last_name}
                              onChange={(e) => setLast_name(e.target.value)}
                              id="lastNamebox"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                        <label className="fullName">Display Name</label> <br />
                        <input
                          type="text"
                          name="display_name"
                          value={display_name}
                          onChange={(e) => setDisplay_name(e.target.value)}
                          id="fullName"
                          placeholder="Display Name"
                        />
                        <br />
                        <div className="Category">
                        <label htmlFor="categoryDropdown">Select a Category : </label>
                        <select
                            id="categoryDropdown"
                            value={category}
                            onChange={handleDropdownChange}
                        >
                            <option value="">Select...</option>
                            {dropdownValues.map((value) => (
                            <option key={value} value={value}>
                                {value}
                            </option>
                            ))}
                        </select>
                        </div>
                        <br/>
                        <label className="additional">
                          {" "}
                          Your Creative Thoughts intro (Required)
                        </label>{" "}
                        <br />
                        <input
                          type="text"
                          id="additionalInfo"
                          name="intro"
                          value={intro}
                          onChange={(e) => setIntro(e.target.value)}
                          placeholder="This is the first thing people will see"
                        />
                        <br />
                        <label className="messagebox">
                          {" "}
                          About yourself
                        </label>{" "}
                        <br />
                        <input
                          type="message"
                          id="addmessage"
                          name="about"
                          value={about}
                          onChange={(e) => setAbout(e.target.value)}
                          placeholder="Tell us more about yourself, your interests and your experience"
                        />
                        <hr />
                        <input style={{width:"100px", marginLeft:"20px"}} type="submit" value="Save" className="btn btn-dark save-2" />
                      </form>
                    </div>
                    <div></div>
                  </div>
                </Tab.Pane>
 
                <Tab.Pane eventKey="account">
                  <div className="bg">
                    <div className="setting">
                      <div className="your-1">
                        <h3>Your details</h3>
                      </div>
                      <div className="address-1">
                        <div className="email">
                          <p>Email address</p>
                          {!isEditing ? (
                                <span>{email}</span>
                          ) : (
                            <>
                              <input
                                type="email"
                                id="inputbox"
                                placeholder="Enter your email"
                                onChange={(e)=> setInputEmail(e.target.value)}
                              />{" "}
                              <br />
                              <button
                                onClick={handleSaveEmail}
                                id="cancel-save"
                              >
                                Save
                              </button>
                            </>
                          )}
                        </div>
                        <div className="edit-1">
                          {!isEditing ? (
                            <a
                              href="#edit"
                              onClick={handleEditEmailBox}
                              style={{ fontWeight: "700" }}
                            >
                              Edit
                            </a>
                          ) : (
                            <a
                              href="#cancel"
                              onClick={handleCancelEdit}
                              id="edit-cancel-num"
                              style={{ marginLeft: "-50px", fontWeight: "700" }}
                            >
                              Cancel
                            </a>
                          )}
                        </div>
                      </div>
 
                      <hr style={{ marginTop: isEditing ? "115px" : "40px" }} />
                      <br />
 
                      <div className="address-2">
                        <div className="mobile-1">
                          <p>Mobile number</p>
                          {!isEditingMobile ? (
                            <>
                            <br/>
                            <p>{mobile}</p>
                            </>
                          ) : (
                            <>
                              <div className="countries">
                                {/* <span></span> */}
                                <input
                                  type="tel"
                                  id="numberbox"
                                  placeholder="Enter your mobile number"
                                  onChange={(e)=> setPhonenumber(e.target.value)}
                               />
                              </div>
                              <button
                                onClick={handleSaveNumber}
                                id="save-cancel"
                              >
                                Save
                              </button>
                            </>
                          )}
                        </div>
 
                        <div className="edit-2">
                          {!isEditingMobile ? (
                            <a
                              href="#edit"
                              onClick={handleEditMobileBox}
                              style={{ fontWeight: "700" }}
                            >
                              Edit
                            </a>
                          ) : (
                            <a
                              href="#cancel"
                              onClick={handleCancelEdit01}
                              id="edit-cancel"
                              style={{ fontWeight: "700" }}
                            >
                              Cancel
                            </a>
                          )}
                        </div>
                      </div>
 
                      <hr
                        style={{
                          marginTop: isEditingMobile ? "120px" : "40px",
                        }}
                      />
                      <br />
 
                      <button onClick={handleLogout} id="logout">
                        Logout
                      </button>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Profile;