import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa';
import { AiOutlineLike, AiOutlineDislike } from 'react-icons/ai';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import img from './assets/yatayati.jpg';
import { AiFillLike } from "react-icons/ai";
import { AiFillDislike } from "react-icons/ai";
import Rating from 'react-rating-stars-component';
import { Link, useParams } from "react-router-dom";
import './Comment.css';
import SideBar from "./Sidebar";
 
 
const Product = () => {
    const storedUserId = localStorage.getItem("userId");
    const userId = storedUserId ? parseInt(storedUserId) : null;
    const { id } = useParams();
    const c_id = id;
    const [reviews, setReviews] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
 
    const [openDialog, setOpenDialog] = useState(false);
    const [likedReviews, setLikedReviews] = useState(new Set());
    const [dislikedReviews, setDislikedReviews] = useState(new Set());  
    const [formData, setFormData] = useState({
        name: '',
        username: '',
        stars: 0,
        comment: '',
        userId: userId,
        c_id: c_id,
 
    });
    const [starCounts, setStarCounts] = useState({
        5: 0,
        4: 0,
        3: 0,
        2: 0,
        1: 0,
    });
 
    const navigate = useNavigate();
    const location = useLocation();
    const [role, setRole] = useState('');
    const [username, setUsername] = useState('');
    const [overallRating, setOverallRating] = useState(0);
 
   
 
    // useEffect(() => {
    //     const token = Cookies.get('token');
    //     const { state } = location;
 
    //     if (!token || !state || !state.username || !state.role) {
    //         navigate('/');
    //     } else {
    //         setUsername(state.username);
    //         setRole(state.role);
    //         console.log(state.username, state.role); // Log username and role
    //     }
    // }, [location, navigate]);
 
    // const handleLogout = () => {
    //     Cookies.remove('token');
    //     navigate('/');
    // };
 
 
 
useEffect(() => {
    if (c_id) {
        fetchReviews();
        // fetchLikes();
    }
});
 
 
// const fetchLikes = () => {
//     axios.get('https://api.booking.ashishphotography.co.in/likes', { params: { username } })
//         .then(response => {
//             const { likes, dislikes } = response.data;
//             setLikedReviews(new Set(likes));
//             setDislikedReviews(new Set(dislikes));
//             // Force re-render to update UI
//             setReviews(prevReviews => [...prevReviews]);
//         })
//         .catch(error => {
//             console.error('Error fetching likes:', error);
//         });
// };
 
 
 
   
    const fetchReviews = () => {
  
        axios.get(`https://api.booking.ashishphotography.co.in/reviews/${c_id}`)
            .then(response => {
                setReviews(response.data);
               
            })
            .catch(error => {
                console.error('Error fetching reviews:', error);
            });
   
    };
 
    // const [rev, setRev] = useState([]);
    // console.log(rev)
   
 
    //  useEffect(() => {
    //     axios.get(`https://api.booking.ashishphotography.co.in/reviews/${c_id}`)
    //     .then(response => {
    //         setRev(response.data);
           
    //     })
    //     .catch(error => {
    //         console.error('Error fetching reviews:', error);
    //     });
    //    }, [c_id]);
   
 
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
 
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
 
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };
 
    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('https://api.booking.ashishphotography.co.in/createreviews', formData)
            .then(response => {
                console.log('Review submitted successfully:', response.data);
                setReviews([...reviews, response.data]);
                setFormData({
                    name: '',
                    username: '',
                    stars: 0,
                    comment: '',
                   
                });
                handleCloseDialog();
            })
            .catch(error => {
                console.error('Error submitting review:', error);
            });
    };
 
    // const handleLike = (reviewId, isLike) => {
    //     const isLiked = likedReviews.has(reviewId);
    //     const isDisliked = dislikedReviews.has(reviewId);
   
    //     if (isLike) {
    //         if (isLiked) {
    //             // If already liked, remove the like
    //             axios.post('https://api.booking.ashishphotography.co.in/like', { reviewId, liked: 0, username })
    //                 .then(response => {
    //                     setLikedReviews(new Set([...likedReviews].filter(id => id !== reviewId)));
    //                     setReviews(prevReviews =>
    //                         prevReviews.map(review =>
    //                             review.id === reviewId ? { ...review, likeCount: review.likeCount - 1 } : review
    //                         )
    //                     );
    //                 })
    //                 .catch(error => {
    //                     console.error('Error removing like:', error);
    //                 });
    //         } else if (isDisliked) {
    //             // If disliked, update the like to 1 and remove the dislike
    //             axios.post('https://api.booking.ashishphotography.co.in/like', { reviewId, liked: 1, username })
    //                 .then(response => {
    //                     setLikedReviews(new Set([...likedReviews, reviewId]));
    //                     setDislikedReviews(new Set([...dislikedReviews].filter(id => id !== reviewId)));
    //                     setReviews(prevReviews =>
    //                         prevReviews.map(review =>
    //                             review.id === reviewId ? { ...review, likeCount: review.likeCount + 1, dislikeCount: review.dislikeCount - 1 } : review
    //                         )
    //                     );
    //                 })
    //                 .catch(error => {
    //                     console.error('Error updating like:', error);
    //                 });
    //         } else {
    //             // If not liked or disliked, add the like
    //             axios.post('https://api.booking.ashishphotography.co.in/like', { reviewId, liked: 1, username })
    //                 .then(response => {
    //                     setLikedReviews(new Set([...likedReviews, reviewId]));
    //                     setReviews(prevReviews =>
    //                         prevReviews.map(review =>
    //                             review.id === reviewId ? { ...review, likeCount: review.likeCount + 1 } : review
    //                         )
    //                     );
    //                 })
    //                 .catch(error => {
    //                     console.error('Error adding like:', error);
    //                 });
    //         }
    //     } else {
    //         if (isDisliked) {
    //             // If already disliked, remove the dislike
    //             axios.post('https://api.booking.ashishphotography.co.in/like', { reviewId, liked: 0, username })
    //                 .then(response => {
    //                     setDislikedReviews(new Set([...dislikedReviews].filter(id => id !== reviewId)));
    //                     setReviews(prevReviews =>
    //                         prevReviews.map(review =>
    //                             review.id === reviewId ? { ...review, dislikeCount: review.dislikeCount - 1 } : review
    //                         )
    //                     );
    //                 })
    //                 .catch(error => {
    //                     console.error('Error removing dislike:', error);
    //                 });
    //         } else if (isLiked) {
    //             // If liked, update the dislike to 1 and remove the like
    //             axios.post('https://api.booking.ashishphotography.co.in/like', { reviewId, liked: 0, username })
    //                 .then(response => {
    //                     setDislikedReviews(new Set([...dislikedReviews, reviewId]));
    //                     setLikedReviews(new Set([...likedReviews].filter(id => id !== reviewId)));
    //                     setReviews(prevReviews =>
    //                         prevReviews.map(review =>
    //                             review.id === reviewId ? { ...review, dislikeCount: review.dislikeCount + 1, likeCount: review.likeCount - 1 } : review
    //                         )
    //                     );
    //                 })
    //                 .catch(error => {
    //                     console.error('Error updating dislike:', error);
    //                 });
    //         } else {
    //             // If not liked or disliked, add the dislike
    //             axios.post('https://api.booking.ashishphotography.co.in/like', { reviewId, liked: 0, username })
    //                 .then(response => {
    //                     setDislikedReviews(new Set([...dislikedReviews, reviewId]));
    //                     setReviews(prevReviews =>
    //                         prevReviews.map(review =>
    //                             review.id === reviewId ? { ...review, dislikeCount: review.dislikeCount + 1 } : review
    //                         )
    //                     );
    //                 })
    //                 .catch(error => {
    //                     console.error('Error adding dislike:', error);
    //                 });
    //         }
    //     }
    // };
 
    useEffect(() => {
        calculateOverallRating();
    }, [reviews, likedReviews, dislikedReviews]); // Include dependencies that affect overall rating
   
    // Inside calculateOverallRating function
    const calculateOverallRating = () => {
        if (!reviews || reviews.length === 0) {
            console.log('No reviews available.');
            setOverallRating(0);
            return;
        }
   
        const totalStars = reviews.reduce((accumulator, review) => accumulator + parseFloat(review.stars), 0);
        const averageRating = totalStars / reviews.length;
        if (averageRating !== overallRating) {
            console.log('Overall Rating changed:', averageRating);
            setOverallRating(averageRating);
        }
    };
   
 
    useEffect(() => {
        updateStarCounts(reviews);
    }, [reviews]);
   
    const updateStarCounts = (reviews) => {
        const counts = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
        reviews.forEach(review => {
            counts[Math.floor(review.stars)]++;
        });
        setStarCounts(counts);
    };
 
 
    function StarRating({ count }) {
        const stars = [];
        for (let i = 0; i < count; i++) {
          stars.push(<FaStar key={i} className="stars" />);
        }
        return stars;
      }
     
      function StarCount({ count }) {
        return (
          <span>
            <StarRating count={count} />
          </span>
        );
      }
     
   
   
    return (
        <>
        <div>
   
            <div className="testimonial-heading">
                <span>Comments</span>
                {/* <h1>Clients Say</h1>
                <br /> */}
                <Button variant="outlined" onClick={handleOpenDialog}>Write a Review</Button>
                {/* <button onClick={handleLogout} className='btn btn-primary'>Logout</button> */}
            </div>
 
{/*
            <div style={{marginLeft:"20vw"}}>
            <h5 className='ratingheading'>Ratings & Reviews</h5>
            <div className='d-flex gap-3'>
                   
                    <div className='avrrating'>
                    <p>Average Rating</p>
                        {Array.from({ length: Math.floor(overallRating) }, (_, index) => (
                            <FaStar key={index} className="stars" />
                        ))}
                        {overallRating % 1 !== 0 && <FaStarHalfAlt className="stars" />}
                    </div>
 
                    <div>
                    <h5>Total Stars Count</h5>
                    <span> <StarCount count={5} /> </span> <span style={{marginLeft:"10px"}}>: {starCounts[5]} </span> <br/>
                    <span> <StarCount count={4} /> </span> <span style={{marginLeft:"27px"}}>: {starCounts[4]} </span> <br/>
                    <span> <StarCount count={3} /> </span> <span style={{marginLeft:"44px"}}>: {starCounts[3]} </span> <br/>
                    <span> <StarCount count={2} /> </span> <span style={{marginLeft:"62px"}}>: {starCounts[2]} </span> <br/>
                    <span> <StarCount count={1} /> </span> <span style={{marginLeft:"80px"}}>: {starCounts[1]} </span>
 
                </div>
                </div>
               
           
            </div> */}
 
 
            <section id="testimonials">
                <div className="testimonial-box-container">
                    {reviews.map((review) => (
                        <div key={review.id} className="testimonial-box1">
                            <div className="box-top">
                                <div className="profile5">
                                    <div className="profile5-img">
                                        <img src={img} alt={`Client ${review.id}`} />
                                    </div>
                                    <div className="name-user">
                                        <strong>{review.name}</strong>
                                        <span>{review.username}</span>
                                    </div>
                                </div>
                                <div className="reviews">
                                    {Array.from({ length: Math.floor(review.stars) }, (_, index) => (
                                        <FaStar key={index} className="stars" />
                                    ))}
                                    {review.stars % 1 !== 0 && <FaStarHalfAlt className="stars" />}
                                    {Array.from({ length: Math.floor(5 - review.stars) }, (_, index) => (
                                        <FaRegStar key={index + Math.floor(review.stars)} className="stars" />
                                    ))}
                                </div>
                            </div>
                            <div className="client-comment">
                                <p>{review.comment}</p>
                            </div>
 
                            {/* <div className='Helpful d-flex gap-2'>
                                <p>Helpful ?</p>
                                <span onClick={() => handleLike(review.id, true)}>
                                    {likedReviews.has(review.id) ? <p><AiFillLike className='likeicon' /> {review.likeCount}</p> : <p><AiOutlineLike className='likeicon' /> {review.likeCount}</p>}
                                </span>
 
                                <span onClick={() => handleLike(review.id, false)}>
                                    {dislikedReviews.has(review.id) ? <p><AiFillDislike className='likeicon' /> {review.dislikeCount}</p> : <p><AiOutlineDislike className='likeicon' /> {review.dislikeCount}</p>}
                                </span>
 
                            </div> */}
 
                            {review.reply && (
                            <div className='adminreplysection'>
                                <div className="profile5">
                                    <div className="adminlogo">
                                        <img src={img} alt={`Client ${review.id}`} />
                                    </div>
                                    <div className="name-user">
                                        <strong>Admin</strong>
                                    </div>
                                </div>
                                <div className="client-comment mt-2" style={{ marginLeft: "45px" }}>
                                    <p>{review.reply}</p>
                                </div>
                            </div>
                        )}
                           
                        </div>
                    ))}
                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                        <DialogTitle>Write a Review</DialogTitle>
                        <DialogContent>
                            <form onSubmit={handleSubmit}>
                                <TextField type="text" name="name" value={formData.name} onChange={handleChange} label="Name" fullWidth style={{ marginBottom: "8px" }} />
                                <TextField type="text" name="username" value={formData.username} onChange={handleChange} label="Username" fullWidth style={{ marginBottom: "8px" }} />
                                <TextField type="number" name="stars" value={formData.stars} onChange={handleChange} label="Rating" fullWidth style={{ marginBottom: "8px" }} />
                                <TextField
                                    multiline
                                    rows={4}
                                    name="comment"
                                    value={formData.comment}
                                    onChange={handleChange}
                                    label="Comment"
                                    fullWidth
                                />
                                <DialogActions>
                                    <Button type="submit" color="primary">Submit</Button>
                                    <Button onClick={handleCloseDialog} color="secondary">Cancel</Button>
                                </DialogActions>
                            </form>
                        </DialogContent>
                    </Dialog>
                </div>
            </section>
            </div>
        </>
    );
};
 
export default Product;