import React from "react";
import { Link } from "react-router-dom";

const Portfolio = () => {
    return (
        <div className="d-flex flex-column align-items-center m-5" >
            <Link  className="text-dark" to={'/portfolio1'} >Portfolio1</Link>
            <Link className="text-dark" to={'/portfolio2'} >Portfolio2</Link>
            <Link className="text-dark" to={'/portfolio3'} >Portfolio3</Link>
            <Link className="text-dark" to={'/portfolio4'} >Portfolio4</Link>
        </div>
    );
}
 
export default Portfolio;