import React, { useState } from "react";
import "./Resume3.css";
import Img1 from "./assets/3-1.png";
import Img2 from "./assets/3-2.png";
import Img3 from "./assets/3-3.png";
import Img4 from "./assets/3-4.png";
import Img5 from "./assets/3-5.png";
import Img6 from "./assets/3-6.png";
import Img7 from "./assets/3-7.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// import Img8 from './8.png'
const Portfolioform3 = () => {

  const storedUserId = localStorage.getItem("userId");
  const [userId, setUserId] = useState(
    storedUserId ? parseInt(storedUserId) : null
  );
  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    profileDescp: "",
    experienceheading: "",
    expYear: "",
    experienceDescp: "",
    stream: "",
    universityYear: "",
    skills: "",
    firstName: "",
    lastName: "",
    image:'',
    designation: "",
    mobile: "",
    email: "",
    location: "",
    pincode: "",
  });

  const handleImageChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const originalFilename = file.name; // Get the original filename
      uploadImage(file, name);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const uploadImage = async (file, name) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      // formData.append('filename', filename);
      formData.append("userId", userId);

      const response = await axios.post(
        "https://api.booking.ashishphotography.co.in/port/uploadImage",
        formData
      );
      const { filename } = response.data; // Extract the filename from the response
      console.log(filename);
      console.log("Image uploaded successfully!");
      setFormData((prevData) => ({ ...prevData, [name]: filename }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const [step, setStep] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleContinue = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // You can submit the form data here or perform any other action
    console.log(formData);
    // Reset form data if needed
   
    const response = await fetch("https://api.booking.ashishphotography.co.in/api/portfolio3", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        formData,
        userId,
      }),
    });

    if (response.ok) {
      console.log("Data saved successfully.");
      // Reset form data after successful submission
      setFormData({
        profileDescp: "",
        experienceheading: "",
        expYear: "",
        experienceDescp: "",
        stream: "",
        universityYear: "",
        skills: "",
        firstName: "",
        lastName: "",
        image:'',
        designation: "",
        mobile: "",
        email: "",
        location: "",
        pincode: "",
      });
      navigate("/pages");
    } else {
      console.error("Error saving data.");
    }
  };


  const getImageForStep = () => {
    switch (step) {
      case 1:
        return <img src={Img1} alt="Step 1" />;
      case 2:
        return <img src={Img2} alt="Step 2" />;
      case 3:
        return <img src={Img3} alt="Step 4" />;
      case 4:
        return <img src={Img4} alt="Step 5" />;
      case 5:
        return <img src={Img5} alt="Step 6" />;
      case 6:
        return <img src={Img6} alt="Step 7" />;
      case 7:
        return <img src={Img7} alt="Step 8" />;
      //   case 8:
      //     return <img src={Img8} alt="Step 9"/>;
      default:
        return null;
    }
  };

  return (
    <div className="multi-step-form-container">
      {getImageForStep()}
      <form onSubmit={handleSubmit} className="form">
        {step === 1 && (
          <div className="form-step">
            <label>1 PROFILE:</label>
            <br />
            <label>
              Profile Description paragraph:
              <input
                type="text" required 
                name="profileDescp"
                value={formData.profileDescp}
                onChange={handleChange}
                style={{
                  padding: "25px",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  fontSize: "14px",
                  marginTop: "5px",
                }}
              />
            </label>
            <br />
            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}

        {step === 2 && (
          <div className="form-step">
            <label>
              Experience Page <br />
              <br />
              2. Designation
              <input
                type="text" required 
                name="experienceheading"
                value={formData.experienceheading}
                onChange={handleChange}
              />
              <br />
              3. year
              <input
                type="number"
                name="expYear" required 
                value={formData.expYear}
                onChange={handleChange}
              />
              <br />
              4. Description
              <input
                type="text"
                name="experienceDescp"
                value={formData.experienceDescp}
                onChange={handleChange} required 
              />
            </label>
            <br />

            {/* <button type="submit">Add More</button> */}

            <br />
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}

        {step === 3 && (
          <div className="form-step">
            <label>
              Education <br />
              <br />
              5.Stream
              <input
                type="text" required 
                name="stream"
                value={formData.stream}
                onChange={handleChange}
              />
            </label>
            <br />
            <label>
              6. University and Year:
              <input
                type="text" required 
                name="universityYear"
                value={formData.universityYear}
                onChange={handleChange}
                style={{
                  padding: "25px",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  fontSize: "14px",
                  marginTop: "5px",
                }}
              />
            </label>

            <br />
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}
        {step === 4 && (
          <div className="form-step">
            <label>
              SKILLS <br />
              <br />
              <label>
                7 Description:
                <input
                  type="text" required 
                  name="skills"
                  value={formData.skills}
                  onChange={handleChange}
                />
              </label>
            </label>

            {/* <button type="submit">Add More</button> */}

            <br />
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}
        {step === 5 && (
          <div className="form-step">
            Profile Picture
            <br />
            <br />
            <label>
              8.Image:
              <input type="file"  name="image" onChange={handleImageChange} />
              <br />
            </label>
            <br />
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}

        {step === 6 && (
          <div className="form-step">
            NAME :
            <br />
            <br />
            <label>
              <br />
              Education <br />
              <br />
              9. First Name
              <input
                type="text"
                name="firstName" required 
                value={formData.firstName}
                onChange={handleChange}
              />
              <br />
              10. Last Name
              <input
                type="text"
                name="lastName"
                value={formData.lastName} required 
                onChange={handleChange}
              />
              <br />
              11. Designation
              <input
                type="text"
                name="designation" required 
                value={formData.designation}
                onChange={handleChange}
              />
              <br />
            </label>
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="button" onClick={handleContinue}>
              Save and Continue
            </button>
          </div>
        )}
        {step === 7 && (
          <div className="form-step">
            <br />
            CONTACT AND ADDRESS <br />
            <br />
            12.Phone number
            <input
              type="tel" required 
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
            13.email
            <input
              type="email" required 
              name="email"
              value={formData.email}
              onChange={handleChange}
            />{" "}
            <br />
            14.Location
            <input
              type="text"
              name="location" required 
              value={formData.location}
              onChange={handleChange}
            />{" "}
            <br />
            15.pincode
            <input
              type="number" required 
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
            />{" "}
            <br />
            <button type="button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="submit">Save and Submit</button>
          </div>
        )}
      </form>
    </div>
  );
};

export default Portfolioform3;
